import React, { useCallback, useMemo, useRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import Select from 'react-select';
import endpoints from '../../../helpers/endpoints'
import request from '../../../helpers/request'
import ReactModal from 'react-modal'
import hasPermission from '../../../helpers/permissions'
import classCodes from '../../../assets/classification-codes.json'
import { useHistory } from 'react-router-dom';
import { syncRowHeights, SummaryTable as MCVSummaryTable } from '../MeterCoordinatesValidation/shared'
import session from '../../../stores/session'
import { DatePicker } from '@material-ui/pickers';
import './AutomatedAddressCleanse.scss';

export const Summary = ({summaryData, setSummaryData, workflow = false, setMdsData, setMdsDataReady, translations = {}, getMDSData, setConfirmModal, prefix, disabledByUserType, setDisabledByUserType, matchCategory, qualityAssurance, selectedModule, setSelectedModule, customMDSPostcode, setMdsMeterData}) => {
  const history = useHistory();
  const { state } = history.location;
  const [spid, setSpid] = useState(state?.spid)
  const [loadingSpid, setLoadingSpid] = useState(false)
  const [loadingWorkflow, setLoadingWorkflow] = useState(false)
  const [search, setSearch] = useState()
  const [spidSearch, setSpidSearch] = useState(null)
  const [wSpid, setWSpid] = useState()
  const [sSpid, setSSpid] = useState()
  const [retOutcome, setRetOutcome] = useState('')
  const [wsOutcome, setWsOutcome] = useState('')
  const [wholesalerNotes, setWholesalerNotes] = useState('')
  const [retailerNotes, setRetailerNotes] = useState('')
  const [wsNoteIndex, setWsNoteIndex] =  useState()
  const [retNoteIndex, setRetNoteIndex] =  useState()
  const [prevWsNotes, setPrevWsNotes] =  useState([])
  const [prevRetNotes, setPrevRetNotes] =  useState([])
  const [workflowItems, setWorkflowItems] = useState([])
  const [workflowCounts, setWorkflowCounts] = useState([])
  const [workflowOutcomeSetting, setWorkflowOutcomeSetting] = useState('New')
  const [bulkRejectModelOpen, setBulkRejectModelOpen] = useState(false)
  const [loadingReject, setLoadingReject] = useState(false)

  const earlierDate = new Date();
  earlierDate.setDate(earlierDate.getDate() - 7);
  earlierDate.setHours(0, 0, 0, 0);
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  const [fromTime, setFromTime] = useState(earlierDate);
  const [toTime, setToTime] = useState(currentDate);
  const [users, setUsers] = useState([])
  const [selectedUser, setSelectedUser] = useState()
  const [selectedStatus, setSelectedStatus] = useState()
  const modules = [
    {label: 'All Modules', value: ''},
    {label: 'Automated Address Cleanse', value: 'AUTOMATED_ADDRESS_CLEANSE'},
    {label: 'Invalid Postcode Street', value: 'INVALID_POSTCODE_STREET'},
    {label: 'Invalid UPRN/VOA Ref In CMOS', value: 'INVALID_UPRN_VOA_CMOS'},
    {label: 'Meter Coordinates Validation', value: 'METER_COORDINATION_VALIDAITON'},
    {label: 'UPRN/VOA Address Conflicts', value: 'INVALID_UPRN_VOA'},
    {label: 'Undeliverable', value: 'UNDELIVERABLE'},
  ]

  useEffect(() => {
    syncRowHeights()
  }, [translations])

  const reset = () => {
    setSummaryData({})
    setWholesalerNotes('')
    setRetailerNotes('')
    setWsOutcome('')
    setRetOutcome('')
    setMdsData(false)
    setMdsDataReady(true)
    setPrevWsNotes([])
    setPrevRetNotes([])
    setWsNoteIndex(0)
    setRetNoteIndex(0)
    setLoadingSpid(false)
  }

  const getSummaryData = useCallback((spidSearch, spidType) => {
    setSearch()
    setSummaryData({})
    setRetOutcome('')
    setWsOutcome('')
    setLoadingSpid(true)
    request(true).get(endpoints[`${prefix}_SEARCH_BY_SPID`], {
      doesCancel: true,
      params: {
        spid: spidSearch || spid,
        spidType: spidType
      }
    }).then(e => {
      setSummaryData(e.data.customer ?? {})
      if (e.data.meterSearch) {
        setSpid(e.data.customer.Meter_ID)
      } else {
        setSpid(e.data.customer.Core_SPID)
      }
      if (spidType === "wSpid") {
        setSearch('')
        setSSpid('')
      } else if (spidType === "sSpid") {
        setSearch('')
        setWSpid('')
      } else {
        setWSpid('')
        setSSpid('')
      }
      setWsNoteIndex(e.data.notes?.filter(note => note.Note_Type === "Wholesaler").length)
      setRetNoteIndex(e.data.notes?.filter(note => note.Note_Type === "Retailer").length)
      setPrevWsNotes(e.data.notes?.filter(note => note.Note_Type === "Wholesaler"))
      setPrevRetNotes(e.data.notes?.filter(note => note.Note_Type === "Retailer"))
      setWsOutcome(e.data.customer?.WS_Outcome)
      setRetOutcome(e.data.customer?.Ret_Outcome)
      setLoadingSpid(false)
    }).catch(e => {
      reset()
      window.alert('Could not find SPID.')
    })
  }, [spid, setSummaryData])

  useEffect(() => {
    if (syncRowHeights) {
      syncRowHeights()
    }
  }, [summaryData])

  useEffect(() => {
    window.onload = syncRowHeights;
    window.onresize = syncRowHeights;
  }, [])

  useEffect(() => {
      window.reloadSummaryData = getSummaryData
  }, [getSummaryData])

  const getWorkflowItems = (spid) => {
    setSpidSearch(spid)
    if (qualityAssurance && !selectedUser) return
    setLoadingWorkflow(true)
    if (qualityAssurance) {
      var fromTimeUTC = fromTime.toISOString().slice(0, 10);
      var toTimeUTC = toTime.toISOString().slice(0, 10);
    }
    request(true).get(endpoints[`${prefix}_WF_ITEMS`], {
      doesCancel: true,
      params: {
        outcome: workflowOutcomeSetting,
        user: selectedUser?.value,
        module: selectedModule?.value,
        ...(qualityAssurance && {fromTime: fromTimeUTC}),
        ...(qualityAssurance && {toTime: toTimeUTC}),
        ...(selectedStatus && {selectedStatus: selectedStatus?.value}),
        ...(spid && {spid: spid}),
      }
    }).then(e => {
      setWorkflowItems(e.data);
      if (e.data.length > 0) {
        setSpid(e.data[_cur && _cur !== -1 ? _cur : 0])
      } else {
        reset()
        setSpid()
        setWSpid()
        setSSpid()
        setSearch()
      }
      setLoadingWorkflow(false);
    }).catch(e => {
      console.log(e);
      window.alert('Could not retrieve data')
      setLoadingWorkflow(false)
    })
  }

  const getWorkflowCounts = () => {
    setSearch()
    setSummaryData({})
    setRetOutcome('')
    setWsOutcome('')
    request(true).get(endpoints[`${prefix}_WF_COUNTTS`]).then(r => {
      setWorkflowCounts(r.data);
    })
  }

  useEffect(() => {
    window.reloadCounts = () => {
      getWorkflowItems()
      getWorkflowCounts()
    }
}, [getWorkflowCounts, getWorkflowItems])

  useEffect(() => {
      window.reloadWorkflow = getWorkflowItems
  }, [getWorkflowItems])

  var _cur = useMemo(() => {
    return workflowItems.findIndex(_ => _ === spid);
  }, [workflowItems, spid]);

  const refreshCounts = () => {
    if (summaryData.WS_Outcome !== wsOutcome && wsOutcome !== '') {
      getWorkflowItems()
      getWorkflowCounts()
    }
  }

  const getWorkflowNext = () => {
    if (_cur !== workflowItems.length-1) {
      refreshCounts()
      setSpid(workflowItems[_cur+1]);
    }
  }

  const getWorkflowLast = () => {
    if (_cur !== 0) {
      refreshCounts()
      setSpid(workflowItems[_cur-1]);
    }
  }

  const updateOutcome = () => {
    if (wsOutcome === summaryData.WS_Outcome && retOutcome === summaryData.Ret_Outcome) return
    request(true).post(endpoints[`${prefix}_UPDATE_OUTCOMES`], {
      SPID: spid,
      WS_Outcome: wsOutcome,
      Ret_Outcome: retOutcome
    }).then(e => {
      // window.alert(e.data);
    }).catch(e => {
      console.log(e)
      window.alert("Update failed.")
    })
  }

  const submitNotes= (noteType) =>{
    request(true).post(endpoints[`${prefix}_SUBMIT_NOTES`], {
      SPID: spid,
      notes: noteType === "Wholesaler" ? wholesalerNotes : retailerNotes,
      noteType: noteType,
      module: summaryData.Module
    }).then(e => {
      // window.alert(e.data);
      setWsNoteIndex(e.data.filter(note => note.Note_Type === "Wholesaler").length)
      setRetNoteIndex(e.data.filter(note => note.Note_Type === "Retailer").length)
      setPrevWsNotes(e.data.filter(note => note.Note_Type === "Wholesaler"))
      setPrevRetNotes(e.data.filter(note => note.Note_Type === "Retailer"))
      setRetailerNotes('')
      setWholesalerNotes('')
      window.alert("Note submitted")
    }).catch(e => {
      console.log(e)
      window.alert("Update failed.")
    })
  }

  useEffect(() => {
    getWorkflowCounts()
    if (qualityAssurance) {
      request(true).get(endpoints.DATA_CLEANSE_VALIDATION_QA_USERS).then(r => {
        setUsers(Object.keys(r.data).map(key => {return {label: r.data[key], value: key} }))
      })
    }
  }, [])

  useEffect(() => {
    if (summaryData.Core_SPID) {
      setMdsDataReady(false)
      getMDSData(summaryData, setMdsDataReady, prefix, customMDSPostcode, setMdsData, setMdsMeterData)
    }
  }, [summaryData])

  useEffect(() => {
    if (retOutcome || wsOutcome) {
      updateOutcome()
    }
  }, [retOutcome, wsOutcome])

  useEffect(() => {
    _cur = 0;
  }, [workflowOutcomeSetting])

  useEffect(() => {
    if (workflow) {
      getWorkflowItems();
    }
  }, [workflowOutcomeSetting])

  useEffect(() => {
    if (spid && spid !== summaryData.Core_SPID) {
      getSummaryData()
      setMdsDataReady(false);
    }
  }, [spid])

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (event.target.id === "wSpid") {
        setMdsDataReady(false);
        getSummaryData(wSpid, "wSpid")
      } else if (event.target.id === "sSpid") {
        setMdsDataReady(false);
        getSummaryData(wSpid, "sSpid")
      } else {
        setWSpid('');
        setSSpid('');
        setSpid(search ? search : spid);
      }
    }
  }

  useEffect(() => {
    if (workflow) {
      getWorkflowItems();
    }
  }, [selectedUser, selectedModule, selectedStatus, fromTime, toTime])

  useEffect(() => {
    if (typeof spidSearch === "undefined") {
      _cur = 0;
      getWorkflowItems()
    }
  }, [spidSearch])

  useEffect(() => {
    if (summaryData.WS_Outcome === "Complete" || summaryData.WS_Outcome === "Assured" || qualityAssurance) {
      setDisabledByUserType(true)
    } else if (session.wholesaler) {
      if ( (summaryData.W_Wholesaler_ID && summaryData.W_Wholesaler_ID === session.wholesaler) || (summaryData.Wholesaler && summaryData.Wholesaler === session.wholesaler) ) {
        setDisabledByUserType(false)
      } else if (!summaryData.W_Wholesaler_ID || !summaryData.Wholesaler) {
        if (summaryData.S_Wholesaler_ID && summaryData.S_Wholesaler_ID === session.wholesaler) {
          setDisabledByUserType(false)
        }
      } else {
        setDisabledByUserType(true)
      }
    }
    if (session.retailer) {
      if ( (summaryData.W_Retailer_ID && summaryData.W_Retailer_ID === session.retailer) || (summaryData.Retailer_ID && summaryData.Retailer_ID === session.retailer) ) {
        setDisabledByUserType(false)
      } else if (!summaryData.W_Retailer_ID || !summaryData.Retailer_ID) {
        if (summaryData.S_Retailer_ID && summaryData.S_Retailer_ID === session.retailer) {
          setDisabledByUserType(false)
        }
      } else {
        setDisabledByUserType(true)
      }
    }
  }, summaryData['Core_SPID'])

  const outcomes = ["New", "In Progress", "Visit Required", "Deregister", "Complete", "Deregistered", "Dead End", "Reject", "Assured"];

  return [
    <div style={{ display: 'grid', gap: '10px' }}>
      { workflow && 
        qualityAssurance ?
        <div class="quality-assurance">
          <div>
            <p className="font-weight-600">User</p>
            <Select 
              options={[{label: 'All Users', value: ''}].concat(users)} 
              onChange={ setSelectedUser }
              styles={{
                container: (baseStyles, state) => ({
                  ...baseStyles,
                  display: 'grid'
                }),
              }}
            />
          </div>
          <div>
            <p className="font-weight-600">Module</p>
            <Select 
              options={modules} 
              onChange={ setSelectedModule }
              defaultValue={{ label: 'All Modules', value: '' }}
              styles={{
                container: (baseStyles, state) => ({
                  ...baseStyles,
                  display: 'grid'
                }),
              }}
            />
          </div>
          <div>
            <p className="font-weight-600">From</p>
            <DatePicker variant="inline" label="From:" value={fromTime} onChange={setFromTime}/>
          </div>
          <div>
            <p className="font-weight-600">To</p>
            <DatePicker variant="inline" label="To:" value={toTime} onChange={setToTime}/>
          </div>
          <div>
            <p className="font-weight-600">Status</p>
            <Select 
              options={[{label: 'All Statuses', value: ''}, {label: 'Complete', value: 'Complete'}, {label: 'Quality Check Passed', value: 'Quality Check Passed'}]} 
              onChange={ setSelectedStatus }
              defaultValue={{ label: 'All Statuses', value: '' }}
              styles={{
                container: (baseStyles, state) => ({
                  ...baseStyles,
                  display: 'grid'
                }),
              }}
            />
          </div>
          <div>
              <button className={`button compact smaller-text reject ${selectedUser && selectedStatus?.value ? 'cancel' : ''}`} disabled={!selectedUser || !selectedStatus?.value} 
              onClick={() => { 
                  if ((selectedStatus.value === 'Quality Check Passed')) {
                    if (window.confirm("This dataset contains work that has previously been marked as quality assured. Please confirm you want to bulk reject these.")) {
                      setBulkRejectModelOpen(true);
                    }
                  } else {
                    setBulkRejectModelOpen(true);
                  }
                }}
              >Bulk Reject</button>
          </div>
        </div>
        :
        <div style={{ display: 'grid', gap: '10px', gridTemplateColumns: `repeat(${outcomes.length}, auto)`, margin: '0em 5em' }}>
          {
            workflow && outcomes.map(outcome => {
              const count = workflowCounts.find(wfCount => wfCount?.WS_Outcome === outcome);
              return (
                <button className={`button compact smaller-text ${workflowOutcomeSetting === outcome ? "bulk-buy-button" : "background-primary"} colour-white`} onClick={() => {
                  refreshCounts()
                  setWorkflowOutcomeSetting(outcome)
                }}>
                  {outcome} ({count?.count ?? 0})
                </button>
              )
            })
          }
        </div>
      }
      { summaryData.Module !== "METER_COORDINATION_VALIDAITON" ? 
      <table className='table borders squish smaller-text left' style={{tableLayout: 'fixed', width: '100%'}}>
      {/* <input type='text' /> */}
        <col width={180} />
        <thead>
          <tr>
            <th colSpan={10}>{translations['Header_Summary']}{qualityAssurance && summaryData.Module && `: ${modules.find(module => module.value === summaryData.Module).label}`}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td width={400}>{translations['Core_SPID']}</td>
            <td colSpan={2}>
              <div style={{display: 'flex', gap: 10, alignItems: 'center'}}>
                <input style={{border: 'none'}} type="text" onChange={e => setSearch(e.target.value)} value={loadingWorkflow ? '' : search ? search : spid} onKeyDown={handleKeyDown} />
                { (loadingWorkflow || loadingSpid ) && <i className="fa fa-spinner fa-spin" style={loadingSpid ? {} : { position: 'absolute', paddingLeft: '2px' }}/> }
                {
                  workflow ? <>
                    <span className='fas fa-angle-left colour-primary' style={{cursor: 'pointer'}} onClick={getWorkflowLast}></span>
                    <span>{_cur+1}/{workflowItems.length}</span>
                    <span className='fas fa-angle-right colour-primary' style={{cursor: 'pointer'}} onClick={getWorkflowNext}></span>
                  </> : <>
                  </>
                }
                <span className='fas fa-search colour-primary' style={{cursor: 'pointer'}} onClick={() => setSpid(search)}></span>
              </div>
            </td>
            <td>{translations['Match_Category']}</td>
            <td colSpan={'2'}>
              {summaryData[matchCategory]}
            </td>
            <td>{translations['Workflow_Status']}</td>
            <td colSpan={3}>
              <select disabled={!hasPermission(prefix, 'WHOLESALER') || (disabledByUserType && !qualityAssurance) || !summaryData?.Core_SPID} value={wsOutcome ? wsOutcome : summaryData.WS_Outcome ?? 'New'} 
              onChange={(event) => {
                if (["Complete", "Assured", "Reject", "Quality Check Passed"].includes(event.target.value)) {
                  setConfirmModal(event.target.value)
                } else {
                  setWsOutcome(event.target.value)
                }
              }} 
              style={{paddingLeft: 0}}>
                <option disabled={qualityAssurance} value="New">New</option>
                <option disabled={qualityAssurance} value="In Progress">In Progress</option>
                <option disabled={qualityAssurance} value="Visit Required">Visit Required</option>
                <option disabled={qualityAssurance} value="Deregister">Deregister</option>
                <option disabled={qualityAssurance} value="Complete">Complete</option>
                <option disabled={qualityAssurance} value="Deregistered">Deregistered</option>
                <option disabled={qualityAssurance} value="Dead End">Dead End</option>
                <option value="Reject">Reject</option>
                <option disabled={qualityAssurance} value="Assured">Assured</option>
                {qualityAssurance && <option value="Quality Check Passed">Quality Check Passed</option>}
              </select>
            </td>
          </tr>
          <tr>
            <td width='(100/4)%'>{translations['Water_SPID']}</td>
            <td width='(100/4)%'>
              <div style={{display: 'flex', gap: 10, alignItems: 'center'}}>
                <input id={"wSpid"} style={{border: 'none', backgroundColor: 'unset'}} type="text" onChange={e => setWSpid(e.target.value)} value={wSpid ? wSpid : summaryData['W_SPID'] ?? ''} onKeyDown={handleKeyDown} />
                <span className='fas fa-search colour-primary' style={{cursor: 'pointer'}} onClick={() => {
                  setSearch('')
                  setSSpid('')
                  getSummaryData(wSpid, "wSpid")}
                }></span>
              </div>
            </td>
            <td width='(100/4)%'>{translations['Water_Wholesaler']}</td>
            <td width='(100/4)%'>{summaryData['W_Wholesaler_ID']}</td>
            <td width='(100/4)%'>{translations['Water_Retailer']}</td>
            <td width='(100/4)%'>{summaryData['W_Retailer_ID']}</td>
            <td colSpan={4} rowSpan={11} style={{height: '100%'}}>
              <div style={{position: 'relative'}}>
                <textarea disabled={qualityAssurance ? false : (!hasPermission(prefix, 'WHOLESALER') || disabledByUserType)} onChange={(event) => setWholesalerNotes(event.target.value.slice(0, 500))} value={wholesalerNotes ?? ''} rows={8} style={{height: '100%', width: '100%', resize: 'none'}}/>
                <div style={{ position: 'relative', bottom: '3px', float: 'right', color: 'gray', fontSize: 12 }}>
                  {wholesalerNotes.length}/500
                </div>
                <textarea disabled={true} value={prevWsNotes[wsNoteIndex - 1]?.Note ?? ''} rows={8} style={{height: '100%', width: '100%', resize: 'none'}}/>
                <div style={{ position: 'relative', bottom: '3px', float: 'right', color: 'gray', fontSize: 12, display: 'flex', gap: 10, alignItems: 'center' }}>
                  {wsNoteIndex ?? 0}/{prevWsNotes.length} <i class="fa-solid fa-chevron-left" style={{ cursor: wsNoteIndex !== 1 && wsNoteIndex !== 0 ? 'pointer' : 'unset' }} onClick={() => setWsNoteIndex(wsNoteIndex !== 1 && wsNoteIndex !== 0 ? wsNoteIndex - 1 : wsNoteIndex)} /> <i class="fa-solid fa-chevron-right" style={{ cursor: wsNoteIndex !== prevWsNotes.length ? 'pointer' : 'unset' }} onClick={() => setWsNoteIndex(wsNoteIndex !== prevWsNotes.length ? wsNoteIndex + 1 : wsNoteIndex)} />
                </div>
                <div style={{ position: 'relative', bottom: 5, left: 5, color: 'gray', fontSize: 12, display: 'flex', gap: 10, alignItems: 'center', minHeight: '3vh' }}>
                  { prevWsNotes.length > 0 ?
                      prevWsNotes[wsNoteIndex - 1]?.User_Name + " - " + 
                      new Date(prevWsNotes[wsNoteIndex - 1]?.Date).toLocaleString('en-GB', { timeZone: 'Europe/London', hour12: false }).replace(',', '').replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$2-$1')
                  : ''}
                </div>
              </div>
              <button 
                disabled={qualityAssurance ? false : (!hasPermission(prefix, 'WHOLESALER') || wholesalerNotes.length < 0 || disabledByUserType)} 
                className={`button compact smaller-text ${(qualityAssurance || hasPermission(prefix, 'WHOLESALER')) && wholesalerNotes.length > 0 ? 'background-primary colour-white' : ''}`}
                onClick={() => submitNotes('Wholesaler')}
              >Submit Notes</button>
            </td>
          </tr>
          <tr>
            <td>{translations['Sewerage_SPID']}</td>
            <td>
              <div style={{display: 'flex', gap: 10, alignItems: 'center'}}>
                <input id={"sSpid"} style={{border: 'none'}} type="text" onChange={e => setSSpid(e.target.value)} value={sSpid ? sSpid : summaryData['S_SPID'] ?? ''} onKeyDown={handleKeyDown} />
                <span className='fas fa-search colour-primary' style={{cursor: 'pointer'}} onClick={() => {
                  setSearch('')
                  setWSpid('')
                  getSummaryData(sSpid, "sSpid")}
                }></span>
              </div>
            </td>
            <td>{translations['Sewerage_Wholesaler']}</td>
            <td>{summaryData['S_Wholesaler_ID']}</td>
            <td>{translations['Sewerage_Retailer']}</td>
            <td>{summaryData['S_Retailer_ID']}</td>
          </tr>
          <tr>
            <td>{translations['CMOS_UPRN']}</td>
            <td width='(100/4)%'>{summaryData['CMOS_UPRN']}</td>
            <td width='(100/4)%'>{translations['Matched_UPRN']}</td>
            <td width='(100/4)%'>{summaryData['Matched_UPRN']}</td>
            <td width='(100/4)%'>{translations['Status_UPRN']}</td>
            <td width='(100/4)%'>{summaryData['Status_UPRN']}</td>
          </tr>
          <tr>
            <td>{translations['UPRN_WSPID_Reason_Code']}</td>
            <td width='(100/4)%'>{summaryData['W_UPRN_Reason_Code']}</td>
          </tr>
          <tr>
            <td>{translations['CMOS_VOA_No']}</td>
            <td width='(100/4)%'>{summaryData['CMOS_VOA_No']}</td>
            <td width='(100/4)%'>{translations['Matched_VOA_No']}</td>
            <td width='(100/4)%'>{summaryData['Matched_VOA_No']}</td>
            <td width='(100/4)%'>{translations['Status_VOA_No']}</td>
            <td width='(100/4)%'>{summaryData['Status_VOA_No']}</td>
          </tr>
          <tr>
            <td>{translations['VOA_WSPID_Reason_Code']}</td>
            <td width='(100/4)%'>{summaryData['W_VOA_BA_Reference_Reason_Code']}</td>
          </tr>
          <tr>
            <td>{translations['CMOS_Address_Single_Line']}</td>
            <td colSpan={'3'}>{summaryData['CMOS_Address_Single_Line']}</td>
            <td>{translations['UPRN_GISX']}</td>
            <td>{summaryData['CMOS_UPRN_ABP_GISX']}</td>
          </tr>
          <tr>
            <td>{translations['CMOS_UPRN_Single_Line']}</td>
            <td colSpan={'3'}>{summaryData['CMOS_UPRN_Single_Line']}</td>
            <td>{translations['UPRN_GISY']}</td>
            <td>{summaryData['CMOS_UPRN_ABP_GISY']}</td>
          </tr>
          <tr>
            <td>{translations['Matched_UPRN_Single_Line']}</td>
            <td colSpan={'3'}>{summaryData['Matched_UPRN_Single_Line']}</td>
            <td>{translations['Meter_GISX']}</td>
            <td>{summaryData['CL_GISX']}</td>
          </tr>
          <tr>
            <td>{translations['CMOS_VOA_Single_Line']}</td>
            <td colSpan={'3'}>{summaryData['CMOS_VOA_Single_Line']}</td>
            <td>{translations['Meter_GISY']}</td>
            <td>{summaryData['CL_GISY']}</td>
          </tr>
          <tr>
            <td>{translations['Matched_VOA_Single_Line']}</td>
            <td colSpan={'3'}>{summaryData['Matched_VOA_Single_Line']}</td>
            <td>{translations['Occupancy']}</td>
            <td>{summaryData['Occupancy']}</td>
          </tr>
          <tr>
            <td>{translations['Meter_Address']}</td>
            <td colSpan={'3'}>{summaryData['Meter_Address_Single_Line']}</td>
            <td>{translations['Meter_Date']}</td>
            <td>{summaryData['Meter_Read_Date_Curr']}</td>
            <td>{translations['Meter_Type']}</td>
            <td colSpan={'1'}>{summaryData['Meter_Read_Method_Curr']}</td>
            <td>{translations['LUM']}</td>
            <td>{summaryData['LUM']}</td>
          </tr>
        </tbody>
      </table>
      :
        <MCVSummaryTable 
          translations={translations} 
          setSearch={setSearch} 
          loadingWorkflow={loadingWorkflow} workflow={workflow}
          search={search} meter={summaryData['Meter_ID']} 
          handleKeyDown={handleKeyDown} 
          getWorkflowLast={getWorkflowLast} workflowItems={workflowItems} getWorkflowNext={getWorkflowNext}
          _cur={_cur}
          setMeter={setSpid}
          summaryData={summaryData}
          wsOutcome={wsOutcome}
          setConfirmModal={setConfirmModal}
          setWsOutcome={setWsOutcome}
          qualityAssurance={qualityAssurance} modules={modules}
          setSpidSearch={setSpidSearch} spidSearch={spidSearch}
          getWorkflowItems={getWorkflowItems} setCur={(target) => _cur = target}
        />
      }
    </div>,
    <ReactModal
    isOpen={bulkRejectModelOpen} 
    onRequestClose={() => setBulkRejectModelOpen(false)}
    className="card bulk-reject"
    contentLabel="Data Cleanse Validation - Bulk Reject"
    style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}>
      <div className='dcv-reject'>
        <p>You are about to reject multiple submissions due to quality concerns. This action <br/> will return the work to the user's workflow queue for further review or updates.<br/>
          Please ensure you have carefully reviewed your selections click "Confirm" if you<br/> are ready to proceed.</p>
        <div>
          <button className="button smaller-text cancel" onClick={ () => setBulkRejectModelOpen(false) }>Cancel</button>
          <button className="button smaller-text background-primary colour-white" onClick={() => {
            setLoadingReject(true)
            request(true).post(endpoints[`${prefix}_BULK_REJECT`], {
              user: selectedUser?.value,
              fromTime: fromTime,
              toTime: toTime,
              module: selectedModule?.value,
              selectedStatus: selectedStatus?.value
            }).then(r => {
              setLoadingReject(false)
              setSummaryData(null)
              window.reloadCounts()
              setBulkRejectModelOpen(false); 
            }).catch(error => {
              console.log(error)
              setLoadingReject(false)
            })
          }}
          >
            { !loadingReject ?
                "Confirm"
              :
                <div className="spinner" style={{ margin: '0em 1em' }}>
                  <i className="fa fa-spinner fa-spin"></i>
                </div>
            }
          </button> 
        </div>
      </div>
    </ReactModal>
  ]
}

export const CMOSLive = ({ translations, summaryData: data, toggle }) => {
  const rows = useMemo(() => {
    return [
      [translations['CL_VOA_BA_Reference'], 'CL_VOA_BA_Reference'],
      [translations['VOA_Reason_Code'], 'CL_VOA_BA_Reference_Reason_Code'],
      [translations['CL_UPRN'], 'CL_UPRN'],
      [translations['UPRN_Reason_Code'], 'CL_UPRN_Reason_Code'],
      [translations['CL_Free_Descriptor'], 'CL_Free_Descriptor'],
      [translations['Occupier'], 'Clean_Customer_Name'],
      [translations['CL_Secondary_Addressable_Object'], 'CL_Secondary_Addressable_Object'],
      [translations['CL_Primary_Addressable_Object'], 'CL_Primary_Addressable_Object'],
      [translations['CL_Address_Line_1'], 'CL_Address_Line_1'],
      [translations['CL_Address_Line_2'], 'CL_Address_Line_2'],
      [translations['CL_Address_Line_3'], 'CL_Address_Line_3'],
      [translations['CL_Address_Line_4'], 'CL_Address_Line_4'],
      [translations['CL_Address_Line_5'], 'CL_Address_Line_5'],
      [translations['CL_Postcode'], 'CL_Postcode'],
      ['CL_Free_Descriptor']
    ]
  }, [translations])
  return (
    <div>
      <GenericTable title={translations['Current_CMOS_Premises_Header']} rows={rows} data={data} toggle={toggle} />
    </div>
  )
}

export const AddressBase = ({ translations, summaryData: data, selectedMatches, setSelectedMatches, prefix, disabledByUserType, setUserInput }) => {
  const [loading, setLoading] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [postcodeModal, setPostcodeModal] = useState(false);
  const [customPostcode, setCustomPostcode] = useState('');
  const [clearMatchModal, setClearMatchModal] = useState(false);
  const [index, setIndex] = useState(0);

  const cycleIndex = (change = 0) => {
    if (index + change <= -1) return
    if (index + change >= addresses.length) return
    setIndex(index + change)
  }

  const getData = (customInput) => {
    if (!customInput && !customPostcode && (!data.ABP_Postcode || data.Address_Status === 'Invalid Postcode') && data.CL_Postcode) {
        setLoading(false);
        return;
    }
    setAddresses([]);
    setIndex(0)
    request(true)
      .get(endpoints[`${prefix}_GET_ADDRESSES`], {
        doesCancel: true,
        params: {
          postcode: customInput || customPostcode || data.ABP_Postcode || data.CL_Postcode || data.CL_Meter_Address_Postcode
        }
      })
      .then(e => {
        const _data = e.data;
        _data.forEach(datum => {
          if (datum.CHANGE_CODE) {
            datum.CHANGE_CODE = datum.CHANGE_CODE === "D" ? "Deleted" : "Active"
          }
          if (datum.CLASSIFICATION_CODE) {
            datum.CLASSIFICATION_CODE = classCodes.find(classCode => classCode.Concatenated === datum?.CLASSIFICATION_CODE)?.Class_Desc
          }
        })
        setAddresses(_data);
        if (data.CF_UPRN || data.Matched_UPRN) {
          const displayFirst = _data.findIndex(_ => _.UPRN === parseInt(data.CF_UPRN ? data.CF_UPRN : data.Matched_UPRN))
          setIndex(displayFirst !== -1 ? displayFirst : 0)
          setSelectedMatches((prevMatches) => {
            return {
              ...prevMatches,
              abp: _data[displayFirst]
            };
          });
        }
        setLoading(false)
      })
      .catch(_ => {
        console.log(_)
        window.alert('Could not get Address Base data.');
        setAddresses([]);
        setLoading(false) 
      });
  };

  useEffect(() => {
    if (syncRowHeights) {
      syncRowHeights()
    }
  }, [addresses, index])

  useEffect(() => {
    setAddresses([])
    setIndex(-1)
    if (data.Core_SPID) {
      setIndex(-1)
      setCustomPostcode('');
      setLoading(true)
      getData(data.ABP_Postcode || data.CL_Postcode)
    } else {
      setLoading(false)
    }
  }, [data.Core_SPID]);

  useEffect(() => {
    if (customPostcode !== '') return
    if (!data.Core_SPID) {
      setLoading(false)
      setAddresses([])
    } else {
      getData();
    }
  }, [customPostcode])

  const selectMatch = useCallback(() => {
    if (!addresses[index]?.UPRN) return
    setSelectedMatches((prevMatches) => ({
      ...prevMatches,
       abp: addresses[index]
    }))
  }, [addresses, selectedMatches, setSelectedMatches, index])

  const updateUPRN = () => {
    setUserInput(prevUserInput => ({
      ...prevUserInput,
      CF_UPRN: addresses[index]?.UPRN,
      CF_UPRN_Reason_Code: ''
    }))
  }

  const restorePostcode = () => {
    setCustomPostcode(''); 
    getData(); 
    setLoading(true);
  } 

  const rows = useMemo(() => {
    return [
      [translations['UPRN'], 'UPRN'],
      [translations['STATUS'], 'CHANGE_CODE'],
      [translations['CLASSIFICATION_CODE'], 'CLASSIFICATION_CODE'],
      [translations['GISX'], 'EASTING'],
      [translations['GISY'], 'NORTHING'],
      [translations['ORGANISATION'], 'ORGANISATION'],
      [translations['SUB_BUILDING'], 'SUB_BUILDING'],
      [translations['BUILDING_NAME'], 'BUILDING_NAME'],
      [translations['BUILDING_NUMBER'], 'BUILDING_NUMBER'],
      [translations['STREET_NAME'], 'STREET_NAME'],
      [translations['LOCALITY'], 'LOCALITY'],
      [translations['TOWN_NAME'], 'TOWN_NAME'],
      [translations['POST_TOWN'], 'POST_TOWN'],
      [translations['Postcode'], 'POSTCODE'],
      ['SINGLE_LINE_ADDRESS'],
      [
        // () => <button disabled={disabledByUserType} className={`button compact smaller-text ${data.CF_UPRN && !disabledByUserType ? 'cancel colour-white' : ''}`} onClick={() => setClearMatchModal(data.CF_UPRN ? true : false) }>Clear Match</button>,
        () => <button disabled={disabledByUserType} className={`button compact smaller-text ${!disabledByUserType && addresses[index] ? 'background-primary colour-white' : ''}`} onClick={() => updateUPRN()}>Update UPRN Reference</button>,
        () => <button disabled={disabledByUserType} className={`button compact smaller-text ${selectedMatches.abp && selectedMatches.abp.UPRN == addresses[index]?.UPRN && !disabledByUserType ? 'background-primary colour-white' : ''}`} onClick={() => selectMatch()}>Select Match</button>
      ]
    ]
  }, [translations, window.reloadSummaryData, index, selectedMatches, addresses])

  return [
    <GenericTable title={translations['ABP_Header']} rows={rows} data={addresses} loading={loading} restorePostcode={restorePostcode} index={index} cycleIndex={cycleIndex} customPostcode={customPostcode} searchEnabled={true} setPostcodeModal={setPostcodeModal} />,
    <PostcodeSearchModal postcodeModal={postcodeModal} setPostcodeModal={setPostcodeModal} customPostcode={customPostcode} setCustomPostcode={setCustomPostcode} setLoading={setLoading} getData={getData} />,
    <ReactModal
      isOpen={clearMatchModal}
      onRequestClose={() => setClearMatchModal(false)}
      className="card bulk-allocate"
      contentLabel="Bulk Allocate COT Alert Data"
      style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
        <h3>Clear existing match</h3>
        <p>Please confirm you wish to clear the following matched UPRN for ABP on this SPID.</p>
        <ul className='spid-list'>
          <li>SPID: {data.Core_SPID}</li>
          <li>ABP UPRN: {data.CF_UPRN}</li>
        </ul>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center'}}>
          <button className="button compact smaller-text cancel" onClick={ () => setClearMatchModal(false) }>Cancel</button>
          <button className="button compact smaller-text background-primary colour-white" onClick={() => {
            request(true).post(endpoints[`${prefix}_CLEAR_MATCH`], {
              spid: data.Core_SPID,
              meterOrCmos: 'CMOS',
              source: 'abp'
            }).then(r => {
              window.reloadSummaryData()
              setClearMatchModal(false); 
            })
          }}
          >Confirm</button> 
        </div>
      </div>
    </ReactModal>
  ];
};

export const ValuationOfficeAndCouncilTax = ({ translations, summaryData: data, selectedMatches, setSelectedMatches, prefix, disabledByUserType, setUserInput }) => {
  const [loading, setLoading] = useState(false);
  const [addresses, setAddresses] = useState([])
  const [addressCategoryLengths, setAddressCategoryLengths] = useState({voa: null, ct: null})
  const [postcodeModal, setPostcodeModal] = useState(false);
  const [customPostcode, setCustomPostcode] = useState('');
  const [clearMatchModal, setClearMatchModal] = useState(false);
  const [index, setIndex] = useState(0);
  const [source, setSource] = useState();

  const cycleIndex = (change = 0) => {
    if (index + change <= -1) return
    if (index + change >= addresses.length) return
    setIndex(index + change)
  }

  const getData = (customInput) => {
    if (!customInput && !customPostcode && (!data.ABP_Postcode || data.Address_Status === 'Invalid Postcode') && data.CL_Postcode) {
        setLoading(false);
        return;
    }
    setAddresses([])
    setIndex(0)
    request(true).get(endpoints[`${prefix}_GET_VO_CT_COMBINED`], {
      doesCancel: true,
      params: {
        vo_postcode: customInput || customPostcode || data.VO_Postcode || data.CL_Postcode || data.CL_Meter_Address_Postcode,
        ct_postcode: customInput || customPostcode || data.CL_Postcode || data.CL_Meter_Address_Postcode,
        wholesaler: data.Wholesaler
      }
    }).then(e => {
      const _data = e.data
      const ctData = _data.ct
      ctData.map(datum => {
          const addressLines = datum.address.split(", ");
          addressLines.forEach((line, i) => {
            datum['CT_Address_Line_' + (i + 1)] = line
            datum.council_tax_band = datum.council_tax_band === "Deleted" ? "" : datum.council_tax_band
            datum.ct_status = datum.council_tax_band === "" ? "Deleted" : "Active"
          })
          return datum
      })
      setAddresses(_data.voa.concat(_data.ct));
      setAddressCategoryLengths({voa: _data.voa.length, ct: _data.ct.length})
      var displayFirst = 0;
      if (data.CF_VOA_BA_Reference || data.Matched_VOA_No) {
        displayFirst = _data.voa.concat(_data.ct).findIndex(_ => _.BA_Reference_Number === ( (data.CF_VOA_BA_Reference && _.Postcode === data.CF_Postcode) || (data.Matched_VOA_No)  ))
        setSelectedMatches((prevMatches) => {
          return {
            ...prevMatches,
            voa: displayFirst !== -1 ? _data.voa.concat(_data.ct)[displayFirst] : ''
          };
        })
      } else if (data.au_reference_number) {
        displayFirst = _data.voa.concat(_data.ct).findIndex(_ => _.au_reference_number === data.au_reference_number)
        setSelectedMatches((prevMatches) => {
          return {
            ...prevMatches,
            ct: displayFirst ? _data.voa.concat(_data.ct)[displayFirst] : ''
          };
        })
      }
      setIndex(displayFirst !== -1 ? displayFirst : 0);
      const currentRecord =_data.voa.concat(_data.ct)[displayFirst !== -1 ? displayFirst : 0] 
      setSource(currentRecord.BA_Reference_Number ? "voa" : currentRecord.au_reference_number ? "ct" : '')
      setLoading(false)
    }).catch((error) => {
      console.log(error)
      window.alert('Could not get Valuation Office data');
      setAddresses([])
      setLoading(false)
    })
  }

  useEffect(() => {
    if (syncRowHeights) {
      syncRowHeights()
    }
    const newSource = index + 1 > addressCategoryLengths.voa ? "ct" : "voa"
    if (source !== newSource) {
      setSource(index + 1 > addressCategoryLengths.voa ? "ct" : "voa")
    }
  }, [addresses, index, source])

  useEffect(() => {
    setAddresses([])
    setIndex(-1)
    if (data.Core_SPID) {
      setIndex(-1)
      setCustomPostcode('')
      setLoading(true)
      getData(data.VO_Postcode || data.CL_Postcode)
    } else {
      setLoading(false)
    }
  }, [data.Core_SPID])

  useEffect(() => {
    if (customPostcode !== '') return 
    if (!data.Core_SPID) {
      setAddresses([])
      setLoading(false)
    } else {
      getData();
    }
  }, [customPostcode])

  const selectMatch = useCallback((source) => {
    if (source === "voa" && !addresses[index]?.BA_Reference_Number) return;
    if (source === "ct" && !addresses[index]?.au_reference_number) return;

    setSelectedMatches((prevMatches) => ({
       ...prevMatches,
        voa: source === "voa" 
          ? addresses[index] 
          : '',
        ct: source === "ct" 
          ? addresses[index] 
          : ''
    }));
  }, [addresses, selectedMatches, setSelectedMatches, index, addressCategoryLengths]);

  const updateVoa = () => {
    setUserInput(prevUserInput => ({
      ...prevUserInput,
      CF_VOA_BA_Reference: addresses[index]?.BA_Reference_Number,
      CF_VOA_BA_Reference_Reason_Code: ''
    }))
  }

  const restorePostcode = () => {
    setCustomPostcode(''); 
    getData(); 
    setLoading(true);
  }

  const rows = useMemo(() => {
    const clearMatchEnabled = source === "voa" && data.CF_VOA_BA_Reference ? true : source === "ct" && data.au_reference_number ? true : false
    const matchEnabled = source === "voa" && JSON.stringify(selectedMatches.voa) === JSON.stringify(addresses[index]) ? true : source === "ct" && JSON.stringify(selectedMatches?.ct) === JSON.stringify(addresses[index]) ? true : false 
    if (addresses[index]) {
      addresses[index].Source = source === "voa" ? "VOA" : source === "ct" ? "Council Tax" : ''
    }
    return [
      [translations['BA_Reference_Number'], 'BA_Reference_Number'],
      [translations['VOA_Description'], ["SCOTTISH-W", "SW"].includes(data.Wholesaler) ? 'BR_Full_Address' : 'Primary_Description_Text'],
      [translations['Effective_Status'], ["SCOTTISH-W", "SW"].includes(data.Wholesaler) ? 'OCC_STATUS' : 'Effective_Status'],
      [translations['CT_Reference'], 'au_reference_number'],
      [translations['Effective_Status'], 'ct_status'],
      [translations['CT_Band'], 'council_tax_band'],
      [translations['CT_Mixed_Use_Property'], 'mixed_use_property'],
      [translations['Number_Or_Name'], source === "voa" ? ["SCOTTISH-W", "SW"].includes(data.Wholesaler) ? 'PAON' : 'Number_Or_Name' : ''],
      [translations['Street'], source === "voa" ? ["SCOTTISH-W", "SW"].includes(data.Wholesaler) ? 'STREET' : 'Street' : 'CT_Address_Line_1'],
      [translations['Sub_Street_level_1'], source === "voa" ? 'Sub_Street_level_1' : 'CT_Address_Line_2'],
      [translations['Town'], source === "voa" ? ["SCOTTISH-W", "SW"].includes(data.Wholesaler) ? 'TOWN' : 'Town' : 'CT_Address_Line_3'],
      [translations['Postal_District'], source === "voa" ? ["SCOTTISH-W", "SW"].includes(data.Wholesaler) ? "ADMIN_AREA" : 'Postal_District' : 'CT_Address_Line_4'],
      [translations['County'], source === "voa" ? ["SCOTTISH-W", "SW"].includes(data.Wholesaler) ? "UNITARY_AUTHORITY" : 'County' : 'CT_Address_Line_5'],
      [translations['Postcode'], source === "voa" ? ["SCOTTISH-W", "SW"].includes(data.Wholesaler) ? "BR_Postcode" : 'Postcode' : 'postcode'],
      [source === "voa" ? 'Full_Property_Identifier' : 'address'],
      [
        // () => <button disabled={disabledByUserType} className={`button compact smaller-text ${clearMatchEnabled && !disabledByUserType ? 'cancel colour-white' : ''}`} onClick={() => setClearMatchModal(clearMatchEnabled ? true : false) }>Clear Match</button>,
        () => <button disabled={disabledByUserType || source !== "voa"} className={`button compact smaller-text ${!disabledByUserType && addresses[index] && source === "voa" ? 'background-primary colour-white' : ''}`} onClick={() => updateVoa(source)}>Update VOA Reference</button>,
        () => <button disabled={disabledByUserType} className={`button compact smaller-text ${matchEnabled && !disabledByUserType ? 'background-primary colour-white' : ''}`} onClick={() => selectMatch(source)}>Select Match</button>
      ]
    ]
  }, [translations, window.reloadSummaryData, index, selectedMatches, addresses, syncRowHeights, source])

  return [
    <GenericTable title={translations['VOA_&_Council_Tax_Header']} rows={rows} data={addresses} loading={loading} restorePostcode={restorePostcode} index={index} cycleIndex={cycleIndex} customPostcode={customPostcode} searchEnabled={true} setPostcodeModal={setPostcodeModal} additionalHeaderInfo={[addresses[index]?.Source]} />,
    <PostcodeSearchModal postcodeModal={postcodeModal} setPostcodeModal={setPostcodeModal} customPostcode={customPostcode} setCustomPostcode={setCustomPostcode} setLoading={setLoading} getData={getData} />,
    <ReactModal
      isOpen={clearMatchModal}
      onRequestClose={() => setClearMatchModal(false)}
      className="card bulk-allocate"
      contentLabel="Bulk Allocate COT Alert Data"
      style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
        <h3>Clear existing match</h3>
        <p>Please confirm you wish to clear the following matched Reference for {source === "voa" ? "VOA" : source === "ct" ? "Council Tax" : ''} on this SPID.</p>
        <ul className='spid-list'>
          <li>SPID: {data.Core_SPID}</li>
          <li>
            {
              source === "voa" ?
                `VOA Reference: ${data.CF_VOA_BA_Reference}`
              : source === "ct" ?
                `CT Reference: ${data.au_reference_number}`
              : ''
            }
          </li>
        </ul>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center'}}>
          <button className="button compact smaller-text cancel" onClick={ () => setClearMatchModal(false) }>Cancel</button>
          <button className="button compact smaller-text background-primary colour-white" onClick={() => {
            request(true).post(endpoints[`${prefix}_CLEAR_MATCH`], {
              spid: data.Core_SPID,
              meterOrCmos: 'CMOS',
              source: source
            }).then(r => {
              window.reloadSummaryData()
              setClearMatchModal(false); 
            })
          }}
          >Confirm</button> 
        </div>
      </div>
    </ReactModal>
  ]
}

export const MeterAddress = ({ translations, summaryData: data, selectedMatches, setSelectedMatches, prefix, disabledByUserType, userInput, setUserInput }) => {
  const [loading, setLoading] = useState(false);
  const [addresses, setAddresses] = useState();
  const [postcodeModal, setPostcodeModal] = useState(false);
  const [customPostcode, setCustomPostcode] = useState('');
  const [clearMatchModal, setClearMatchModal] = useState(false);
  const [index, setIndex] = useState(0);
  const [source, setSource] = useState("internal")

  const cycleIndex = (change = 0) => {
    if (index + change <= -1) return
    if (index + change >= addresses?.length) return
    setIndex(index + change)
  }

  const getData = (customInput) => {
    if (!customInput && !customPostcode && !data.CF_Meter_Address_Postcode && data.CL_Postcode) {
        setLoading(false);
        return;
    }
    setAddresses()
    setIndex(0)
    setLoading(true)
    request(true).get(endpoints[`${prefix}_GET_METER`], {
      doesCancel: true,
      params: {
        postcode: customInput || data.CF_Meter_Address_Postcode || data.CL_Postcode
      }
    })
    .then(e => {
      const _data = e.data;
      setAddresses(_data)
      var displayFirst = -1
      if (data.Meter_ID) {
        displayFirst = _data.findIndex(_ => `${_.Manufacturer_Meter_Serial_Number}_${_.Meter_Manufacturer}` === data.Meter_ID) 
        setIndex(displayFirst !== -1 ? displayFirst : 0);
        setSelectedMatches((prevMatches) => {
          return {
            ...prevMatches,
            meter: typeof data.Meter_ID === "number" ? data.Meter_ID.toString() : data.Meter_ID 
          };
        });
      }
      setUserInput(_data[displayFirst !== -1 ? displayFirst : 0])
      setSource("external")
      setLoading(false)
    })
    .catch(error => { 
      setAddresses(); 
      setLoading(false)
      console.log(error) 
      window.alert('Could not get Meter data.'); 
    })
  };

  useEffect(() => {
    if (syncRowHeights) {
      syncRowHeights()
    }
  }, [addresses, index])

  useEffect(() => {
    setAddresses()
    setIndex(-1)
    setUserInput({})
    if (data.Core_SPID) {
      setIndex(-1)
      setCustomPostcode('')
      setLoading(false)
      setUserInput(data ?? {})
      setSource("internal")
    } else {
      setLoading(false)
    }
  }, [data.Core_SPID])

  const restorePostcode = () => {
    setCustomPostcode(''); 
    setLoading(false)
    setAddresses()
    setUserInput(data ?? {})
    setSource("internal")
  } 

  const handleInputChange = (newInput) => {
    setUserInput(prevUserInput => ({
        ...prevUserInput,
        ...newInput,
    }));
  };

  function formatAddress(fields) {
    const formattedAddress = {};
  
    const addressLines = [
      'CF_Meter_Address_Address_Line_1',
      'CF_Meter_Address_Address_Line_2',
      'CF_Meter_Address_Address_Line_3',
      'CF_Meter_Address_Address_Line_4',
      'CF_Meter_Address_Address_Line_5',
    ];
  
    const components = fields.map(field => selectedMatches.abp?.[field]).filter(value => value && value.trim() !== '');
  
    components.forEach((component, index) => {
      if (index < addressLines.length) {
        formattedAddress[addressLines[index]] = component;
      }
    });
  
    addressLines.forEach(line => {
      if (!formattedAddress[line]) {
        formattedAddress[line] = '';
      }
    });
  
    return formattedAddress;
  }

  const handleUpdateOnUprn = () => {
    if (!selectedMatches.abp) return
    setAddresses()
    setIndex()
    restorePostcode()
    const choice = selectedMatches.abp
    var SAO = '';
    if (!choice.SUB_BUILDING && !choice.BUILDING_NAME && !choice.BUILDING_NUMBER) {
      SAO = choice.ORGANISATION
    }
    setUserInput({
      ...userInput,
      CF_UPRN: choice.UPRN,
      CF_UPRN_Reason_Code: '',
      CF_GISX: choice.EASTING ?? '',
      CF_GISY: choice.NORTHING ?? '',
      CF_Meter_Address_Secondary_Addressable_Object: SAO || choice.SUB_BUILDING || '',
      CF_Meter_Address_Primary_Addressable_Object: choice.STREET_NAME ? (choice.BUILDING_NAME || choice.BUILDING_NUMBER || '') : '',
      CF_Meter_Address_Address_Line_1: choice.STREET_NAME || choice.BUILDING_NAME || choice.BUILDING_NUMBER || '',
      CF_Meter_Address_Address_Line_2: choice.LOCALITY ?? '',
      CF_Meter_Address_Address_Line_3: choice.TOWN_NAME ?? '',
      CF_Meter_Address_Address_Line_4: choice.POST_TOWN ?? '',
      CF_Meter_Address_Address_Line_5: choice.ISLAND ?? '',
      CF_Meter_Address_Postcode: choice.POSTCODE ?? ''
    })
    syncRowHeights()
    setSource('internal')
  }

  useEffect(() => {
    if (source === "external") {
      // const fields = [
      //   'Address_Address_Line_1',
      //   'Address_Address_Line_2',
      //   'Address_Address_Line_3',
      //   'Address_Address_Line_4',
      //   'Address_Address_Line_5',
      //   'Address_Postcode'
      // ]
      // const formattedAddress = formatAddress(fields)
      setUserInput({
        ...userInput,
        CF_UPRN: addresses[index]?.UPRN ?? '',
        CF_GISX: addresses[index]?.GISX ?? '',
        CF_GISY: addresses[index]?.GISY ?? '',
        CF_Meter_Address_Address_Line_1: addresses[index]?.Address_Address_Line_1 ?? '',
        CF_Meter_Address_Address_Line_2: addresses[index]?.Address_Address_Line_2 ?? '',
        CF_Meter_Address_Address_Line_3: addresses[index]?.Address_Address_Line_3 ?? '',
        CF_Meter_Address_Address_Line_4: addresses[index]?.Address_Address_Line_4 ?? '',
        CF_Meter_Address_Address_Line_5: addresses[index]?.Address_Address_Line_5 ?? '',
        CF_Meter_Address_Postcode: addresses[index]?.Address_Postcode ?? ''
      })
    }
  }, [index, addresses])

  const rows = useMemo(() => {
    var matchEnabled = false

    if (!loading) {
      const fields = [
        'SUB_BUILDING',
        'BUILDING_NAME',
        'BUILDING_NUMBER',
        'STREET_NAME',
        'TOWN_NAME',
        'POST_TOWN',
      ];
      const formattedAddress = formatAddress(fields)
      formattedAddress.CF_GISX = selectedMatches?.abp?.EASTING
      formattedAddress.CF_GISY = selectedMatches?.abp?.NORTHING
      formattedAddress.CF_Meter_Address_Postcode = selectedMatches?.abp?.POSTCODE

      const fieldMappings = {
        CF_GISX: 'CF_GISX',
        CF_GISY: 'CF_GISY',
        CF_Meter_Address_Secondary_Addressable_Object: source === "external" ? 'Address_Secondary_Addressable_Object' : 'CF_Meter_Address_Secondary_Addressable_Object', 
        CF_Meter_Address_Primary_Addressable_Object: source === "external" ? 'Address_Primary_Addressable_Object' : 'CF_Meter_Address_Primary_Addressable_Object',
        CF_Meter_Address_Address_Line_1: source === "external" ? 'Address_Address_Line_1' : 'CF_Meter_Address_Address_Line_1',
        CF_Meter_Address_Address_Line_2: source === "external" ? 'Address_Address_Line_2' : 'CF_Meter_Address_Address_Line_2',
        CF_Meter_Address_Address_Line_3: source === "external" ? 'Address_Address_Line_3' : 'CF_Meter_Address_Address_Line_3',
        CF_Meter_Address_Address_Line_4: source === "external" ? 'Address_Address_Line_4' : 'CF_Meter_Address_Address_Line_4',
        CF_Meter_Address_Address_Line_5: source === "external" ? 'Address_Address_Line_5' : 'CF_Meter_Address_Address_Line_5',
        CF_Meter_Address_Postcode: source === "external" ? 'Address_Postcode' : 'CF_Meter_Address_Postcode'
      };
      if (userInput) {
        matchEnabled = selectedMatches?.abp && Object.keys(fieldMappings).some(
          key => userInput[key] != formattedAddress[fieldMappings[key]]
        );
      }
    }

    return [
      [translations['Outreader_Protocol'], 'Outreader_Protocol'],
      [translations['Meter_Location_Free_Desc'], addresses ? 'Meter_Outreader_Location_Free_Desc' : 'CF_Meter_Location_Free_Desc'],
      [translations['Meter_Location_Code'], 'Meter_Location_Code'],
      [translations['GISX'], {hasInput: true, column: 'CF_GISX', value: userInput?.['CF_GISX'], disabled: disabledByUserType}],
      [translations['GISY'], {hasInput: true, column: 'CF_GISY', value: userInput?.['CF_GISY'], disabled: disabledByUserType}],
      [translations['Occupier'], ''],
      [translations['CL_Secondary_Addressable_Object'], addresses ? 'Address_Secondary_Addressable_Object' : 'CF_Meter_Address_Secondary_Addressable_Object'],
      [translations['CL_Primary_Addressable_Object'], addresses ? 'Address_Primary_Addressable_Object' : "CF_Meter_Address_Primary_Addressable_Object"],
      [translations['CL_Address_Line_1'], addresses ? 'Address_Address_Line_1' : 'CF_Meter_Address_Address_Line_1'],
      [translations['CL_Address_Line_2'], addresses ? 'Address_Address_Line_2' : 'CF_Meter_Address_Address_Line_2'],
      [translations['CL_Address_Line_3'], addresses ? 'Address_Address_Line_3' : 'CF_Meter_Address_Address_Line_3'],
      [translations['CL_Address_Line_4'], addresses ? 'Address_Address_Line_4' : 'CF_Meter_Address_Address_Line_4'],
      [translations['CL_Address_Line_5'], addresses ? 'Address_Address_Line_5' : 'CF_Meter_Address_Address_Line_5'],
      [translations['CL_Postcode'], addresses ? 'Address_Postcode' : "CF_Meter_Address_Postcode"],
      [
        // () => 
        // <button 
        //   disabled={disabledByUserType}
        //   className={`button compact smaller-text ${clearMatchEnabled && !disabledByUserType ? 'cancel colour-white' : ''}`} 
        //   onClick={() => {
        //     if (clearMatchEnabled) {
        //       setUserInput(data)
        //       setSource("internal")
        //     } 
        //   }}
        // >
        //   Clear Match
        // </button> 
        '',
        () => <button disabled={disabledByUserType} className={`button compact smaller-text ${matchEnabled && !disabledByUserType ? ' background-primary colour-white' : ''}`} onClick={() => matchEnabled && handleUpdateOnUprn()}>Update on UPRN</button>,
      ],
    ]
  }, [translations, window.reloadSummaryData, index, selectedMatches, addresses, userInput, setUserInput, source])

  return [
    <GenericTable title={translations['Meter_Address_Header']} rows={rows} data={addresses ?? userInput} loading={loading} restorePostcode={restorePostcode} index={index} cycleIndex={cycleIndex} customPostcode={customPostcode} searchEnabled={true} setPostcodeModal={setPostcodeModal} handleInputChange={handleInputChange} userInput={userInput} />,
    <PostcodeSearchModal postcodeModal={postcodeModal} setPostcodeModal={setPostcodeModal} customPostcode={customPostcode} setCustomPostcode={setCustomPostcode} setLoading={setLoading} getData={getData} />,
    <ReactModal
      isOpen={clearMatchModal}
      onRequestClose={() => setClearMatchModal(false)}
      className="card bulk-allocate"
      contentLabel="Bulk Allocate COT Alert Data"
      style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
        <h3>Clear existing match</h3>
        <p>Please confirm you wish to clear the following matched Reference for Meter on this SPID.</p>
        <ul className='spid-list'>
          <li>SPID: {data.Core_SPID}</li>
          <li>CT Reference: {data.au_reference_number}</li>
        </ul>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center'}}>
          <button className="button compact smaller-text cancel" onClick={ () => setClearMatchModal(false) }>Cancel</button>
          <button className="button compact smaller-text background-primary colour-white" onClick={() => {
            request(true).post(endpoints[`${prefix}_CLEAR_MATCH`], {
              spid: data.Core_SPID,
              matchType: 'ct'
            }).then(r => {
              window.reloadSummaryData()
              setClearMatchModal(false); 
            })
          }}
          >Confirm</button> 
        </div>
      </div>
    </ReactModal>
  ]
}

export const CMOSFormatted = ({ translations, summaryData, selectedMatches, prefix, disabledByUserType, userInput, setUserInput }) => {
  useEffect(() => {
    setUserInput(summaryData)
  }, [summaryData.Core_SPID])

  const handleUpdateOnUprn = () => {
    if (!selectedMatches.abp) return
    const choice = selectedMatches.abp
    var SAO = '';
    if (!choice.SUB_BUILDING && !choice.BUILDING_NAME && !choice.BUILDING_NUMBER) {
      SAO = choice.ORGANISATION
    }
    setUserInput({
      ...userInput,
      CF_UPRN: choice.UPRN,
      CF_UPRN_Reason_Code: '',
      CF_Secondary_Addressable_Object: SAO || choice.SUB_BUILDING || '',
      CF_Primary_Addressable_Object: choice.STREET_NAME ? (choice.BUILDING_NAME || choice.BUILDING_NUMBER || '') : '',
      CF_Address_Line_1: choice.STREET_NAME || choice.BUILDING_NAME || choice.BUILDING_NUMBER || '',
      CF_Address_Line_2: choice.LOCALITY ?? '',
      CF_Address_Line_3: choice.TOWN_NAME ?? '',
      CF_Address_Line_4: choice.POST_TOWN ?? '',
      CF_Address_Line_5: choice.ISLAND ?? '',
      CF_Postcode: choice.POSTCODE ?? ''
    })
    syncRowHeights()
  }

  const handleUpdateOnVoaOrCt = () => {
    const source = selectedMatches.voa ? "voa" : selectedMatches.ct ? "ct" : ""
    const choice = selectedMatches[source]
    if (source === "voa") {
      const useScottishTerms =  ["SCOTTISH-W", "SW"].includes(choice.Wholesaler)
      setUserInput({
        ...userInput,
        CF_VOA_BA_Reference: choice.BA_Reference_Number,
        CF_VOA_BA_Reference_Reason_Code: '',
        CF_Secondary_Addressable_Object: '',
        CF_Primary_Addressable_Object: useScottishTerms ? (choice.STREET && (choice.PAON ?? '') ) : (choice.Street && (choice.Number_Or_Name ?? '') ),
        CF_Address_Line_1: useScottishTerms ? choice.STREET || choice.PAON || '' : choice.Street || choice.Number_Or_Name || '',
        CF_Address_Line_2: '',
        CF_Address_Line_3: useScottishTerms ? choice.ADMIN_AREA ?? '' : choice.Postal_District ?? '',
        CF_Address_Line_4: useScottishTerms ? choice.TOWN  ?? '' : choice.Town ?? '',
        CF_Address_Line_5: useScottishTerms ? choice.UNITARY_AUTHORITY ?? '' : choice.County ?? '',
        CF_Postcode: choice.Postcode ?? ''
      })
    } else if (source === "ct") {
      const formattedAddress = {}
      const fields = [
        'CT_Address_Line_1',
        'CT_Address_Line_2',
        'CT_Address_Line_3',
        'CT_Address_Line_4',
        'CT_Address_Line_5',
      ];

      const addressLines = [
        'CF_Address_Line_1',
        'CF_Address_Line_2',
        'CF_Address_Line_3',
        'CF_Address_Line_4',
        'CF_Address_Line_5',
      ];

      const components = fields.map(field => selectedMatches[source][field]).filter(value => value && value.trim() !== '');

      components.forEach((component, index) => {
        if (index < addressLines.length) {
          formattedAddress[addressLines[index]] = component;
        }
      });
      setUserInput({
        ...userInput,
        CF_Secondary_Addressable_Object: '',
        CF_Primary_Addressable_Object: '',
        CF_Address_Line_1: choice.CT_Address_Line_1 ?? '',
        CF_Address_Line_2: choice.CT_Address_Line_2 ??'',
        CF_Address_Line_3: choice.CT_Address_Line_3 ?? '',
        CF_Address_Line_4: choice.CT_Address_Line_4 ?? '',
        CF_Address_Line_5: choice.CT_Address_Line_5 ?? '',
        CF_Postcode: choice.postcode ?? ''
      })
    }
    syncRowHeights()
  }

  useEffect(() => {
    syncRowHeights()
  }, [userInput])

  const handleInputChange = (newInput) => {
    if (newInput.CF_UPRN_Reason_Code) {
      newInput.CF_UPRN = ''
    } else if (newInput.CF_VOA_BA_Reference_Reason_Code) {
      newInput.CF_VOA_BA_Reference = ''
    }
    setUserInput(prevUserInput => ({
        ...prevUserInput,
        ...newInput,
    }));
  };

  const rows = useMemo(() => {
    const abpMatchEnabled = Boolean(
      summaryData && selectedMatches.abp?.UPRN && String(selectedMatches.abp.UPRN) !== String(userInput.CF_UPRN)
    );
    
    const voaMatchEnabled = Boolean(
      summaryData && selectedMatches.voa?.BA_Reference_Number && String(selectedMatches.voa.BA_Reference_Number) !== String(userInput.CF_VOA_BA_Reference)
    );
    
    const ctMatchEnabled = Boolean(
      summaryData && selectedMatches.ct?.au_reference_number && String(selectedMatches.ct.au_reference_number) !== String(userInput.au_reference_number)
    );
    
    return [
      [translations['CF_VOA_BA_Reference'], 'CF_VOA_BA_Reference'],
      [translations['VOA_Reason_Code'], 
        {
          hasDropdown: true,
          column: 'CF_VOA_BA_Reference_Reason_Code',
          value: userInput?.['CF_VOA_BA_Reference_Reason_Code'],
          options: [
            { value: '', label: "None"},
            { value: 'AG', label: "No number - agricultural land and buildings"},
            { value: 'IP', label: "No number - infrastructure Project"},
            { value: 'ME', label: "No number - missing Entry from the VOA"},
            { value: 'MT', label: "Multiple VOA BA References at the same eligible premises"},
            { value: 'NR', label: "No number - property not yet rated"},
            { value: 'SP', label: "No number provided - use number from Other SPID"},
            { value: 'SR', label: "No number - fish farms, fishing, and sporting rights"},
            { value: 'OT', label: "Other"},
          ],
          disabled: disabledByUserType
        }
      ],
      [translations['CF_UPRN'], 'CF_UPRN'],
      [translations['UPRN_Reason_Code'], 
        {
          hasDropdown: true, 
          column: 'CF_UPRN_Reason_Code', 
          value: userInput?.['CF_UPRN_Reason_Code'],
          options: [
            { value: '', label: "None"},
            { value: 'BW', label: "No number - construction site"},
            { value: 'IP', label: "No number - infrastructure Project"},
            { value: 'ME', label: "No number - missing Entry from the NLPG"},
            { value: 'MT', label: "Multiple UPRNs at the same eligible premises"},
            { value: 'PL', label: "No number - not yet issued by planning"},
            { value: 'SP', label: "No number provided - use number from Other SPID"},
            { value: 'SR', label: "No number - fish farms, fishing, and sporting rights"},
            { value: 'OT', label: "Other"},
          ],
          disabled: disabledByUserType
        }
      ],
      [translations['CF_Free_Descriptor'], 'CF_Free_Descriptor'],
      [translations['Occupier'], 'Clean_Customer_Name'],
      [translations['CF_Secondary_Addressable_Object'], 'CF_Secondary_Addressable_Object'],
      [translations['CF_Primary_Addressable_Object'], 'CF_Primary_Addressable_Object'],
      [translations['CF_Address_Line_1'], 'CF_Address_Line_1'],
      [translations['CF_Address_Line_2'], 'CF_Address_Line_2'],
      [translations['CF_Address_Line_3'], 'CF_Address_Line_3'],
      [translations['CF_Address_Line_4'], 'CF_Address_Line_4'],
      [translations['CF_Address_Line_5'], 'CF_Address_Line_5'],
      [translations['CF_Postcode'], 'CF_Postcode'],
      [
        ({summaryData}) => 
          <button 
            disabled={disabledByUserType}
            className={`button compact smaller-text ${(voaMatchEnabled || ctMatchEnabled) && !disabledByUserType ? 'background-primary colour-white' : ''}`} 
            onClick={() => (voaMatchEnabled || ctMatchEnabled) && handleUpdateOnVoaOrCt()}
          >Update on {ctMatchEnabled ? "CT" : "VOA"}</button>,         
        ({}) => 
          <button 
            disabled={disabledByUserType}
            className={`button compact smaller-text ${abpMatchEnabled && !disabledByUserType && "background-primary colour-white"}`} 
            onClick={() => abpMatchEnabled && handleUpdateOnUprn()}
          >Update on UPRN</button>
      ]
    ]
  }, [translations, window.reloadSummaryData, selectedMatches, userInput, setUserInput, summaryData])
  return (
    <GenericTable title={translations['Updated_CMOS_Premises_Header']} rows={rows} data={userInput} handleInputChange={handleInputChange} />
  )
}

export const GenericTable = ({title, rows, data, loading, restorePostcode, index = 0, cycleIndex, customPostcode, searchEnabled, setPostcodeModal, handleInputChange, userInput, toggle, additionalHeaderInfo }) => {
  const [localInput, setLocalInput] = useState(userInput);
  return (
    <div style={{ height: 'fit-content' }}>
      <table className="table borders squish smaller-text left sync" style={{ height: '100%', tableLayout: 'fixed', wordBreak: 'break-word' }}>
        <thead>
          <tr>
            <th colSpan={2}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {title}
                <div style={{ marginLeft: 'auto', display: 'flex', gap: 10 }}>
                  {additionalHeaderInfo && (
                    additionalHeaderInfo.map(text => {
                      return <span>{text}</span>
                    })
                  )}
                  {customPostcode && (
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <span className='fas fa-trash-undo' onClick={() => { restorePostcode() }} title="Restore original postcode search"></span>
                    </div>
                  )}
                  { toggle &&
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <span className='fas fa-rotate-reverse' onClick={() => toggle()}></span>
                    </div>
                  }
                  {searchEnabled &&
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <span className='fas fa-search' onClick={() => setPostcodeModal(true)} title="Search for a different postcode"></span>
                    </div>
                  }
                  {
                    data?.length > 1 ? <>
                      <span style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <span className='fas fa-angle-left' onClick={() => cycleIndex(-1)}></span>
                        <span style={{minWidth: 60, textAlign: 'center'}}>{index+1}/{data.length}</span>
                        <span className='fas fa-angle-right' onClick={() => cycleIndex(1)}></span>
                      </span>
                    </> : null
                  }
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, i) => {
            return (
              <tr>{
                row.map((Cell, rowIndex) => {
                  const cellAbove = row[rowIndex - 1]
                  if (!Cell && typeof cellAbove === "function") return
                  return (
                    <td rowSpan={typeof Cell === "function" && !row[rowIndex + 1] ? 2 : 1} colSpan={row.length === 1 ? 2 : 1} style={{ minWidth: '60px', textAlign: (loading && rowIndex === 1) || row.length === 1 ? 'center' : 'unset', borderBottom: '0px' }}>
                      { loading && rowIndex === 1 && i === 0 ?
                        <i className="fa fa-spinner fa-spin"></i>
                      :
                        loading && rowIndex === 1 && typeof Cell === "string" ?
                        ''
                      :
                        typeof Cell === "string" ? 
                          rowIndex === 0 && row.length !== 1 ? Cell : Array.isArray(data) ? data?.[index]?.[Cell] : data?.[Cell]
                        : Cell?.hasInput ?
                        <input
                          disabled={Cell.disabled}
                          type="text"
                          value={localInput[Cell.column] ?? Cell.value ?? ''}
                          onChange={ e => handleInputChange({[Cell.column]: e.target.value }) }
                        />
                        : Cell?.hasDropdown ?
                          // <select value={Cell.value ?? ''} onChange={ e => setUserInput((prev) => ({ ...prev, GISX: e.target.value })) }>
                          <select disabled={Cell.disabled} value={Cell.value ?? ''} onChange={ e => handleInputChange({[Cell.column]: e.target.value}) }>
                            {
                              Cell?.options?.map(option => {
                                return (
                                  <option value={option.value}>{option.value}</option>
                                )
                              })
                            }
                          </select>
                        : Cell ?
                          <Cell />
                        : ''
                      }
                    </td>    
                  )
                })
              }</tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

const PostcodeSearchModal = ({postcodeModal, setPostcodeModal, customPostcode, setCustomPostcode, setLoading, getData}) => {
  return (
    <ReactModal 
    isOpen={postcodeModal} 
    onRequestClose={() => setPostcodeModal(false)}
    className="card bulk-allocate"
    contentLabel="Bulk Allocate COT Alert Data"
    style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}>
      <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
        <h3>Postcode Search</h3>
        <input 
        value={customPostcode} 
        autofocus
        onChange={_ => setCustomPostcode(_.target.value.toUpperCase())} 
        style={{border: '1px solid gray', width: '100%', borderRadius: 3}}
        onKeyDown={(event) => {
          if (event.key === 'Enter' && customPostcode?.length > 3) {
            getData(customPostcode || undefined); setLoading(true); setPostcodeModal(false)
          }
        }}
        />
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
          <button className={`button compact smaller-text ${customPostcode?.length >= 3 ? 'background-primary colour-white' : ''}`} disabled={customPostcode?.length < 3} onClick={() => {getData(customPostcode || undefined); setLoading(true); setPostcodeModal(false)}}>Search</button> 
        </div>
      </div>
    </ReactModal>
  )
}

export const ConfirmModal = ({confirmModal, setConfirmModal, summaryData, cmosUserInput, meterUserInput, setSummaryData, prefix, suffix = ""}) => {
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  return (
    <ReactModal 
    isOpen={confirmModal} 
    onRequestClose={() => setConfirmModal(false)}
    className="card bulk-allocate"
    contentLabel="Wholesaler GAPs No Match"
    style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}>
      <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
        <h3>Confirm</h3>
        { confirmModal === "Reject" ?
            <p>Please confirm you wish to reject these changes.</p>
          : confirmModal === "Quality Check Passed" ?
            <p>Please confirm you wish to pass these changes.</p>
          :
            <p>Please confirm you are finished working on this SPID.</p>
        }
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center'}}>
          <button className="button compact smaller-text cancel" onClick={ () => setConfirmModal(false) }>Cancel</button>
          <button className="button compact smaller-text background-primary colour-white" onClick={() => {
            setLoadingConfirm(true)
            request(true).post(endpoints[`${prefix}_SELECT_MATCH`] + suffix, {
              spid: summaryData.Core_SPID,
              meterId: summaryData.Meter_ID,
              status: confirmModal,
              cmosUserInput: cmosUserInput,
              meterUserInput: meterUserInput,
              ...(summaryData.Module && {module: summaryData.Module})
            }).then(r => {
              setLoadingConfirm(false)
              setSummaryData(null)
              window.reloadCounts()
              setConfirmModal(false); 
            }).catch(error => {
              console.log(error)
              setLoadingConfirm(false)
            })
          }}
          >
            { !loadingConfirm ?
                "Confirm"
              :
                <div className="spinner" style={{ margin: '0em 1em' }}>
                  <i className="fa fa-spinner fa-spin"></i>
                </div>
            }
          </button> 
        </div>
      </div>
    </ReactModal>
  )
}

export const getMDSData = (summaryData, setMdsDataReady, prefix, customMDSPostcode, setMdsData, setMdsMeterData) => {
  if (!summaryData || !summaryData.Core_SPID) return
  setMdsDataReady(false);
  request(true).get(endpoints[`${prefix}_MDS_SPID`], {
    doesCancel: true,
    params: {
        postcode: customMDSPostcode || summaryData.CF_Postcode || summaryData.CL_Postcode,
        cmos_uprn: summaryData.CMOS_UPRN,
        cmos_voa_no: summaryData.CMOS_VOA_No,
    }
  }).then(r => {
    setMdsData(r.data)
    setMdsDataReady(true)
  }).catch(e => {
    setMdsDataReady(true)
      console.log(e);
  })
  request(true).get(endpoints[`${prefix}_MDS_METER`], {
      params: {
          w_spid: summaryData.W_SPID,
          s_spid: summaryData.S_SPID
      }
  }).then(r => {
    setMdsMeterData(r.data);
  }).catch(e => {
    console.log(e);
  })
}
