import React, { useCallback, useMemo, useRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import ReactSelect from 'react-select'
import endpoints from '../../../../helpers/endpoints'
import request from '../../../../helpers/request'
import { GenericTable } from '../../VacantCOTAlerts/sharedComponents'
import Button from '../../../../components/large-link-button/largeLinkButton'
import ReactModal from 'react-modal'
import hasPermission from '../../../../helpers/permissions'

export const Summary = ({summaryData, setLowerSummary, setSummaryData, workflow = false, translations = {}}) => {
  const [spid, setSpid] = useState()
  const [type, setType] = useState('Supply')
  const [workflowItems, setWorkflowItems] = useState([])
  const [confirmhModal, setConfirmModal] = useState(false)
  const [bulkMeterModal, setBulkMeterModal] = useState(false)
  const getSummaryData = useCallback(() => {
    request(true).get(endpoints.WHOLESALER_GAPS_SEARCH_BY_SPID, {
      params: {
        spid,
        type
      }
    }).then(e => {
      setSummaryData(e.data)
    }).catch(e => {
      window.alert('Could not find SPID.')
      setSummaryData([]);
    })
  }, [spid, type, setSummaryData])

  useEffect(() => {
    window.reloadSummaryData = getSummaryData
  }, [getSummaryData])

  const getWorkflowItems = () => {
    request(true).get(`${endpoints.WHOLESALER_GAPS_WF_ITEMS}?stage=1`, {
      params: {
        type
      }
    }).then(e => {
      setWorkflowItems(e.data);
      if (e.data.length > 0) {
        setSpid(e.data[_cur-1] ?? e.data[0])
      }
    })
  }

  const _cur = useMemo(() => {
    return workflowItems.findIndex(_ => _ === spid);
  }, [workflowItems, spid]);

  const getWorkflowNext = () => {
    if (_cur !== workflowItems.length-1) {
      setSpid(workflowItems[_cur+1]);
    }
  }

  const getWorkflowLast = () => {
    if (_cur !== 0) {
      setSpid(workflowItems[_cur-1]);
    }
  }

  const buyWorkflowItem = () => {
    request(true).post(endpoints.WHOLESALER_GAPS_PURCHASE, {
      spid,
      type
    }).then(e => {
      window.alert('OK')
      getWorkflowItems()
    }).catch(() => {
      window.alert('Could not purchase SPID');
      getWorkflowItems()
    });
  }

  const excludeWorkflowItem = () => {
    request(true).post(endpoints.WHOLESALER_GAPS_EXCLUDE, {
      spid,
      type
    }).then(e => {
      window.alert('OK')
      getWorkflowItems()
    }).catch(() => {
      window.alert('Could not exclude SPID');
      getWorkflowItems()
    });
  }

  useEffect(() => {
    if (workflow) {
      getWorkflowItems();
    }
  }, [type])

  useEffect(() => {
    if (workflow) {
      setSpid('')
      setWorkflowItems([])
      setSummaryData({})
      getWorkflowItems()
    }
  }, [type])

  useEffect(() => {
    if (workflow && spid) {
      getSummaryData()
    }
  }, [spid])
  return (
    <>
      <table className='table borders squish smaller-text left' style={{tableLayout: 'fixed', width: '100%'}}>
      {/* <input type='text' /> */}
        <col width={180} />
        <thead>
          <tr>
            <th colSpan={16}>{translations['Header_Summary']}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colspan={1}>{translations['SPID']}</td>
            <td colspan={5}>
              <div style={{display: 'flex', gap: 10, alignItems: 'center'}}>
                <input disabled={workflow ?? false} style={{border: 'none'}} type="text" onChange={e => setSpid(e.target.value)} value={spid} />
                {
                  workflow ? <>
                    <span className='fas fa-angle-left colour-primary' style={{cursor: 'pointer'}} onClick={getWorkflowLast}></span>
                    <span>{_cur+1}/{workflowItems.length}</span>
                    <span className='fas fa-angle-right colour-primary' style={{cursor: 'pointer'}} onClick={getWorkflowNext}></span>
                  </> : <>
                    <span className='fas fa-search colour-primary' style={{cursor: 'pointer'}} onClick={getSummaryData}></span>
                  </>
                }
              </div>
            </td>
            <td colspan={1}>UPRN Status</td>
            <td colspan={2}>{summaryData?.Status_UPRN ? summaryData.Status_UPRN : ''}</td>
            <td colspan={1}>VOA Status</td>
            <td colspan={2}>{summaryData?.Status_VOA_No ? summaryData.Status_VOA_No: ''}</td>
            <td colspan={2}>
              <button onClick={() => setBulkMeterModal(true)} className='button compact smaller-text cancel'>
                Bulk Meter
              </button>
            </td>
            <td colspan={2}>
              <button onClick={() => setConfirmModal(true)} className='button compact smaller-text background-primary colour-white'>
                Confirm
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <ReactModal 
      isOpen={confirmhModal} 
      onRequestClose={() => setConfirmModal(false)}
      className="card bulk-allocate"
      contentLabel="Wholesaler GAPs No Match"
      style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}>
        <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
          <h3>Confirm</h3>
          <p>Please confirm you are finished working on this SPID.</p>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center'}}>
            <button className="button compact smaller-text cancel" onClick={ () => setConfirmModal(false) }>Cancel</button>
            <button className="button compact smaller-text background-primary colour-white" onClick={() => {
              request(true).post(endpoints.WHOLESALER_GAPS_SELECT_MATCH, {
                spid: summaryData.SPID,
              }).then(r => {
                window.alert('Update confirmed.')
                if (workflow) getWorkflowItems()
                window.reloadSummaryData()
                setConfirmModal(false); 
              })
            }}
            >Confirm</button> 
          </div>
        </div>
      </ReactModal>
      <ReactModal 
      isOpen={bulkMeterModal} 
      onRequestClose={() => setBulkMeterModal(false)}
      className="card bulk-allocate"
      contentLabel="Wholesaler GAPs Bulk Meter"
      style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' }, content: { width: '33vw' } }}>
        <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
          <h3>Confirm</h3>
          <p>Please confirm you wish to mark all unconfirmed records of this postcode as potentially part of a bulk meter.</p>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center'}}>
            <button className="button compact smaller-text cancel" onClick={ () => setBulkMeterModal(false) }>Cancel</button>
            <button className="button compact smaller-text background-primary colour-white" onClick={() => {
              request(true).post(endpoints.WHOLESALER_GAPS_SELECT_MATCH, {
                spid: summaryData.SPID,
                bulkMeter: 'true'
              }).then(r => {
                window.alert('Update confirmed.')
                if (workflow) getWorkflowItems()
                window.reloadSummaryData()
                setBulkMeterModal(false); 
              })
            }}
            >Confirm</button> 
          </div>
        </div>
      </ReactModal>
    </>
  )
}

export const CMOSLive = ({ translations, summaryData: data, uprnOrVoa }) => {
  const rows = useMemo(() => {
    return [
      uprnOrVoa === 'uprn' ? [translations['CL_UPRN'], 'CL_UPRN'] : [translations['CF_VOA_BA_Reference'], 'CF_VOA_BA_Reference'],
      [translations['Customer_Name'], 'Customer_Name'],
      [translations['CL_PAF_Address_Key'], 'CL_PAF_Address_Key'],
      [translations['CL_Free_Descriptor'], 'CL_Free_Descriptor'],
      [translations['CL_Secondary_Addressable_Object'], 'CL_Secondary_Addressable_Object'],
      [translations['CL_Primary_Addressable_Object'], 'CL_Primary_Addressable_Object'],
      [translations['CL_Address_Line_1'], 'CL_Address_Line_1'],
      [translations['CL_Address_Line_2'], 'CL_Address_Line_2'],
      [translations['CL_Address_Line_3'], 'CL_Address_Line_3'],
      [translations['CL_Address_Line_4'], 'CL_Address_Line_4'],
      [translations['CL_Address_Line_5'], 'CL_Address_Line_5'],
      [translations['CL_Postcode'], 'CL_Postcode'],
    ]
  }, [translations])
  return (
    <div>
      <GenericTable style={{height: 'unset'}} biggerSecondRow={false} firstColWidth={150} title={translations['CMOS_Supply_Address']} rows={rows} extraClass="squish smaller-text left" evidence={false} data={data} />
    </div>
  )
}

export const AddressBase = ({ translations, summaryData: data, selectedMatches, setSelectedMatches }) => {
  const [addresses, setAddresses] = useState([]);
  const [postcodeModal, setPostcodeModal] = useState(false);
  const [customPostcode, setCustomPostcode] = useState('');

  const getData = (customInput) => {
    if (!customPostcode && (!data.Type || (!data.ABP_Postcode && data.Address_Status === 'Invalid Postcode'))) return;
    setAddresses([]);
    request(true)
      .get(endpoints.WHOLESALER_GAPS_GET_ADDRESSES, {
        params: {
          postcode: customInput || customPostcode || data.ABP_Postcode || data.CL_Postcode
        }
      })
      .then(e => {
        const _data = e.data;
        setAddresses(_data);
        if (data.ABP_UPRN) {
          const displayFirst = _data.findIndex(_ => _.UPRN === data.Matched_UPRN);
          setSelectedMatches((prevMatches) => {
            return {
              ...prevMatches,
              abp:  _data[displayFirst]
            };
          });
        }
      })
      .catch(_ => { window.alert('Could not get Address Base data.'); setAddresses([]); });
  };

  useEffect(() => {
    setAddresses([])
    if (data.SPID) {
      setCustomPostcode('');
      getData(data.ABP_Postcode || data.CL_Postcode)
    }
  }, [data.SPID]);

  useEffect(() => {
    if (customPostcode === '') {
      getData();
    }
  }, [customPostcode])

  const originalPostcode = useMemo(() => {
    return data.ABP_Postcode || data.CL_Postcode;
  }, [data.ABP_Postcode, data.CL_Postcode]);

  const selectMatch = useCallback((selected) => {
    setSelectedMatches((prevMatches) => {
      return {
        ...prevMatches,
        abp: prevMatches.abp === selected ? {} : selected
      };
    });
  }, [setSelectedMatches]);

  return (
    <div>
      <table className='table borders squish smaller-text left'>
        <thead>
          <tr>
            <th style={{ textAlign: 'start' }} colSpan={2}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {translations['Header_AddressBase']}
                <div style={{ marginLeft: 'auto', display: 'flex', gap: 10 }}>
                  {customPostcode && (
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <span className='fas fa-trash-undo' onClick={() => { setCustomPostcode(''); getData(); }} title="Restore original postcode search"></span>
                    </div>
                  )}
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <span className='fas fa-search' onClick={() => setPostcodeModal(true)} title="Search for a different postcode"></span>
                  </div>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody style={{ overflowY: 'auto', maxHeight: "30em", display: 'block' }}>
          {addresses.map(address => (
            <tr key={address.UPRN}>
              <td>{address?.SINGLE_LINE_ADDRESS ?? ''}</td>
              <td>
                {address?.SINGLE_LINE_ADDRESS && (
                  <input type="checkbox" name={address.SINGLE_LINE_ADDRESS} onChange={() => selectMatch(address)} checked={selectedMatches.abp === address} />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ReactModal
        isOpen={postcodeModal}
        onRequestClose={() => setPostcodeModal(false)}
        className="card bulk-allocate"
        contentLabel="Bulk Allocate COT Alert Data"
        style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          <h3>Postcode Search</h3>
          <input value={customPostcode ? customPostcode : originalPostcode} onChange={_ => setCustomPostcode(_.target.value.toUpperCase())} style={{ border: '1px solid gray', width: '100%', borderRadius: 3 }} />
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <button className={`button compact smaller-text ${customPostcode?.length >= 3 ? 'background-primary colour-white' : ''}`} disabled={customPostcode?.length < 3} onClick={() => { getData(customPostcode); setPostcodeModal(false); }}>Search</button>
          </div>
        </div>
      </ReactModal>
    </div>
  );
};


export const CMOSUPRN = ({ translations, summaryData: data, selectedMatches, setSelectedMatches }) => {  
  const [address, setAddress] = useState([])

  useEffect(() => {
    setAddress([])
    getData()
  }, [data])

  const getData = () => {
    if (!data.CMOS_UPRN) return
    request(true).get(endpoints.WHOLESALER_GAPS_GET_CMOS_ADDRESSES, {
      params: {
        uprn: data.CMOS_UPRN
      }
    }).then(r => {
      r.data.STATUS = r.data?.CHANGE_CODE === 'D' ? 'Deleted' : 'Active';
      setAddress(r.data)
    })
  }

  const selectMatch = useCallback(() => {
    setSelectedMatches((prevMatches) => {
      return {
        ...prevMatches,
        abp: prevMatches.abp === address ? {} : address,
      };
    });
  }, [address, setSelectedMatches]);

  const rows = useMemo(() => {
    return [
      [translations['UPRN'], 'UPRN'],
      [translations['STATUS'], 'STATUS'],
      [translations['UDPRN'], 'UDPRN'],
      [translations['ORGANISATION'], 'ORGANISATION'],
      [translations['SUB_BUILDING'], 'SUB_BUILDING'],
      [translations['BUILDING_NAME'], 'BUILDING_NAME'],
      [translations['BUILDING_NUMBER'], 'BUILDING_NUMBER'],
      [translations['STREET_NAME'], 'STREET_NAME'],
      [translations['LOCALITY'], 'LOCALITY'],
      [translations['TOWN_NAME'], 'TOWN_NAME'],
      [translations['POST_TOWN'], 'POST_TOWN'],
      [translations['Postcode'], 'POSTCODE'],
      ['', () => <button className={`button compact smaller-text ${selectedMatches.abp === address ? 'background-primary colour-white' : ''}`} onClick={() => selectMatch()}>Select Match</button>]
    ]
  }, [translations, address, selectMatch, selectedMatches])
  return (
    <div>
      <GenericTable style={{height: 'unset'}} biggerSecondRow={false} firstColWidth={150} title={translations['CMOS_UPRN']} rows={rows} extraClass="squish smaller-text left" evidence={false} data={address} />
    </div>
  )
}

export const CMOSVOA = ({ translations, summaryData: data, selectedMatches, setSelectedMatches }) => {  
  const [address, setAddress] = useState([])

  useEffect(() => {
    setAddress([])
    getData()
  }, [data])

  const getData = () => {
    if (!data.CL_VOA_BA_Reference) return
    request(true).get(endpoints.WHOLESALER_GAPS_GET_CMOS_ADDRESSES_VOA, {
      params: {
        uprn: data.CL_VOA_BA_Reference
      }
    }).then(r => {
      r.data.Source = r.data.Wholesaler === "SCOTTISH-W" ? 'SAA' : 'Business Rates'
      r.data.Deletion_Date = r.data.Effective_Status === 'DELETED' ? r.data.Current_From_Date : ''
      setAddress(r.data)
    })
  }

  const selectMatch = useCallback(() => {
    if (selectedMatches.voa === address) {
      setSelectedMatches((prevMatches) => {
        return {
          ...prevMatches,
          voa: {}
        };
      });
    } else {
      setSelectedMatches((prevMatches) => {
        return {
          ...prevMatches,
          voa: address
        };
      });
    }
  }, [address, selectedMatches, setSelectedMatches])

  const rows = useMemo(() => {
    return [
      [translations['BA_Reference_Number'], 'BA_Reference_Number'],
      [translations['Effective_Status'], data.Wholesaler === "SCOTTISH-W" ? 'OCC_STATUS' : 'Effective_Status'],
      [translations['Source'], 'Source'],
      [translations['Number_Or_Name'], data.Wholesaler === "SCOTTISH-W" ? 'PAON' : 'Number_Or_Name'],
      [translations['Street'], data.Wholesaler === "SCOTTISH-W" ? 'STREET' : 'Street'],
      [translations['Sub_Street_level_1'], 'Sub_Street_level_1'],
      [translations['Town'], data.Wholesaler === "SCOTTISH-W" ? 'TOWN' : 'Town'],
      [translations['Postal_District'], data.Wholesaler === "SCOTTISH-W" ? "ADMIN_AREA" : 'Postal_District'],
      [translations['County'], data.Wholesaler === "SCOTTISH-W" ? "UNITARY_AUTHORITY" : 'County'],
      [translations['Postcode'], data.Wholesaler === "SCOTTISH-W" ? "BR_Postcode" : 'Postcode'],
      [translations['Effective_Status'], data.Wholesaler === "SCOTTISH-W" ? 'OCC_STATUS' : 'Effective_Status'],
      [translations['Deletion_Date'], 'Deletion_Date'],
      ['', () => <button className={`button compact smaller-text ${selectedMatches.voa === address ? 'background-primary colour-white' : ''}`} onClick={() => selectMatch()}>Select Match</button>]
    ]
  }, [translations, address, selectMatch, selectedMatches])
  return (
    <div>
      <GenericTable style={{height: 'unset'}} biggerSecondRow={false} firstColWidth={150} title={translations['CMOS_VOA']} rows={rows} extraClass="squish smaller-text left" evidence={false} data={address} />
    </div>
  )
}

export const IdenteqUPRN = ({ translations, summaryData: data, selectedMatches, setSelectedMatches }) => {
  const [address, setAddresses] = useState([])

  useEffect(() => {
    setAddresses([])
    getData()
  }, [data])

  const getData = () => {
    if (!data.Matched_UPRN) return
    request(true).get(endpoints.WHOLESALER_GAPS_GET_IDENTEQ_ADDRESSES, {
      params: {
        uprn: data.Matched_UPRN
      }
    }).then(r => {
      r.data.STATUS = r.data?.CHANGE_CODE === 'D' ? 'Deleted' : 'Active';
      setAddresses(r.data)
    })
  }

  const selectMatch = useCallback(() => {
    if (selectedMatches.abp === address) {
      setSelectedMatches((prevMatches) => {
        return {
          ...prevMatches,
          abp: {}
        };
      });
    } else {
      setSelectedMatches((prevMatches) => {
        return {
          ...prevMatches,
          abp: address
        };
      });
    }

  }, [address, selectedMatches, setSelectedMatches])

  const rows = useMemo(() => {
    return [
      [translations['UPRN'], 'UPRN'],
      [translations['STATUS'], 'STATUS'],
      [translations['UDPRN'], 'UDPRN'],
      [translations['ORGANISATION'], 'ORGANISATION'],
      [translations['SUB_BUILDING'], 'SUB_BUILDING'],
      [translations['BUILDING_NAME'], 'BUILDING_NAME'],
      [translations['BUILDING_NUMBER'], 'BUILDING_NUMBER'],
      [translations['STREET_NAME'], 'STREET_NAME'],
      [translations['LOCALITY'], 'LOCALITY'],
      [translations['TOWN_NAME'], 'TOWN_NAME'],
      [translations['POST_TOWN'], 'POST_TOWN'],
      [translations['Postcode'], 'POSTCODE'],
      ['', () => <button className={`button compact smaller-text ${selectedMatches.abp === address ? 'background-primary colour-white' : ''}`} onClick={() => selectMatch()}>Select Match</button>]
    ]
  }, [translations, address, selectMatch, selectedMatches])
  return (
    <div>
      <GenericTable style={{height: 'unset'}} biggerSecondRow={false} firstColWidth={150} title={translations['Identeq_UPRN']} rows={rows} extraClass="squish smaller-text left" evidence={false} data={address} />
    </div>
  )
}

export const IdenteqVOA = ({ translations, summaryData: data, selectedMatches, setSelectedMatches }) => {
  const [address, setAddresses] = useState([])

  useEffect(() => {
    setAddresses([])
    getData()
  }, [data])

  const getData = () => {
    if (!data.Matched_VOA_No) return
    request(true).get(endpoints.WHOLESALER_GAPS_GET_IDENTEQ_ADDRESSES_VOA, {
      params: {
        uprn: data.Matched_VOA_No
      }
    }).then(r => {
      r.data.Source = r.data.Wholesaler === "SCOTTISH-W" ? 'SAA' : 'Business Rates'
      r.data.Deletion_Date = r.data.Effective_Status === 'DELETED' ? r.data.Current_From_Date : ''
      setAddresses(r.data)
    })
  }

  const selectMatch = useCallback(() => {
    if (selectedMatches.voa === address) {
      setSelectedMatches((prevMatches) => {
        return {
          ...prevMatches,
          voa: {}
        };
      });
    } else {
      setSelectedMatches((prevMatches) => {
        return {
          ...prevMatches,
          voa: address
        };
      });
    }

  }, [address, selectedMatches, setSelectedMatches])
  const rows = useMemo(() => {
    return [
      [translations['BA_Reference_Number'], 'BA_Reference_Number'],
      [translations['Effective_Status'], data.Wholesaler === "SCOTTISH-W" ? 'OCC_STATUS' : 'Effective_Status'],
      [translations['Source'], 'Source'],
      [translations['Number_Or_Name'], data.Wholesaler === "SCOTTISH-W" ? 'PAON' : 'Number_Or_Name'],
      [translations['Street'], data.Wholesaler === "SCOTTISH-W" ? 'STREET' : 'Street'],
      [translations['Sub_Street_level_1'], 'Sub_Street_level_1'],
      [translations['Town'], data.Wholesaler === "SCOTTISH-W" ? 'TOWN' : 'Town'],
      [translations['Postal_District'], data.Wholesaler === "SCOTTISH-W" ? "ADMIN_AREA" : 'Postal_District'],
      [translations['County'], data.Wholesaler === "SCOTTISH-W" ? "UNITARY_AUTHORITY" : 'County'],
      [translations['Postcode'], data.Wholesaler === "SCOTTISH-W" ? "BR_Postcode" : 'Postcode'],
      [translations['Effective_Status'], data.Wholesaler === "SCOTTISH-W" ? 'OCC_STATUS' : 'Effective_Status'],
      [translations['Deletion_Date'], 'Deletion_Date'],
      ['', () => <button className={`button compact smaller-text ${selectedMatches.voa === address ? 'background-primary colour-white' : ''}`} onClick={() => selectMatch()}>Select Match</button>]
    ]
  }, [translations, address, selectMatch, selectedMatches])
  return (
    <div>
      <GenericTable style={{height: 'unset'}} biggerSecondRow={false} firstColWidth={150} title={translations['IDenteq_VOA']} rows={rows} extraClass="squish smaller-text left" evidence={false} data={address} />
    </div>
  )
}

export const ValuationOffice = ({ translations, summaryData: data, selectedMatches, setSelectedMatches }) => {
  const [voas, setVoas] = useState([])
  const [postcodeModal, setPostcodeModal] = useState(false)
  const [customPostcode, setCustomPostcode] = useState('');

  const getData = (external) => {
    if (!customPostcode && (!data.Type || (!data.ABP_Postcode && data.Address_Status === 'Invalid Postcode'))) return
    request(true).get(data.Wholesaler === "SCOTTISH-W" ? endpoints.WHOLESALER_GAPS_GET_SAA : endpoints.WHOLESALER_GAPS_GET_VOAS, {
      params: {
        postcode: external || customPostcode || data.VO_Postcode || data.CL_Postcode
      }
    }).then(e => {
      const _data = e.data
      setVoas(_data);
      if (data.Matched_VOA_No) {
        const displayFirst = _data.findIndex(_ => _.BA_Reference_Number === data.Matched_VOA_No && _.Postcode === data.CL_Postcode) 
        setSelectedMatches((prevMatches) => {
          return {
            ...prevMatches,
            voa: _data[displayFirst]
          };
        });
      }
    }).catch(() => {
      window.alert('Could not get Valuation Office data');
      setVoas([])
    })
  }

  useEffect(() => {
    setVoas([])
    if (data.SPID) {
      setCustomPostcode('');
      getData(data.VO_Postcode || data.CL_Postcode)
    }
  }, [data.SPID])

  useEffect(() => {
    if (customPostcode === '') {
      getData();
    }
  }, [customPostcode])

  const selectedVOA = useMemo(() => {
    const selected = selectedMatches.voa?.BA_Reference_Number ? selectedMatches.voa : null;
    if (!selected ) return {}
    selected.Source = data.Wholesaler === "SCOTTISH-W" ? 'SAA' : 'Business Rates'
    selected.Deletion_Date = selected.Effective_Status === 'DELETED' ? selected.Current_From_Date : ''
    // setPostcode(selected.Postcode)
    return selected
  }, [voas, selectedMatches]);

  const selectMatch = useCallback((selected) => {
    if (selectedMatches.voa === selected) {
      setSelectedMatches((prevMatches) => {
        return {
          ...prevMatches,
          voa: {}
        };
      });
    } else {
      setSelectedMatches((prevMatches) => {
        return {
          ...prevMatches,
          voa: selected
        };
      });
    }

  }, [selectedVOA, selectedMatches, setSelectedMatches])

  const originalPostcode = useMemo(() => {
    return data.VO_Postcode || data.CL_Postcode
  }, [data.ABP_Postcode, data.CL_Postcode])

  return [
    <div>
      <table className='table borders squish smaller-text left'>
        <thead>
          <tr>
            {
              <th style={{ textAlign: 'start' }} colSpan={2}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  {translations['Header_Valuation']}
                  <div style={{marginLeft: 'auto', display: 'flex', gap: 10}}>
                    {
                      customPostcode ? <>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                          <span className='fas fa-trash-undo' onClick={() => {setCustomPostcode('');getData()}} title="Restore original postcode search"></span>
                        </div>
                      </> : null
                    }
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                      <span className='fas fa-search' onClick={() => {setPostcodeModal(true)}} title="Search for a different postcode"></span>
                    </div>
                  </div>
                </div>
              </th>
            }
          </tr>
        </thead>
        <tbody style={{ overflowY: 'auto', maxHeight: "30em", display: 'block' }}>
          {
            voas
            .filter(voa => {
              return voa.Effective_Status !== "DELETED"
            })
            .map(address => {
              return (
                <tr>
                  <td>{address?.Full_Property_Identifier ?? ''}</td>
                  <td>
                    { address?.Full_Property_Identifier ?
                        <input type="checkbox" name={ address.Full_Property_Identifier } onChange={ () => selectMatch( address )} checked={ selectedMatches.voa === address }/>
                      : null
                    }
                  </td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </div>,
    <ReactModal 
    isOpen={postcodeModal} 
    onRequestClose={() => setPostcodeModal(false)}
    className="card bulk-allocate"
    contentLabel="Bulk Allocate COT Alert Data"
    style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}>
      <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
        <h3>Postcode Search</h3>
        <input value={customPostcode ? customPostcode : originalPostcode} onChange={_ => setCustomPostcode(_.target.value.toUpperCase())} style={{border: '1px solid gray', width: '100%', borderRadius: 3}} />
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
          <button className={`button compact smaller-text ${customPostcode?.length >= 3 ? 'background-primary colour-white' : ''}`} disabled={customPostcode?.length < 3} onClick={() => {getData(customPostcode); setPostcodeModal(false)}}>Search</button> 
        </div>
      </div>
    </ReactModal>
  ]
}

export const CMOSFormatted = ({ translations, summaryData: data, selectedMatches }) => {    
  const updateAddress = () => {
    request(true).post(endpoints.WHOLESALER_GAPS_SELECT_MATCH, {
      spid: data.SPID,
      abp_uprn: selectedMatches.abp?.UPRN
    }).then(r => {
      window.reloadSummaryData()
    })
  }

  const clearAddress = () => {
    request(true).post(endpoints.WHOLESALER_GAPS_CLEAR_MATCH, {
      spid: data.SPID,
      abp_uprn: data.CF_UPRN,
    }).then(r => {
      window.reloadSummaryData()
    })
  }
  
  return (
    <table className='table borders squish smaller-text left'>
    <thead>
      <tr>
        {
          <th style={{ textAlign: 'start' }} colSpan={2}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              {translations['UPRN_Retrieved']}
            </div>
          </th>
        }
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>{translations['CF_UPRN']}</td>
        <td>{data.CF_VOA_BA_Reference ?? ''}</td>
      </tr>
      <tr>
        <td>{translations['Customer_Name']}</td>
        <td>{data.Customer_Name ?? ''}</td>
      </tr>
      <tr>
        <td>{translations['CF_PAF_Address_Key']}</td>
        <td>{data.CF_PAF_Address_Key ?? ''}</td>
      </tr>
      <tr>
        <td>{translations['CF_Free_Descriptor']}</td>
        <td>{data.CF_Free_Descriptor ?? ''}</td>
      </tr>
      <tr>
        <td>{translations['CF_Secondary_Addressable_Object']}</td>
        <td>{data.CF_Secondary_Addressable_Object ?? ''}</td>
      </tr>
      <tr>
        <td>{translations['CF_Primary_Addressable_Object']}</td>
        <td>{data.CF_Primary_Addressable_Object ?? ''}</td>
      </tr>
      <tr>
        <td>{translations['CF_Address_Line_1']}</td>
        <td>{data.CF_Address_Line_1 ?? ''}</td>
      </tr>
      <tr>
        <td>{translations['CF_Address_Line_2']}</td>
        <td>{data.CF_Address_Line_2 ?? ''}</td>
      </tr>
      <tr>
        <td>{translations['CF_Address_Line_3']}</td>
        <td>{data.CF_Address_Line_3 ?? ''}</td>
      </tr>
      <tr>
        <td>{translations['CF_Address_Line_4']}</td>
        <td>{data.CF_Address_Line_4 ?? ''}</td>
      </tr>
      <tr>
        <td>{translations['CF_Address_Line_5']}</td>
        <td>{data.CF_Address_Line_5 ?? ''}</td>
      </tr>
      <tr>
        <td>{translations['CF_Postcode']}</td>
        <td>{data.CF_Postcode ?? ''}</td>
      </tr>
      <tr>
        <td>
          <button
            className={`button compact smaller-text ${data?.CF_UPRN && hasPermission('WHOLESALER_GAPS', 'PURCHASE') ? 'cancel' :''}`} 
            disabled={!data?.CF_UPRN && hasPermission('WHOLESALER_GAPS', 'PURCHASE')}
            onClick={() => {
              if (hasPermission('WHOLESALER_GAPS', 'PURCHASE')) {
                clearAddress()
              }
            }}            
          >
            Clear Address
          </button>
        </td>
        <td>
          <button 
          className={`button compact smaller-text ${selectedMatches.abp?.UPRN && selectedMatches.abp.UPRN != data.Matched_UPRN && hasPermission('WHOLESALER_GAPS', 'PURCHASE') ? 'background-primary colour-white' : ''}`} 
          disabled={(!selectedMatches.abp?.UPRN || selectedMatches.abp?.UPRN == data.Matched_UPRN) && hasPermission('WHOLESALER_GAPS', 'PURCHASE')}
          onClick={() => {
            if (hasPermission('WHOLESALER_GAPS', 'PURCHASE')) {
              updateAddress()
            }
          }}
          >
            Update Address
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  )
}

export const CMOSFormattedVOA = ({ translations, summaryData: data, selectedMatches }) => {
  const updateAddress = () => {
    request(true).post(endpoints.WHOLESALER_GAPS_SELECT_MATCH, {
      spid: data.SPID,
      ba_reference: selectedMatches.voa?.BA_Reference_Number
    }).then(r => {
      window.reloadSummaryData()
    })
  }

  const clearAddress = () => {
    request(true).post(endpoints.WHOLESALER_GAPS_CLEAR_MATCH, {
      spid: data.SPID,
      ba_reference: data?.CF_VOA_BA_Reference,
    }).then(r => {
      window.reloadSummaryData()
    })
  }

  return (
    <table className='table borders squish smaller-text left'>
      <thead>
        <tr>
          {
            <th style={{ textAlign: 'start' }} colSpan={2}>
              <div style={{display: 'flex', alignItems: 'center'}}>
                {translations['VOA_Retrieved']}
              </div>
            </th>
          }
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{translations['CF_VOA_BA_Reference']}</td>
          <td>{data.CF_VOA_BA_Reference ?? ''}</td>
        </tr>
        <tr>
          <td>{translations['Customer_Name']}</td>
          <td>{data.Customer_Name ?? ''}</td>
        </tr>
        <tr>
          <td>{translations['Source']}</td>
          <td>{data.Source ?? ''}</td>
        </tr>
        <tr>
          <td>{translations['Number_Or_Name']}</td>
          <td>{data.VO_Number_Or_Name ?? ''}</td>
        </tr>
        <tr>
          <td>{translations['Street']}</td>
          <td>{data.VO_Street ?? ''}</td>
        </tr>
        <tr>
          <td>{translations['Sub_Street_level_1']}</td>
          <td>{data.Sub_Street_level_1 ?? ''}</td>
        </tr>
        <tr>
          <td>{translations['Town']}</td>
          <td>{data.VO_Town ?? ''}</td>
        </tr>
        <tr>
          <td>{translations['Postal_District']}</td>
          <td>{data.VO_Postal_District ?? ''}</td>
        </tr>
        <tr>
          <td>{translations['County']}</td>
          <td>{data.VO_County ?? ''}</td>
        </tr>
        <tr>
          <td>{translations['Postcode']}</td>
          <td>{data.VO_Postcode ?? ''}</td>
        </tr>
        <tr>
          <td>{translations['Effective_Status']}</td>
          <td>{data.VO_Effective_Status ?? ''}</td>
        </tr>
        <tr>
          <td>{translations['Deletion_Date']}</td>
          <td>{data.Deletion_Date ?? ''}</td>
        </tr>
        <tr>
          <td>
            <button
              className={`button compact smaller-text ${data?.CF_VOA_BA_Reference && hasPermission('WHOLESALER_GAPS', 'PURCHASE') ? 'cancel' :''}`} 
              disabled={!data?.CF_VOA_BA_Reference && hasPermission('WHOLESALER_GAPS', 'PURCHASE')}
              onClick={() => {
                if (hasPermission('WHOLESALER_GAPS', 'PURCHASE')) {
                  clearAddress()
                }
              }}            
            >
              Clear Address
            </button>
          </td>
          <td>
            <button 
              className={`button compact smaller-text ${selectedMatches.voa?.BA_Reference_Number && selectedMatches.voa.BA_Reference_Number != data.Matched_VOA_No && hasPermission('WHOLESALER_GAPS', 'PURCHASE') ? 'background-primary colour-white' :''}`} 
              disabled={(!selectedMatches.voa?.BA_Reference_Number || selectedMatches.voa?.BA_Reference_Number == data.Matched_VOA_No) && hasPermission('WHOLESALER_GAPS', 'PURCHASE')}
              onClick={() => {
                if (hasPermission('WHOLESALER_GAPS', 'PURCHASE')) {
                  updateAddress()
                }
              }}
            >
              Update Address
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  )
}
