import React, { Component } from 'react'
import Navigation from '../../../components/navigation/navigation'
import { ReactComponent as HouseIcon } from '../../../assets/House Icon.svg'
import Container from '../../../components/container/container'
import Footer from '../../../components/footer/footer'
import request from '../../../helpers/request'
import endpoints from '../../../helpers/endpoints'
import { view } from '@risingstack/react-easy-state'
import './vacantCOTAlerts.scss';

import { Link, withRouter, matchPath } from 'react-router-dom'
import session from '../../../stores/session'
import HelpVideo from '../../../components/help-video/helpVideo'
import useWSProduct from '../../../helpers/useWSProduct'

// import {DeedPDFViewer} from './voidSalesAlertsSearch'
import {GoogleEvidence,AddressEvidence,YellComEvidence,CompaniesHouseEvidence,RightColumn, LeftColumn, FoodStandardsEvidence, BTDirectoryEvidence, CharityCommisionEvidence, DVSAEvidence, CQCEvidence, JustEatEvidence, One92Evidence, FacebookEvidence, TitleOwnerRow} from './sharedComponents'
import ProductIcons from '../../../helpers/productIcons'
import ReactModal from 'react-modal'
import PropertyMap from '../../../components/property-map/property-map'
import PageTitle from '../../../components/page-header/pageTitle'
import DatePicker from 'react-datepicker'
import Select from "react-select";

export default view(
    withRouter(
        class VacantCOTAlertsWorkflow extends Component {
            constructor(props) {
                super(props)

                this.state = {
                    translations: null,
                    postcodeModal: false,
                    abpIndex: 0,
                    accountNumber: null,
                    customer: null,
                    moveInDate: null,
                    SIC_Code: null,
                    customPostcode: null,
                    ABPAddresses: null,
                    addUserDisplay: {indexToDisplay: null, customContacts: {}},

                    pathname: null,
                    workflowIndex: null,
                    wholesaler: null,

                    errors: {moveIn: null, SIC_Code: null},
                }
            }

            async componentDidMount() {
                useWSProduct('void_sales_alerts');// eslint-disable-line
                request(true).get('/translate?key=vacant_cot_alerts').then(r => {
                    this.setState({
                        translations: r.data
                    });
                }).catch(e => {
                    console.error(e);
                })


                const {history} = this.props;

                const spid = history.location?.state?.spid;
                const index = history.location?.state?.index;
                const wholesaler = history.location?.state?.wholesaler;
                const pathname = history.location?.state?.prevPath;

                if (!spid) {
                    this.props.history.goBack();
                }
                
                this.setState({accountNumber: spid, pathname: pathname, workflowIndex: index, wholesaler: wholesaler}, () => {
                    request(true).get(`${endpoints.VACANT_COT_ALERTS_GET_CUSTOMER}?search=${this.state.accountNumber}`).then(r => {
                        this.setState(prevState => ({
                            customer: r.data.customer,
                            addUserDisplay: {
                                customContacts: {
                                    ...prevState.addUserDisplay.customContacts,
                                    ...[1, 2, 3].reduce((acc, groupNumber) => {
                                        const firstName = r.data.customer[`CH_Director_First_Name_${groupNumber}`];
                                        const lastName = r.data.customer[`CH_Director_Second_Name_${groupNumber}`];
                                        
                                        if (!firstName && !lastName) {
                                            acc[groupNumber] = false;
                                        }
                                        
                                        return acc;
                                    }, {})
                                }
                            }
                        }))
                    })
                })
            }

            getABPPostcodeData = () => {
                if (!this.state.customPostcode) return
                request(true).get(endpoints.VACANT_COT_ALERTS_ABP_BY_POSTCODE, {
                    params: {
                        postcode: this.state.customPostcode || this.state.customer.CL_Postcode || this.state.customer.ABP_Postcode
                    }
                }).then(r => {
                    const index = r.data.findIndex((address) => address.UPRN == this.state.customer.UPRN) 
                    this.setState({
                        ABPAddresses: r.data,
                        abpIndex: index !== -1 ? index : 0
                    })
                }).catch(error => {
                    console.log(error)
                })
            }

            submit = () => {
                this.setState({
                    errors: {
                        moveIn: !this.state.moveInDate ? "Move in date required" : null,
                        SIC_Code: !this.state.customer.SIC_Code && !this.state.SIC_Code ? "SIC Code required" : null,
                    }
                }, () => {
                    if (this.state.errors.moveIn || this.state.errors.SIC_Code) {
                        window.alert(Object.values(this.state.errors).join('\n'))
                        return;
                    }

                    request(true).post(endpoints.VACANT_COT_ALERTS_CONFIRMATION, {
                        "customer": this.state.customer,
                        "occupier_purchase_type": "IDenteq",
                        "moveInDate": this.state.moveInDate,
                        "SIC_Code": this.state.SIC_Code,
                    }).then(r => {
                        window.alert("Submission successful")
                        // this.props.history.goBack()
                        this.handleGoBack();
                    }).catch(error => {
                        console.log(error)
                        if (error.response && error.response.status == 400) {
                            window.alert("No account found")
                        } else {
                            window.alert("Submission error")
                        }
                    })
                })

            }

            handleGoBack = () => {
                var pathname = this.state.pathname
                if (pathname && pathname.indexOf('confirmation') !== -1) {
                    pathname = '/portal/vacant-cot-alerts/'
                }
                this.props.history.push({
                    pathname: this.state.pathname ? this.state.pathname : '/portal/vacant-cot-alerts/', 
                    state: {spid: this.state.accountNumber, workflowIndex: this.state.workflowIndex, wholesaler: this.state.wholesaler}
                }) 
            }

            updateSICCode = (value) => {
                this.setState((prevState) => ({
                        SIC_Code: value, 
                        errors: {...prevState.errors, SIC_Code: null}
                    }
                ))
            }

            updateMoveInDate = (date) => {
                this.setState(prevState => ({
                        moveInDate: date, 
                        errors: {...prevState.errors, moveIn: null}
                    }
                ))
            }

            cycleIndex = (change = 0) => {
                if (this.state.abpIndex + change <= -1) return
                if (this.state.abpIndex + change >= this.state.ABPAddresses.length) return
                this.setState({abpIndex: this.state.abpIndex + change})
            }

            customFilterOption = (option, inputValue) => {
                if (!inputValue) {
                    return true;
                }

                return option.value.startsWith(inputValue);
            };

            componentDidUpdate(prevProps, prevState) {
                if (this.state.customer !== prevState.customer) {
                    const updatedCustomContacts = {};
                    let updatedIndexToDisplay = null;
                
                    Object.keys(this.state.addUserDisplay.customContacts).forEach(groupNumber => {
                        const firstName = this.state.customer[`CH_Director_First_Name_${groupNumber}`];
                        const lastName = this.state.customer[`CH_Director_Second_Name_${groupNumber}`];
                
                        if (firstName || lastName) {
                            updatedCustomContacts[groupNumber] = true;
                        } else {
                            updatedCustomContacts[groupNumber] = false;
                        }
                
                        if (firstName || lastName) {
                            updatedIndexToDisplay = null;
                        }
                    });
                
                    this.setState(prevState => ({
                        customer: this.state.customer,
                        addUserDisplay: {
                            customContacts: {
                                ...prevState.addUserDisplay.customContacts,
                                ...updatedCustomContacts
                            },
                            indexToDisplay: updatedIndexToDisplay
                        }
                    }));
                }
            }

            render() {
                const customerDataFields = [
                    { title: 'vacant-cot-alerts.unit_number', property: 'CL_Primary_Addressable_Object' },
                    { title: 'vacant-cot-alerts.house_number', property: 'CL_Secondary_Addressable_Object' },
                    { title: 'vacant-cot-alerts.building', property: 'CL_Address_Line_1' },
                    { title: 'vacant-cot-alerts.estate', property: 'CL_Address_Line_3' },
                    { title: 'vacant-cot-alerts.street_name', property: 'CL_Address_Line_2' },
                    { title: 'vacant-cot-alerts.locality', property: 'CL_Address_Line_4' },
                    { title: 'vacant-cot-alerts.post_town', property: 'CL_Address_Line_5' },
                    { title: 'vacant-cot-alerts.postcode', property: 'CL_Postcode' },
                ];
                const abpDataFields = [
                    { title: 'vacant-cot-alerts.unit_number', property: this.state.ABPAddresses?.length > 0 ? 'SUB_BUILDING' : 'ABP_Sub_Building' },
                    { title: 'vacant-cot-alerts.house_number', property: this.state.ABPAddresses?.length > 0 ? 'BUILDING_NUMBER' : 'ABP_Building_Number' },
                    { title: 'vacant-cot-alerts.building', property: this.state.ABPAddresses?.length > 0 ? 'BUILDING_NAME' : 'ABP_Building_Name' },
                    { title: 'vacant-cot-alerts.estate', property: '' }, 
                    { title: 'vacant-cot-alerts.street_name', property: this.state.ABPAddresses?.length > 0 ? 'STREET_NAME' : 'ABP_Street_Name' },
                    { title: 'vacant-cot-alerts.locality', property: this.state.ABPAddresses?.length > 0 ? 'LOCALITY' : 'ABP_Locality' },
                    { title: 'vacant-cot-alerts.post_town', property: this.state.ABPAddresses?.length > 0 ? 'TOWN_NAME' : 'ABP_Town_Name' },
                    { title: 'vacant-cot-alerts.postcode', property: this.state.ABPAddresses?.length > 0 ? 'POSTCODE' : 'ABP_Postcode' },
                ]

                const sic_codes = [
                    '0100', '0200', '0300', '1113', '1114', '111', '1300', '2100', '2396', '2310',
                    '2330', '4126', '4123', '4122', '4150', '4147', '4283', '4116', '4115', '4130',
                    '4213', '4160', '4239', '4196', '4200', '4214', '4221', '4222', '4240', '4261',
                    '4270', '4290', '4321', '4322', '4370', '4364', '4399', '4555', '4556', '4557',
                    '4384', '4385', '4396', '4532', '4534', '4531', '4535', '4536', '4537', '4560',
                    '4363', '4410', '4420', '4510', '4610', '4620', '4630', '4640', '4650', '4710',
                    '4724', '4725', '4722', '4723', '4721', '4728', '4751', '4754', '3452', '1200',
                    '1401', '1402', '2567', '2516', '2511', '2512', '2513', '2514', '2515', '2568',
                    '2551', '2552', '2581', '2599', '2565', '2562', '2564', '2570', '4811', '4812',
                    '4832', '4835', '4833', '4836', '2471', '2478', '2479', '2481', '2489', '2410',
                    '2420', '2437', '2426', '2450', '2460', '2210', '2220', '2235', '2234', '2247',
                    '2245', '2246', '1520', '3111', '3112', '3204', '3142', '3284', '3163', '3205',
                    '3290', '3120', '3138', '3162', '3169', '3161', '3164', '3137', '3302', '3441',
                    '3454', '3443', '3442', '3710', '3740', '3732', '3420', '3432', '3410', '3470',
                    '3460', '3165', '3281', '3283', '3287', '3261', '3245', '3255', '3285', '3212',
                    '3211', '3221', '3275', '3251', '3254', '3244', '3230', '3510', '3521', '3522',
                    '3523', '3434', '3530', '3610', '3620', '3640', '3633', '3634', '3650', '4671',
                    '4910', '4942', '4941', '3169', '3221', '1610', '1620', '1630', '1700', '9212',
                    '9211', '6210', '8500', '5010', '5020', '5000', '5030', '5040', '6510', '6710',
                    '6148', '6300', '6110', '6170', '6160', '6150', '6180', '6190', '6420', '6560',
                    '6410', '6450', '6460', '6430', '6530', '6480', '7700', '7901', '6650', '6670',
                    '6611', '6612', '6640', '6620', '6630', '4753', '4751', '4752', '4754', '8394',
                    '9711', '9741', '7902', '8140', '8150', '8200', '8310', '8320', '8340', '8350',
                    '8360', '8396', '8370', '9400', '8380', '9760', '9890', '4930', '9560', '8480',
                    '8460', '8410', '8420', '8430', '8490', '7700', '9230', '9111', '9150', '9120',
                    '9130', '9140', '9190', '9320', '9330', '9310', '9791', '9360', '9510', '9530',
                    '9540', '9550', '9611', '9611', '9631', '9660', '9690', '6730', '9812', '9820',
                    '9900', '0000'
                ];
                
                
                return (
                    <div className="grid grid-gap-15" id="vaca-confirmation-page">
                        <Navigation/>
                        <Container>
                            <PageTitle title="Vacant COT Alerts - Confirmation" iconClass={ProductIcons.BusinessIcon.props.className} />
                        </Container>
                        <Container>
                            <div className="vacantCOTAlerts">
                                <div className="primary-grid" style={{gridTemplateColumns: '1fr'}}>
                                    <h4 style={{backgroundColor: 'light-grey'}}>Are you sure you want to submit the following details?</h4>
                                    <div className="left-grid" style={{gridTemplateColumns: '1fr 1fr 1fr', zIndex: '1'}}>
                                        <div className="data-row datepicker">
                                            <div className="data-row-title" style={this.state.errors.moveIn ? {color: 'red'} : {}}>
                                                { this.state.translations ? this.state.translations['move_in_date'] : '' }
                                            </div>
                                            <DatePicker customInput={<CustomInput value={this.state.moveInDate } />} dateFormat="ddMMMyyyy" showYearDropdown yearDropdownItemNumber={100} scrollableYearDropdown maxDate={new Date()} showIcon={true} selected={this.state.moveInDate} onChange={this.updateMoveInDate}></DatePicker>
                                        </div>
                                        <div className="data-row">
                                            <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['spid'] : '' }
                                            </div>
                                            <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? this.state.customer.Full_SPID : '' }/>
                                        </div>
                                        <div className="data-row">
                                            <div className="data-row-title" style={this.state.errors.SIC_Code ? {color: 'red'} : {}}>
                                                { this.state.translations ? this.state.translations['sic_code'] : '' }
                                            </div>
                                            {
                                                this.state.customer && !this.state.customer.SIC_Code ? 
                                                    <Select
                                                        options={sic_codes.map((option) => {
                                                            return (
                                                                {label: option, value: option}
                                                            )
                                                        })}
                                                        onChange={(selectedOption) => this.updateSICCode(selectedOption.value)}
                                                        value={this.state.SIC_Code}
                                                        isSearchable
                                                        placeholder={'Please select a SIC Code'}
                                                        filterOption={this.customFilterOption}
                                                        styles={{
                                                            control: base => ({
                                                                ...base,
                                                                height: 34,
                                                                minHeight: 34,
                                                                paddingTop: 0
                                                            }),
                                                            ValueContainer: base => ({
                                                                ...base,
                                                                paddingTop: 0
                                                            })
                                                          }}
                                                    />
                                                :
                                                    <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? this.state.customer.SIC_Code : '' }/>
                                            }
                                        </div>
                                        <div className="data-row" style={{zIndex: '-1'}}>
                                            <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['company_name'] : '' }
                                            </div>
                                            <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? this.state.customer.Company_Name : '' }/>
                                        </div>
                                        <div className="data-row">
                                            <div className="data-row-title">
                                                { this.state.translations ? this.state.translations['company_number'] : '' }
                                            </div>
                                            <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? this.state.customer.Company_Number : '' }/>
                                        </div>
                                        {
                                            !this.state.customer || !this.state.customer.Company_Number ? 
                                                <div className="data-row" style={{zIndex: '-1'}}>
                                                    <div className="data-row-title">
                                                        { this.state.translations ? this.state.translations['telephone'] : '' }
                                                    </div>
                                                    <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? this.state.customer.Landline ? this.state.customer.Landline : this.state.customer.Mobile ? this.state.customer.Mobile : '' : '' }/>
                                                </div>
                                            :
                                                ''
                                        }
                                    </div>
                                    <div className="left-grid" style={{gridTemplateColumns: '1fr 1fr'}}>
                                        <div className="data-row" style={{gridTemplateColumns: 'unset'}}>
                                            <div className="data-row-title" style={{width: 'auto', borderRadius: '5px'}}>
                                                { this.state.translations ? this.state.translations['supply_address'] : '' }
                                            </div>
                                        </div>
                                        <div className="data-row" style={{ gridTemplateColumns: 'unset' }}>
                                            <div className="data-row-title" style={{ width: 'auto', borderRadius: '5px', gap: '10px' }}>
                                                {this.state.translations ? this.state.translations['billing_address'] : ''}
                                                <div style={{ display: 'flex', position: 'absolute', right: '10%', gap: '10px' }}>
                                                    <div style={{ visibility: this.state.customPostcode !== this.state.customer?.ABP_Postcode && this.state.ABPAddresses ? 'visible' : 'hidden', flexDirection: 'row', alignItems: 'center' }}>
                                                        <span style={{ cursor: 'pointer', marginLeft: '5px' }} className='fas fa-trash-undo' onClick={() => { this.setState({ customPostcode: null, ABPAddresses: null }) }} title="Restore original postcode search"></span>
                                                    </div>
                                                    <div style={{ visibility: 'visible', flexDirection: 'row', alignItems: 'center' }}>
                                                        <span style={{ cursor: 'pointer' }} className='fas fa-search' onClick={() => { this.setState({ postcodeModal: true }) }} title="Search for a different postcode"></span>
                                                    </div>
                                                    <div style={{ visibility: this.state.ABPAddresses?.length ? 'visible' : 'hidden', flexDirection: 'row', alignItems: 'center' }}>
                                                        <span style={{ cursor: 'pointer' }} className='fas fa-angle-left' onClick={() => this.cycleIndex(-1)}></span>
                                                        <span style={{ minWidth: 60, textAlign: 'center' }}>{this.state.abpIndex + 1}/{this.state.ABPAddresses?.length}</span>
                                                        <span style={{ cursor: 'pointer' }} className='fas fa-angle-right' onClick={() => this.cycleIndex(1)}></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='left-grid'>
                                            {
                                                customerDataFields.map(field => {
                                                    return (
                                                        <div className="data-row" style={{zIndex: '-1'}}>
                                                        <div className="data-row-title">
                                                            { this.state.translations ? this.state.translations[field.title] : '' }
                                                        </div>
                                                        <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? this.state.customer[field.property] : '' }/>
                                                    </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className='left-grid'>
                                            {
                                                abpDataFields.map(field => {
                                                    return (
                                                        <div className="data-row" style={{zIndex: '-1'}}>
                                                        <div className="data-row-title">
                                                            { this.state.translations ? this.state.translations[field.title] : '' }
                                                        </div>
                                                        <input type="text" className="data-row-field" disabled={true} value={
                                                            this.state.ABPAddresses?.length ? 
                                                                this.state.ABPAddresses[this.state.abpIndex][field.property]
                                                            :
                                                                this.state.customer ? this.state.customer[field.property] : '' 
                                                        }/>
                                                    </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    {
                                        this.state.customer ?
                                                <div className='left-grid' style={{gridTemplateColumns: '1fr 1fr 1fr', borderTop: '4px dashed #e8e8e8', paddingTop: '15px'}}>
                                                    {[
                                                        ...[1, 2, 3].filter(number => !Object.keys(this.state.addUserDisplay.customContacts).includes(String(number))),
                                                        ...Object.keys(this.state.addUserDisplay.customContacts).sort((a, b) => a - b)
                                                    ].map(groupNumber => {
                                                    const firstName = this.state.customer[`CH_Director_First_Name_${groupNumber}`];
                                                    const lastName = this.state.customer[`CH_Director_Second_Name_${groupNumber}`];

                                                    if (!Object.keys(this.state.addUserDisplay.customContacts).includes(groupNumber) || this.state.addUserDisplay.indexToDisplay === groupNumber || this.state.addUserDisplay.customContacts[groupNumber] === true ) {
                                                        return (
                                                            <div style={{display: 'flex', gap: '5px'}}>
                                                                <div style={{width: '100%'}} className='left-grid' id={groupNumber}>
                                                                    <div className="data-row">
                                                                        <div className="data-row-title">
                                                                            { this.state.translations ? this.state.translations['first_name'] : '' }
                                                                        </div>
                                                                        <input type="text" className="data-row-field" disabled={!Object.keys(this.state.addUserDisplay.customContacts).includes(groupNumber)} value={ firstName || '' } onChange={(event) => this.setState({customer: {...this.state.customer, [`CH_Director_First_Name_${groupNumber}`]: event.target.value} })} />
                                                                    </div>
                                                                    <div className="data-row">
                                                                        <div className="data-row-title">
                                                                            { this.state.translations ? this.state.translations['last_name'] : '' }
                                                                        </div>
                                                                        <input type="text" className="data-row-field" disabled={!Object.keys(this.state.addUserDisplay.customContacts).includes(groupNumber)} value={ lastName || '' } onChange={(event) => this.setState({customer: {...this.state.customer, [`CH_Director_Second_Name_${groupNumber}`]: event.target.value} })} />
                                                                    </div>
                                                                    <div className="data-row">
                                                                        <div className="data-row-title">
                                                                            { this.state.translations ? this.state.translations['telephone'] : '' }
                                                                        </div>
                                                                        <input type="text" className="data-row-field" disabled={!Object.keys(this.state.addUserDisplay.customContacts).includes(groupNumber)} value={ this.state.customer ? this.state.customer.Landline ? this.state.customer.Landline : this.state.customer.Mobile ? this.state.customer.Mobile : '' : '' } />
                                                                    </div>
                                                                </div>
                                                                { Object.keys(this.state.addUserDisplay.customContacts).includes(groupNumber) ? 
                                                                    <i onClick={() => this.setState(prevState => ({ 
                                                                        customer: {
                                                                            ...prevState.customer, 
                                                                            [`CH_Director_First_Name_${groupNumber}`]: null, 
                                                                            [`CH_Director_Second_Name_${groupNumber}`]: null
                                                                        },
                                                                        addUserDisplay: {
                                                                            indexToDisplay: null,
                                                                            customContacts: {
                                                                                ...prevState.addUserDisplay.customContacts,
                                                                                [groupNumber]: false
                                                                            }
                                                                        }
                                                                    }))} class="fa-solid fa-minus"></i>
                                                                : null}
                                                            </div>
                                                        )
                                                    }

                                                })}

                                                {Object.keys(this.state.addUserDisplay.customContacts).length > 0 && !this.state.addUserDisplay.indexToDisplay && Object.values(this.state.addUserDisplay.customContacts).some(value => value === false) &&
                                                    <div className='add-user' onClick={() => this.setState(prevState => ({ addUserDisplay: {...prevState.addUserDisplay, indexToDisplay: Object.keys(this.state.addUserDisplay.customContacts).find(key => this.state.addUserDisplay.customContacts[key] === false) }}))}>
                                                        <div>
                                                            <i className="fa-solid fa-user-plus fa-xl"></i>
                                                        </div>
                                                        <p>Add Contact</p>
                                                    </div>    
                                                }


                                            </div>
                                        :
                                            ''
                                    }
                                </div>
                                <div style={{display: 'flex', justifyContent: 'flex-end', gap: '15px'}}>
                                    <button style={{fontWeight: "100"}} className="button compact cancel" onClick={() => { 
                                        this.handleGoBack();
                                    }}>
                                        Cancel
                                    </button>
                                    <button className="button background-primary colour-white" onClick={() => this.submit()}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </Container>
                        <Footer />
                        <ReactModal 
                            isOpen={this.state.postcodeModal} 
                            onRequestClose={() => this.setState({postcodeModal: false})}
                            className="card bulk-allocate"
                            contentLabel="Bulk Allocate COT Alert Data"
                            style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)', zIndex: '99999' } }}>
                                <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
                                <h3>Postcode Search</h3>
                                <input autoFocus value={this.state.customPostcode} onChange={_ => this.setState({customPostcode: _.target.value})} style={{border: '1px solid gray', width: '100%', borderRadius: 3}} />
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                                    <button className='button compact smaller-text background-primary' onClick={() => {this.getABPPostcodeData(); this.setState({postcodeModal: false})}}>Search</button> 
                                </div>
                                </div>
                        </ReactModal>
                    </div>
                )
            }
        }
    )
)


function CustomInput(props) {
    return (
        <input style={{width: '100%'}} value={props.value.toUpperCase()} onClick={props.onClick} onChange={null} />
    )
  }