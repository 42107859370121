import { view } from '@risingstack/react-easy-state'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import PropertyMap from '../../../components/property-map/property-map'
import GenericPageContainer from '../../../GenericPageElements/GenericPageContainer'
import request from '../../../helpers/request'
import hasPermission from '../../../helpers/permissions';
import endpoints from '../../../helpers/endpoints'
import {GoogleEvidence, AddressEvidence, YellComEvidence, CompaniesHouseEvidence, RightColumn, LeftColumn, FoodStandardsEvidence, BTDirectoryEvidence, CharityCommisionEvidence, DVSAEvidence, CQCEvidence, JustEatEvidence, One92Evidence, FacebookEvidence, TitleOwnerRow, NotesRow} from '../VacantCOTAlerts/sharedComponents'
import HelpVideo from '../../../components/help-video/helpVideo'
import session from '../../../stores/session'
import ReactModal from 'react-modal'

export default view(function LongTermVacantSearch() {
    const [accountNumber, setAccountNumber] = useState();
    const [customer, setCustomer] = useState({});
    const [notes, setNotes] = useState([]);
    const [showMap, setShowMap] = useState(true);
    const [purchasable, setPurchasable] = useState();
    const [shouldRefreshMap, setShouldRefreshMap] = useState(false);
    const [workflow, setWorkflow] = useState([])
    const [workflowIndex, setWorkflowIndex] = useState(-1)
    const [loadingWorkflow, setLoadingWorkflow] = useState(false)
    const [workflowMode, setWorkflowMode] = useState('Workflow')
    const [selectedWholesaler, setSelectedWholesaler] = useState()
    const [wholesalers, setWholesalers] = useState([])
    const [translations, setTranslations] = useState();
    const [exclusionReason, setExclusionReason] = useState();
    const [workflowModalIsOpen, setWorkflowModalIsOpen] = useState(false);
    const [exclusionModalIsOpen, setExclusionModalIsOpen] = useState(false);
    const [assignedTo, setAssignedTo] = useState()
    const history = useHistory()

    useEffect(() => {
        request(true).get('/translate?key=long_term_vacant').then(r => {
            setTranslations(r.data)
        }).catch(e => {
            console.error(e)
        })

        var index = history.location?.state?.index;
        var wholesaler = history.location?.state?.wholesaler;
               
        request(true).get(`${endpoints.LONG_TERM_VACANT_DASHBOARD_WHOLESALER}?user=${session.user.id}`).then(r => {
            setWholesalers(r.data)
            setSelectedWholesaler(wholesaler ?? r.data[0]?.value)
            getWorkflow(index)
        })
    }, [])

    const getWorkflow = (index) => {
        setLoadingWorkflow(true)
        request(true).get(endpoints.LONG_TERM_VACANT_WORKFLOW, {
            params: {
                selectedWholesaler: selectedWholesaler,
                workflowMode: workflowMode
            }
        }).then(r => {
            if (index > r.data.length - 1) {
                index --;
            }
            setWorkflow(r.data)
            setAccountNumber(r.data[index ?? 0])
            setWorkflowIndex(index ?? 0)
            setWorkflowModalIsOpen(false)
            setLoadingWorkflow(false)
        }).catch(e => {
            console.error(e);
            window.alert('Could not retrieve data')
            setLoadingWorkflow(false)
        });
    }

    useEffect(() => {
        if (workflow) {
            getData()
        }
    }, [workflow])

    const getData = () => {
        if (!accountNumber) return;
        setCustomer({})
        setPurchasable()
        request(true).get(`${endpoints.LONG_TERM_VACANT_GET_CUSTOMER}?search=${accountNumber}`).then(r => {
            if (!r.data.customer) window.alert('Could not find SPID.');
            setCustomer(r.data.customer)
            setNotes(r.data.notes)
            setShowMap(true)
            setPurchasable(r.data.customer && r.data.customer.Date_Bought === null)
            setShouldRefreshMap(true)
            setAssignedTo(r.data.customer && r.data.customer.assigned_to)
            setExclusionReason(r.data.customer && r.data.customer.Occupier_Exclusion_Reason)
        }).catch(e => {
            console.log(e)
        })
    }

    const purchaseCustomer = (occupierPurchaseType) => {
        request(true).post(endpoints.LONG_TERM_VACANT_BUY_CUSTOMER, {
            "customer": customer.Core_SPID,
            "occupier_purchase_type": occupierPurchaseType
        }).then(r => {
            setCustomer(r.data.customer)
            setShowMap(true)
            setPurchasable(r.data.customer.Date_Bought === null)
            setWorkflowModalIsOpen()
        }).catch(e => {
            console.log(e)
        })
    }

    const openWorkflowBuyModal = () => {
        setWorkflowModalIsOpen(true)
    }

    const openExclusionModal = () => {
        setExclusionModalIsOpen(true)
    }

    const excludeCustomer = () => {
        if (!customer || !customer.Core_SPID) return;
        request(true).post(endpoints.LONG_TERM_VACANT_EXCLUDE_CUSTOMER, {
            customer: customer.Core_SPID,
            Occupier_Exclusion_Reason: exclusionReason
        }).catch(e => {
            console.error(e)
        })
        setExclusionModalIsOpen(false)
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            getData();
        }
    }

    const workflowNext = () => {
        if (workflowIndex + 1 > workflow.length) return
        setWorkflowIndex(workflowIndex + 1)
        setAccountNumber(workflow[workflowIndex + 1])
    }

    const workflowPrev = () => {
        if (workflowIndex > workflow.length) return
        setWorkflowIndex(workflowIndex - 1)
        setAccountNumber(workflow[workflowIndex - 1])
    }

    useEffect(() => {
        if (accountNumber && accountNumber !== customer.Core_SPID) {
            getData();
        }
    }, [accountNumber])

    const updateStatus = (event) => {
        setCustomer({...customer, status: event.target.value})
        request(true).post(endpoints.LONG_TERM_VACANT_UPDATE_STATUS, {
            customer: customer.Core_SPID,
            status: event.target.value
        })
    }

    useEffect(() => {
        if (exclusionReason) {
            setExclusionModalIsOpen(true)
        }
    }, [exclusionReason])

    return (
        <GenericPageContainer
            title="Long Term Vacant - Workflow"
            titleIconClass={"fak fa-id-business-icon fa-2x colour-primary"}
            titleRight={
            <div>
                <Link className="navigate" to="/portal/long-term-vacant">
                    To Summary
                </Link>
                { (hasPermission('LONG_TERM_VACANT', 'WORKFLOW')) ?
                <Link className="navigate" to="/portal/long-term-vacant/search">
                    To Search
                </Link>
                : null }
                { (hasPermission('LONG_TERM_VACANT', 'DASHBOARD')) ?
                    <Link className="navigate" to="/portal/long-term-vacant/dashboard">
                        To Dashboard
                    </Link>
                : null }
            </div>
            }
        >
            <div className="LTV">
                <div className="primary-grid">
                    <div className="left-grid">
                        <LeftColumn state={{ translations, customer }}>
                            <div className="data-row with-buttons">
                                <div className="data-row-title">
                                    { translations ? translations['spid'] : '' }
                                </div>
                                {/* <input type="text" className="data-row-field" name="account-number" value={ accountNumber } onChange={ (event) => accountNumberChange(event) } onKeyDown={ (event) => handleKeyDown(event) }/>
                                <button className="search-button" onClick={ () => getData() }>
                                    Search
                                </button> */}

                                <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                                    <input disabled={true} type="text" className="data-row-field" name="account-number" value={ accountNumber } style={{ width: 'calc(100% - 15px)', height: '100%' }}/>
                                    { loadingWorkflow && <i className="fa fa-spinner fa-spin" style={{ position: 'absolute', marginLeft: '15px' }}/> }
                                    <span className="message">{ workflowIndex + 1 }/{ workflow.length }</span>
                                </div>
                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '15px', marginLeft: '15px' }}>
                                    <button className="button background-primary colour-white" onClick={ () => workflowPrev() } disabled={workflowIndex === 0}>
                                        <i className="fas fa-angle-left"></i>
                                    </button>
                                    <button className="button background-primary colour-white" onClick={ () => workflowNext() } disabled={workflowIndex === workflow.length - 1}>
                                        <i className="fas fa-angle-right"></i>
                                    </button>
                                </div>
                            </div>
                        </LeftColumn>
                    </div>
                    <RightColumn state={{ translations, customer }}>
                        { (!hasPermission('LONG_TERM_VACANT', 'PURCHASE') && purchasable) ?
                            <button className="buy-button disabled" disabled={true}>
                                Occupier Moved In
                            </button>
                        :
                            <button className="buy-button" disabled={ !purchasable} onClick={ () => openWorkflowBuyModal() }>
                                { purchasable === true || purchasable === null ? "Occupier Moved In" :
                                    customer.Bought_By ?
                                        `Purchased on ${customer.Date_Bought} by ${customer.Bought_By}`
                                    :
                                        customer.Date_Bought ?
                                            `Purchased on ${customer.Date_Bought}`
                                        :
                                            'Unavailable for Purchase'
                                }
                            </button>
                        }
                        <select style={{height: 28, marginTop: 7, border: '1px solid var(--primary)', borderRadius: 5}} onChange={_ => { setExclusionReason(_.target.value); }} value={exclusionReason}>
                            <option value={''}>Select an exclusion reason</option>
                            <option value={'Existing Live Account'}>Existing Live Account</option>
                            <option value={'Multiple Occupier/LL Query'}>Multiple Occupier/LL Query</option>
                            <option value={'Address/Data Quality Issue'}>Address/Data Quality Issue</option>
                            <option value={'Previous Occupier Provided'}>Previous Occupier Provided</option>
                            <option value={'Adjacent Occupier Provided'}>Adjacent Occupier Provided</option>
                            <option value={'De-registration'}>De-registration</option>
                            <option value={'Other Retailer'}>Other Retailer</option>
                            <option value={'Other'}>Other</option>
                        </select>
                        <div className="data-row">
                            <div className="data-row-title">
                                Status
                            </div>
                            <select style={{height: 28, border: '1px solid var(--primary)', borderRadius: 5}} onChange={(event) => updateStatus(event)} value={customer?.status ?? "New"}>
                                <option value="New">New</option>
                                <option value="In Progress">In Progress</option>
                                <option value="Previous Occupier">Previous Occupier</option>
                                <option value="Unable to Validate Occupier">Unable to Validate Occupier</option>
                                <option value="Occupier Moved In">Occupier Moved In</option>
                                <option value="Raise with Retailer">Raise with Retailer</option>
                            </select>
                        </div>
                    </RightColumn>
                    <TitleOwnerRow state={{ translations, customer }}  />
                    {/* <NotesRow state={{ translations, notes, customer }} notesEndpoint={endpoints.LONG_TERM_VACANT_SUBMIT_NOTES} outcomeEndpoint={endpoints.LONG_TERM_VACANT_UPDATE_ASSESSMENT} issueToEndpoint={endpoints.LONG_TERM_VACANT_ISSUE_TO_USER} setNotes={(data) => setNotes(data)} showNotes={false} extraWorkflowStatuses={[{value: "Raise with Retailer", label: "Raise with Retailer"}]} /> */}
                </div>
                <div className="secondary-grid">
                    {/* <PropertyMap address={ this.state.customer ? this.state.customer.SPID_Address : null }/> */}
                    <div style={{display: 'flex', flexDirection: 'row', gap: '.5em', height: '100%'}}>
                        <GoogleEvidence data={customer} />
                        <CompaniesHouseEvidence data={customer} />
                        <YellComEvidence data={customer} />
                        <AddressEvidence data={customer} />
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', gap: '.5em', height: '100%'}}>
                        <FoodStandardsEvidence data={customer} />
                        <BTDirectoryEvidence data={customer} />
                        <CharityCommisionEvidence data={customer} />
                        <DVSAEvidence data={customer} />
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', gap: '.5em', height: '100%'}}>
                        <CQCEvidence data={customer} />
                        <JustEatEvidence data={customer} />
                        <One92Evidence data={customer} />
                        <FacebookEvidence data={customer} />
                    </div>
                </div>

                <div className="secondary-grid" style={{gridTemplateColumns: '1fr 3fr', gridGap: '15px', gap: '15px' }}>
                    <table className="table borders squish super-small-text extra-row-padding">
                        <thead>
                            <tr>
                                <th style={{textAlign: 'left'}}>
                                    Days
                                </th>
                                <th>
                                    Opening Times
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map(day => {
                                    return (
                                        <tr>
                                            <td style={{textAlign: "left", width: "100px"}}>{ day }</td>
                                            <td style={{minWidth: "unset"}}>{ customer?.Google_Opening_Hours ? JSON.parse(customer.Google_Opening_Hours.replaceAll('\'', '"'))[day] : null }</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                    <div style={{height: 'auto'}}>
                    { showMap ?
                        <PropertyMap address={ customer ? customer.SPID_Address : null } />
                    :
                        <>
                            <div className="map hidden" onClick={ () => { if (customer) { setShowMap(true) }} }>
                                { customer ? 'Click to show map' : '' }
                            </div>
                        </>
                    }
                    </div>
                </div>
            </div>
            <HelpVideo video={session.company.vacant_search_video_url}/>
            <ReactModal 
            className="card bulk-buy"
            isOpen={exclusionModalIsOpen}>
                <p>You have selected the following exclusion reason</p>
                <p>{exclusionReason}</p>
                <div className='grid grid-columns-2 grid-gap-5'>
                    <button className='button compact background-red colour-white' onClick={() => setExclusionModalIsOpen(false)}>Cancel</button>
                    <button className='button compact background-primary colour-white' onClick={() => excludeCustomer()}>Confirm</button>
                </div>
            </ReactModal>
            <ReactModal
            className="card bulk-buy"
            isOpen={workflowModalIsOpen}>
                <p>You have selected to move in an occupier </p>
                <p>Select either Cancel, IDenteq for identified name or Other for an other name </p>
                <div className='grid grid-columns-3 grid-gap-5'>
                    <button className='button compact background-red colour-white' onClick={() => setWorkflowModalIsOpen(false)}>Cancel</button>
                    <button className='button compact background-primary colour-white' onClick={() => purchaseCustomer('IDenteq')}>IDenteq</button>
                    <button className='button compact background-primary colour-white' onClick={() => purchaseCustomer('Other')}>Other</button>
                </div>
            </ReactModal>
        </GenericPageContainer>
    )
})