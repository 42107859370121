import React, { Component, useEffect, useMemo, useState } from 'react'
import Navigation from '../../../components/navigation/navigation'
import { ReactComponent as HouseIcon } from '../../../assets/House Icon.svg'
import KpiCard from '../../../components/kpi-card/kpiCard'
import Container from '../../../components/container/container'
import {
    BarChart,
    XAxis,
    Bar,
    YAxis,
    // Tooltip,
    Legend,
    ResponsiveContainer,
    CartesianGrid,
    Tooltip,
} from 'recharts'
import LargeLinkButton from '../../../components/large-link-button/largeLinkButton'
import Footer from '../../../components/footer/footer'
import request from '../../../helpers/request'
import hasPermission from '../../../helpers/permissions';
import endpoints from '../../../helpers/endpoints'
import { view } from '@risingstack/react-easy-state'
import S3Modal from '../../../components/s3Modal/S3Modal'
import session from '../../../stores/session';
import useWSProduct from '../../../helpers/useWSProduct'
import ProductIcons from '../../../helpers/productIcons'
import PageTitle from '../../../components/page-header/pageTitle'
import ProductChartInfo from '../../../components/product-chart-info/product-info-chart'

export default view(function BusinessTeleAppend() {
    const [stats, setStats] = useState({})
    const [translations, setTranslations] = useState({})
    const [last12files, setLast12files] = useState([])
    const [last12months, setLast12months] = useState([])
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [modalMode, setModalMode] = useState(hasPermission('BTA', 'DOWNLOAD') ? 'download' : null)
    const [graphData, setGraphData] = useState([])

    useEffect(() => {
        request(true).get('/translate?key=business_tele_append').then(r => {
            setTranslations(r.data)
        }).catch(e => {
            console.error(e);
        })
        request(true).get(endpoints.BUSINESS_TELE_APPEND_GRAPH).then(e => {
            setGraphData(e.data)
        })
        request(true).get(endpoints.BUSINESS_TELE_APPEND_TABLE).then(e => {
            setLast12months(e.data)
        })
        request(true).get(endpoints.BUSINESS_TELE_APPEND_DATA).then(e => {
            setStats(e.data)
        })
    }, []);

    const graphDataParsed = useMemo(() => {
        const toReturn = new Array(graphData.length < 12 ? 12 : graphData.length).fill({});

        graphData.forEach((e, i) => {
            const data = {};
            Object.keys(e).map(key => {
                data[key] = e[key].replace('%', '');
            })
            toReturn[i] = data
        })

        return toReturn
    }, [graphData])

    return (
        <div className="grid grid-gap-15" id="contact-validation-page">
            <Navigation />
            <Container>
                <PageTitle title="Contact Validation" iconClass={ProductIcons.BusinessIcon.props.className} titleRight={
                    <div style={{display: 'flex'}}>
                        { hasPermission('BTA', 'DOWNLOAD') ?
                        <button className="navigate" onClick={() => {setModalMode('download');setModalIsOpen(true)}}>Download Return File</button>
                            : null }
                        { hasPermission('BTA', 'UPLOAD') ?
                            <button className="navigate" onClick={() => {setModalMode('upload');setModalIsOpen(true)}}>Issue Input File</button>
                        : null }
                    </div>
                } />
            </Container>
            <Container>
                <div className="grid grid-columns-5 grid-gap-15">
                    <KpiCard
                        primary={ stats.File_Count ? parseInt(stats.File_Count).toLocaleString() : '...'}
                        title={`Checked in ${stats.Month ? stats.Month : '...'}`}
                    />
                    <KpiCard
                        primary={ stats.Dual_Append_Count ? parseInt(stats.Dual_Append_Count).toLocaleString() : '...'}
                        title={ translations ? translations['Dual'] : '' }
                        secondary={stats.Dual_Append_Per || '...'}
                    />
                    <KpiCard
                        primary={ stats.Single_Append_Count ? parseInt(stats.Single_Append_Count).toLocaleString() : '...'}
                        title={ translations ? translations['Single'] : '' }
                        secondary={stats.Single_Append_Per || '...'}
                    />
                    <KpiCard
                        primary={ stats.No_Match_Count ? parseInt(stats.No_Match_Count).toLocaleString() : '...'}
                        title={ translations ? translations['NoMatch'] : '' }
                        secondary={stats.No_Match_Per || '...'}
                    />
                    <KpiCard
                        primary={ stats.Permanently_Closed_Count ? parseInt(stats.Permanently_Closed_Count).toLocaleString() : '...'}
                        title={ translations ? translations['Permanently'] : '' }
                        secondary={stats.Permanently_Closed_Per || '...'}
                    />
                </div>
                <hr
                    style={{ width: '100%', opacity: '25%', margin: '25px 0 25px 0' }}
                />
                <div className="grid grid-columns-2 grid-gap-20">
                    <div className="grid grid-gap-20">
                        <div className="grid">
                            <p className="chart-title">Last 12 files performance</p>
                            {/* Chart */}
                            <div className="chart-wrapper">
                                <ResponsiveContainer minHeight="400px" maxHeight="500px">
                                    <BarChart
                                        data={graphDataParsed}
                                        barSize={20}
                                        backgroundColor="#fff"
                                        margin={{ top: 20, bottom: 20, right: 5, left: 5}}
                                    >
                                        <XAxis
                                            dataKey="Title"
                                            type="category"
                                            minTickGap={1}
                                            interval={0}
                                            tick={{fontSize: 14, color: 'rgb(204, 204, 204)'}}
                                            height={60}
                                            tickMargin={20}
                                            tickLine={false}
                                            stroke="rgb(204, 204, 204)"
                                        />
                                        <YAxis
                                            axisLine={false}
                                            unit="%"
                                            ticks={[0,20,40,60,80,100]}
                                            tickMargin={10}
                                            tickLine={false}
                                            tick={{ color: 'rgb(204, 204, 204)' }}
                                            stroke="rgb(204, 204, 204)"
                                        />

                                        <CartesianGrid vertical={false}/>

                                        {/* <Tooltip /> */}

                                        <Legend align="left" iconType="circle" iconSize={11} />

                                        <Bar
                                            dataKey="Dual Append"
                                            stackId="primary"
                                            fill="#445469"
                                        />
                                        <Bar
                                            dataKey="Single Append"
                                            stackId="primary"
                                            fill="var(--primary)"
                                        />
                                        <Bar dataKey="No Match" stackId="primary" fill="#0d73b2" />
                                        <Bar
                                            dataKey="Permanently Closed"
                                            stackId="primary"
                                            fill="#f19a14"
                                        />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p style={{display: 'flex', justifyContent: 'space-between'}} className="chart-title">Last 12 months performance <ProductChartInfo path={window.location.pathname} style={{margin: 0, cursor: 'pointer', color: 'var(--primary)', paddingLeft: '15px'}} /></p>
                        {/* Table */}
                        <table className="table borders">
                            <thead>
                                <tr>
                                    <th style={{minWidth: 75}}>File Date</th>
                                    <th>{ translations ? translations['NumberChecked'] : '' }</th>
                                    <th>{ translations ? translations['Dual'] : '' }</th>
                                    <th>{ translations ? translations['Single'] : '' }</th>
                                    <th>{ translations ? translations['NoMatch'] : '' }</th>
                                    <th>{ translations ? translations['Permanently'] : '' }</th>
                                </tr>
                            </thead>
                            <tbody>
                                {last12months.map(function (data) {
                                    return (
                                        <tr key={data.Month}>
                                            <td>{data.Month}</td>
                                            <td>{parseInt(data['File Count']).toLocaleString()}</td>
                                            <td>{parseInt(data['Dual Append']).toLocaleString()}</td>
                                            <td>{parseInt(data['Single Append']).toLocaleString()}</td>
                                            <td>{parseInt(data['No Match']).toLocaleString()}</td>
                                            <td>{parseInt(data['Permanently Closed']).toLocaleString()}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </Container>
            <S3Modal showModal={modalIsOpen} closeModal={() => setModalIsOpen(false)} modalMode={modalMode} listingUrl={endpoints.BUSINESS_TELE_FILE_DOWNLOAD} uploadUrl={endpoints.BUSINESS_TELE_FILE_UPLOAD}/>
            <Footer />
        </div>
    )
})
