import { Checkbox } from '@material-ui/core';
import { GoogleMap, Marker, InfoWindow, useLoadScript } from '@react-google-maps/api';
import OSPoint from 'ospoint';
import React, { useEffect, useState } from 'react'

export default function PropertyMap({eastingNorthing, address, uprnEastingNorthing, meterEastingNorthing, displayExtraPins, showAllMeters, mdsMeterData}) {
    const [reload, setReload] = useState(false);
    const [mapCenter, setMapCenter] = useState('cmos');
    const [currentCmos, setCurrentCmos] = useState('');
    const [centerCmos, setCenterCmos] = useState({});
    const [currentUprn, setCurrentUprn] = useState('');
    const [centerUprn, setCenterUprn] = useState({});
    const [currentMeter, setCurrentMeter] = useState('');
    const [centerMeter, setCenterMeter] = useState({})
    const [meters, setMeters] = useState([]);
    const [showCMOSMarker, setShowCMOSMarker] = useState(true);
    const [showUPRNMarker, setShowUPRNMarker] = useState(true);
    const [showMeterMarker, setShowMeterMarker] = useState(true);
    const [infoWindowOpen, setInfoWindowOpen] = useState([])

    const {isLoaded, loadError} = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY
    })

    const updateMap = () => {
        setCurrentCmos('')
        setCenterCmos({})
        setCurrentUprn('')
        setCenterUprn({})
        setCurrentMeter('')
        setCenterMeter({})
        setShowCMOSMarker(true);
        setShowUPRNMarker(true);
        setShowMeterMarker(true);
        setMapCenter('cmos')
        setReload(true)
        const geocoder = new window.google.maps.Geocoder();

        if (eastingNorthing) {
            const easting = eastingNorthing[0]
            const northing = eastingNorthing[1]
            const point = new OSPoint(northing, easting);
            const wgs84Meter = point.toWGS84()
            geocoder.geocode({location: {lat: wgs84Meter.latitude, lng: wgs84Meter.longitude}}, (res, status) => {
                if (status === "OK") {
                    setCenterCmos(res[0].geometry.location);
                    setReload(false)
                }
            });
        } else if (address) {
          geocoder.geocode({address: address}, (res, status) => {
              if (status === "OK") {
                    setCenterCmos(res[0].geometry.location);
                    setReload(false)
              }
          });
        }
    }

    const formatMeters = () => {
        const formattedMeters = mdsMeterData.map(meter => {
            const easting = meter.GISX
            const northing = meter.GISY
            const point = new OSPoint(northing, easting);
            const wgs84Meter = point.toWGS84()
            meter.lat = wgs84Meter.latitude
            meter.lng = wgs84Meter.longitude
            return meter
        }) 
        setMeters(formattedMeters)
    }

    useEffect(() => {
        if (!isLoaded) return
        if (eastingNorthing) {
            if (eastingNorthing.every(function(value, index) {return value === currentCmos[index]})) return
            const easting = eastingNorthing[0]
            const northing = eastingNorthing[1]
            updateMap()
            setCurrentCmos([easting, northing])
        } else if (address && address !== currentCmos) {
            updateMap()
            setCurrentCmos(eastingNorthing ?? address)
        } else {
            setReload(true)
        }
    }, [eastingNorthing, address, isLoaded])

    useEffect(() => {
        if (isLoaded && uprnEastingNorthing?.length > 0 && !uprnEastingNorthing?.every(function(value, index) { return value === currentUprn[index]})) {
            const easting = uprnEastingNorthing[0]
            const northing = uprnEastingNorthing[1]
            const point = new OSPoint(northing, easting);
            const wgs84Meter = point.toWGS84()
            setCenterUprn({lat: wgs84Meter.latitude, lng: wgs84Meter.longitude});
            setCurrentUprn([easting, northing])
        }
    }, [uprnEastingNorthing, isLoaded])

    useEffect(() => {
        if (isLoaded && meterEastingNorthing?.length > 0 && !meterEastingNorthing?.every(function(value, index) { return value === currentMeter[index]})) {
            const easting = meterEastingNorthing[0]
            const northing = meterEastingNorthing[1]
            const point = new OSPoint(northing, easting);
            const wgs84Meter = point.toWGS84()
            setCenterMeter({lat: wgs84Meter.latitude, lng: wgs84Meter.longitude});
            setCurrentMeter([easting, northing])
        }
    }, [meterEastingNorthing, isLoaded])

    useEffect(() => {
        setMeters([])
        if (showAllMeters && mdsMeterData.length > 0) {
            formatMeters()
        }
    }, [isLoaded, mdsMeterData])

    return (
        !reload ? isLoaded ?
        <>
            <GoogleMap
                mapContainerClassName="map"
                zoom={ 18 }
                center={ mapCenter === "cmos" ? centerCmos : mapCenter === "uprn" ? centerUprn : mapCenter === "meter" ? centerMeter : {} }
                onDragEnd={() => setMapCenter("")}
                onLoad={(loadedMap) => {
                    loadedMap.addListener('click', () => {
                        setInfoWindowOpen([])
                    });
                }}
            >
                {showCMOSMarker &&
                    <Marker position={ centerCmos }
                    icon={{
                        path: "M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z",
                        fillColor: '#ea4335',
                        fillOpacity: 1,
                        scale: 1.3,
                        anchor: new window.google.maps.Point(12,24),
                    }}
                    />
                }
                {showUPRNMarker && centerUprn &&
                    <Marker position={ centerUprn }
                    icon={{
                        path: "M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z",
                        fillColor: '#33d1ad',
                        fillOpacity: 1,
                        scale: 1.3,
                        anchor: new window.google.maps.Point(12,24),
                    }}
                    />
                }
                {showMeterMarker && centerMeter &&
                    <>
                        { !showAllMeters && <Marker position={centerMeter}
                            icon={{
                                path: "M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z",
                                fillColor: '#6991fd',
                                fillOpacity: 1,
                                scale: 1.3,
                                anchor: new window.google.maps.Point(12,24),
                            }}
                        />}
                        { meters.length > 0 &&
                            meters.map((meter, index) => {
                                const easting = meter.GISX
                                const northing = meter.GISY
                                const point = new OSPoint(northing, easting);
                                const wgs84Meter = point.toWGS84()
                                return (
                                    <Marker position={{lat: wgs84Meter.latitude, lng: wgs84Meter.longitude}}
                                    icon={{
                                        path: "M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z",
                                        fillColor: '#6991fd',
                                        fillOpacity: 1,
                                        scale: 1.3,
                                        anchor: new window.google.maps.Point(12,24),
                                    }}
                                    onClick={() => {
                                        const infoWindows = [...infoWindowOpen]
                                        infoWindows[index] = infoWindows[index] ? false : true
                                        setInfoWindowOpen(infoWindows)
                                    }}
                                    >
                                        { infoWindowOpen[index] &&
                                            <InfoWindow
                                                onCloseClick={() => {
                                                    const infoWindows = [...infoWindowOpen]
                                                    infoWindows[index] = false
                                                    setInfoWindowOpen(infoWindows)
                                                }}
                                                options={{
                                                    headerContent: "Meter Serial Number:"
                                                }}
                                            >
                                                <p style={{ margin: '0px' }}>{meter.Manufacturer_Meter_Serial_Number}</p>
                                            </InfoWindow> 
                                        }
                                    </Marker>
                                )
                            })
                        }
                    </>
                }
            </GoogleMap>
            {displayExtraPins && 
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex' }}>
                        <p>Show CMOS Address:</p>
                        <Checkbox
                            color='primary'
                            checked={showCMOSMarker && centerCmos.lat}
                            onChange={() => setShowCMOSMarker(!showCMOSMarker) }
                            disabled={!centerCmos?.lat}
                        />
                        <i style={{ cursor: 'pointer', alignSelf: 'center', color: '#ea4335' }} class="fa-solid fa-location-dot" onClick={() => setMapCenter('cmos')} />
                    </div>
                    <div style={{ display: 'flex' }}>
                        <p>Show Matched UPRN Location:</p>
                        <Checkbox
                            color='primary'
                            checked={showUPRNMarker && !!centerUprn.lat}
                            onChange={() => setShowUPRNMarker(!showUPRNMarker) }
                            disabled={!centerUprn.lat}
                        />
                        <i style={{ cursor: 'pointer', alignSelf: 'center', color: '#33d1ad' }} class="fa-solid fa-location-dot" onClick={() => setMapCenter('uprn')} />
                    </div>
                    <div style={{ display: 'flex' }}>
                        <p>Show Meter Location:</p>
                        <Checkbox
                            color='primary'
                            checked={showMeterMarker && !!centerMeter.lat}
                            onChange={() => setShowMeterMarker(!showMeterMarker) }
                            disabled={!centerMeter.lat}
                        />
                        <i style={{ cursor: 'pointer', alignSelf: 'center', color: '#6991fd' }} class="fa-solid fa-location-dot" onClick={() => setMapCenter('meter')} />
                    </div>
                </div>
            }
        </> : loadError ? <p>Error Loading Map</p> : <div style={{backgroundColor: 'lightgray', height: '100%'}}></div> : <div style={{backgroundColor: 'lightgray', height: '100%'}}></div>
    )
}
