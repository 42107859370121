import { view } from '@risingstack/react-easy-state'
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import PropertyMap from '../../../components/property-map/property-map'
import GenericPageContainer from '../../../GenericPageElements/GenericPageContainer'
import request from '../../../helpers/request'
import hasPermission from '../../../helpers/permissions';
import session from '../../../stores/session'
import endpoints from '../../../helpers/endpoints'
import ReactModal from 'react-modal'
import { AddressBase, CouncilTax, CMOSLive, Summary, ValuationOffice, CMOSFormatted } from './shared'

export default view(function HouseholdPropertySearch() {
  const [summaryData, setSummaryData] = useState({})
  const [selectedMatches, setSelectedMatches] = useState({abp: '', voa: '', ct: ''})
  const [dataIssue, setDataIssue] = useState('')
  const [confirmModal, setConfirmModal] = useState(false)
  
  const [translations, setTranslations] = useState({})
  useEffect(() => {
    request(true).get('/translate?key=household_property')
    .then(e => {
      setTranslations(e.data)
    })
    }, [])
  useEffect(() => {
    setSelectedMatches({abp: '', voa: '', ct: ''})
  }, [summaryData.SPIDCORE])
  return (
    <GenericPageContainer
    containerWidth={"96vw"}
      title="Household Property - Search"
      titleIconClass={"fak fa-id-business-icon fa-2x colour-primary"}
      titleRight={
        <div>
          <Link className="navigate" to="/portal/household-property">
              To Summary
          </Link>
          { (hasPermission('HOUSEHOLD_PROPERTY', 'WORKFLOW')) ?
            <Link className="navigate" to="/portal/household-property/workflow">
                To Workflow
            </Link>
          : null }
        </div>
      }
    >
      <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
        <div style={{display: 'grid', gap: 10}}>
          <Summary translations={translations} summaryData={summaryData} setSummaryData={setSummaryData} />
        </div>

        <div style={{display: 'grid', gridAutoColumns: '1fr', gridAutoFlow: 'column', gap: 10}}>
          <CMOSLive translations={translations} summaryData={summaryData} selectedMatches={selectedMatches} setSelectedMatches={setSelectedMatches} />
          <AddressBase translations={translations} summaryData={summaryData} selectedMatches={selectedMatches} setSelectedMatches={setSelectedMatches} />
          <ValuationOffice translations={translations} summaryData={summaryData} selectedMatches={selectedMatches} setSelectedMatches={setSelectedMatches} />
          <CouncilTax translations={translations} summaryData={summaryData} selectedMatches={selectedMatches} setSelectedMatches={setSelectedMatches} />
          <CMOSFormatted translations={translations} summaryData={summaryData} selectedMatches={selectedMatches} setConfirmModal={setConfirmModal} />
        </div>
        <div>
          <ReactModal 
          isOpen={confirmModal} 
          onRequestClose={() => setConfirmModal(false)}
          className="card bulk-allocate"
          contentLabel="Wholesaler GAPs No Match"
          style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}>
            <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
            <h3>{dataIssue ? dataIssue : "Confirm"}</h3>
              {
                dataIssue ? 
                  <p>Please confirm you wish to report this SPID as {dataIssue}.</p>
                :
                  <p>Please confirm you are finished working on this SPID.</p>
              }
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center'}}>
                <button className="button compact smaller-text cancel" onClick={ () => setConfirmModal(false) }>Cancel</button>
                <button className="button compact smaller-text background-primary colour-white" onClick={() => {
                  request(true).post(endpoints.HOUSEHOLD_PROPERTY_SELECT_MATCH, {
                    spid: summaryData.SPIDCORE,
                    data_issue: dataIssue ?? ''
                  }).then(r => {
                    setSummaryData(null)
                    window.reloadSummaryData()
                    setConfirmModal(false); 
                  })
                }}
                >Confirm</button> 
              </div>
            </div>
          </ReactModal>
        </div>
        <div style={{minHeight: '40vh', marginBottom: '10em'}}>
          <PropertyMap 
          translations={translations} 
          address={summaryData['CMOS_Address_Single_Line']} 
          meterCoords={summaryData && summaryData.GISX && summaryData.GISY && [parseInt(summaryData.GISX), parseInt(summaryData.GISY)]} 
          />
        </div>
      </div>
    </GenericPageContainer>
  )
})
