import React, { Component } from 'react'
import Navigation from '../../../components/navigation/navigation'
import { ReactComponent as HouseIcon } from '../../../assets/House Icon.svg'
import Container from '../../../components/container/container'
import Footer from '../../../components/footer/footer'
import hasPermission from '../../../helpers/permissions';
import request from '../../../helpers/request'
import endpoints from '../../../helpers/endpoints'
import { view } from '@risingstack/react-easy-state'
import './occupierCOTAlerts.scss';

import { Link, withRouter } from 'react-router-dom'
import session from '../../../stores/session'
import HelpVideo from '../../../components/help-video/helpVideo'
import useWSProduct from '../../../helpers/useWSProduct'
import PropertyMap from '../../../components/property-map/property-map'
import { OccupierCOTAlertRight, OccupierCOTAlertTopContent, OccupierFootprintEvidenceFirst, OccupierFootprintEvidenceSecond } from './sharedComponents'
import PageTitle from '../../../components/page-header/pageTitle'

export default view(
    withRouter(
        class VoidCOTAlertsWorkflow extends Component {
            constructor(props) {
                super(props)

                this.state = {
                    accountNumber: '',
                    customer: null,
                    message: '',
                    showMap: true,
                    purchasable: null,
                    shouldRefreshMap: false,
                    workflow: [],
                    workflowIndex: null,
                    workflowLength: 0,
                    disableActions: false,
                    translations: null
                }
            }

            componentDidMount = () => {
                useWSProduct('void_cot_alerts');// eslint-disable-line
                request(true).get('/translate?key=occupier_cot_alert').then(r => {
                    this.setState({
                        translations: r.data
                    });
                }).catch(e => {
                    console.error(e);
                })
                request(true).get(endpoints.OCCUPIER_COT_ALERTS_WORKFLOW).then(r => {
                    this.setState({
                        workflow: r.data,
                        workflowIndex: 0,
                        workflowLength: r.data.length,
                        accountNumber: r.data[0],
                    }, () => {
                        this.getData();
                    });
                }).catch(e => {
                    console.error(e);
                });
            }

            componentWillUnmount() {
                useWSProduct('void_cot_alerts', 'unlisten');// eslint-disable-line
            }

            getData = () => {
                request(true).get(`${endpoints.OCCUPIER_COT_ALERTS_GET_CUSTOMER}?search=${this.state.accountNumber}`).then(r => {
                    console.log(r.data.customer);
                    this.setState({
                        customer: r.data.customer,
                        message: r.data.customer ? '' : 'Customer Does Not Exist',
                        showMap: true,
                        purchasable: r.data.customer.Date_Bought === null && r.data.customer.Occupier_Score > 0,
                        shouldRefreshMap: true,
                    }, () => {
                        this.setState({
                            shouldRefreshMap: false,
                            disableActions: false,
                        });
                    });
                }).catch(e => {
                    //console.log(e);
                });
            }

            purchaseCustomer = () => {
                this.setState({disableActions: true}, () => {
                    request(true).post(`${endpoints.OCCUPIER_COT_ALERTS_BUY_CUSTOMER}`, {
                        "customer": this.state.customer.Account_Number
                    }).then(r => {
                        var customers = [].concat(this.state.workflow);
                        customers.splice(this.state.workflowIndex, 1);

                        this.setState({
                            workflow: customers,
                            workflowIndex: this.state.workflowIndex - 1,
                            workflowLength: customers.length,
                            customer: r.data.customer,
                            message: r.data.customer ? '' : 'Customer Does Not Exist',
                            showMap: true,
                            purchasable: r.data.customer.Date_Bought === null
                        });
                    }).catch(e => {
                        console.log(e);
                    })
                });
            }

            excludeCustomer = () => {
                if (!this.state.customer || !this.state.customer.Account_Number) return;
                this.setState({disableActions: true}, () => {
                    request(true).post(endpoints.OCCUPIER_COT_ALERTS_EXCLUDE_CUSTOMER, {
                        customer: this.state.customer.Account_Number
                    }).then(r => {
                        var customers = [].concat(this.state.workflow);
                        customers.splice(this.state.workflowIndex, 1);

                        this.setState({
                            workflow: customers,
                            workflowLength: customers.length,
                            accountNumber: customers[this.state.workflowIndex],
                        }, () => {
                            this.getData();
                        });
                    }).catch(e => {
                        console.error(e);
                    });
                });
            }

            workflowNext = () => {
                if (this.state.workflowIndex + 1 > this.state.workflow.length) return
                this.setState({
                    workflowIndex: this.state.workflowIndex + 1,
                    accountNumber: this.state.workflow[this.state.workflowIndex + 1]
                }, () => {
                    this.getData();
                });
            }

            workflowPrev = () => {
                if (this.state.workflowIndex > this.state.workflow.length) return
                this.setState({
                    workflowIndex: this.state.workflowIndex - 1,
                    accountNumber: this.state.workflow[this.state.workflowIndex - 1]
                }, () => {
                    this.getData();
                });
            }

            render() {
                return (
                    <div className="grid grid-gap-15" id="void-cot-alerts-page">
                        <Navigation/>
                        <Container>
                            <PageTitle title={ this.props.history.location.pathname.indexOf('void') !== -1 ? 'Void COT Alerts - Workflow' : 'Occupier COT Alerts - Workflow' } titleRight={
                                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                    <Link className="navigate" to={ this.props.history.location.pathname.indexOf('void') !== -1 ? '/portal/void-cot-alerts/' : '/portal/occupier-cot-alerts/' }>
                                        To Overview
                                    </Link>
                                    <Link className="navigate" to={ this.props.history.location.pathname.indexOf('void') !== -1 ? '/portal/void-cot-alerts/search' : '/portal/occupier-cot-alerts/search' }>
                                        To Search
                                    </Link>
                                    <Link className="navigate" to="/portal/occupier-cot-alerts/report">
                                        To Report
                                    </Link>
                                </div>
                            } />
                        </Container>
                        <Container>
                            <div className="vcv">
                                <div className="primary-grid">
                                    <div className="left-grid">
                                        <div className="data-row with-buttons">
                                            <div className="data-row-title">
                                            { this.state.translations ? this.state.translations['account_number'] : '' }
                                            </div>
                                            <div style={{ position: 'relative' }}>
                                                <input disabled={true} type="text" className="data-row-field" name="account-number" value={ this.state.accountNumber } style={{ width: 'calc(100% - 15px)', height: '100%' }}/>
                                                <span className="message">{ this.state.workflowIndex + 1 }/{ this.state.workflowLength }</span>
                                            </div>
                                            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '15px', marginLeft: '15px' }}>
                                                <button className="button background-primary colour-white" onClick={ this.workflowPrev } disabled={this.state.workflowIndex === 0}>
                                                    <i className="fas fa-angle-left"></i>
                                                </button>
                                                <button className="button background-primary colour-white" onClick={ this.workflowNext } disabled={this.state.workflowIndex === this.state.workflowLength - 1}>
                                                    <i className="fas fa-angle-right"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <OccupierCOTAlertTopContent data={this.state} />
                                    </div>
                                    <div className="right-grid">
                                        { (!hasPermission('VOID_COT', 'PURCHASE') && this.state.purchasable) ?
                                            <button className="buy-button disabled" disabled={true}>
                                                Purchase Customer
                                            </button>
                                        :
                                            <button className="buy-button" disabled={ !this.state.purchasable || this.state.disableActions } onClick={ this.purchaseCustomer }>
                                                { this.state.purchasable === true || this.state.purchasable === null ? "Purchase Customer" :
                                                    this.state.customer.Bought_By ?
                                                        `Purchased on ${this.state.customer.Date_Bought} by ${this.state.customer.Bought_By}`
                                                    :
                                                        this.state.customer.Date_Bought ?
                                                            `Purchased on ${this.state.customer.Date_Bought}`
                                                        :
                                                            'Unavailable for Purchase'
                                                }
                                            </button>
                                        }
                                        <button className="buy-button" onClick={this.excludeCustomer} disabled={ this.state.disableActions }>
                                                Exclude Customer
                                        </button>
                                        <OccupierCOTAlertRight data={this.state} />
                                    </div>
                                </div>
                                <div className="secondary-grid">
                                    { this.state.showMap ?
                                        <PropertyMap address={ this.state.customer ? this.state.customer.SPID_Address : null } />
                                    :
                                        <>
                                            <div className="map hidden" onClick={ () => { if (this.state.customer) { this.setState({ showMap: true })}} }>
                                                { this.state.customer ? 'Click to show map' : '' }
                                            </div>
                                        </>
                                    }
                                    <OccupierFootprintEvidenceFirst data={this.state} />
                                    <OccupierFootprintEvidenceSecond data={this.state} />
                                </div>
                            </div>
                        </Container>
                        <HelpVideo video={session.company.vca_video_url}/>
                        <Footer />
                    </div>
                )
            }
        }
    )
)

