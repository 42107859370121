import React, { useEffect, useState } from 'react'
import { view } from '@risingstack/react-easy-state'
import './LongTermVacant.scss';
import { Link, withRouter } from 'react-router-dom';
import request from '../../../helpers/request';
import hasPermission from '../../../helpers/permissions';
import endpoints from '../../../helpers/endpoints';
import S3Modal from '../../../components/s3Modal/S3Modal';
import BulkBuy from '../../../components/cot-alert-bulk-buy/bulkBuy';
import BulkAllocate from '../../../components/cot-alert-bulk-allocate/bulkAllocate';
import ProductDataFilter from '../../../components/product-data-filter/product-data-filter';
import GenericDataChart from "../../../GenericPageElements/GenericDataChart";
import GenericPageContainer from "../../../GenericPageElements/GenericPageContainer";
import GenericProductFilterContainer from "../../../GenericPageElements/GenericProductFilterContainer";
import GenericDataRow from "../../../GenericPageElements/GenericDataRow";
import { CustomizedAxisTickMultiLine } from "../CompaniesHouseAlerts/CompaniesHouseAlerts";
import { Label } from '../VCA/voidCOTAlerts';
import Text from 'recharts/lib/component/Text';

export default view(function LongTermVacantSummary() {
  const [filters, setFilters] = useState({});
  const [selectedGroupBy, setSelectectGroupBy] = useState("Connection_Type");
  const [data, setData] = useState(null);
  const [dates, setDates] = useState([]);
  const [date, setDate] = useState(null);
  const [S3Model, setS3Model] = useState(null);
  const [bulkModel, setBulkModel] = useState('')

  const applyFilter = (column, value) => {
    setFilters((current) => {
      return {
        ...current,
        [column]: value,
      };
    });
  };

  useEffect(() => {
    request(true)
      .get(endpoints.LONG_TERM_VACANT_DATES)
      .then((e) => {
        setDates(e.data.dates);
          if (e.data.dates.length > 0) setDate("All_Refreshes");
      });
  }, []);

  useEffect(() => {
    if (!date) return;
    request(true)
      .get(endpoints.LONG_TERM_VACANT_DATA, {
        doesCancel: true,
        params: {
          date: date,
          filter: filters,
          group: selectedGroupBy,
        },
      })
      .then((e) => {
        setData(e.data);
      });
  }, [filters, selectedGroupBy, date]);

  return [
    <GenericPageContainer
      title="Long Term Vacant - Summary"
      titleIconClass={"fak fa-id-business-icon fa-2x colour-primary"}
      titleRight={
        <div>
          {hasPermission('LONG_TERM_VACANT', 'DOWNLOAD') ? (
            <button className="navigate" onClick={() => setS3Model("download")}>
              Download Return File
            </button>
          ) : null}
          {hasPermission('LONG_TERM_VACANT', 'UPLOAD') ? (
            <button className="navigate" onClick={() => setS3Model("upload")}>
              Issue Input File
            </button>
          ) : null}
          <Link className="navigate" to="/portal/long-term-vacant/search">
              To Search
          </Link>
          { (hasPermission('LONG_TERM_VACANT', 'WORKFLOW')) ?
            <Link className="navigate" to="/portal/long-term-vacant/workflow">
                To Workflow
            </Link>
          : null }
          { (hasPermission('LONG_TERM_VACANT', 'DASHBOARD')) ?
              <Link className="navigate" to="/portal/long-term-vacant/dashboard">
                  To Dashboard
              </Link>
          : null }
        </div>
      }
    >
      <div style={{maxWidth: '20vw', marginBottom: 15}}>
      <GenericDataRow title={"File Date"} contentColour={"white"}>
        <select
          onChange={(_) => setDate(_.target.value)}
          value={date}
          style={{
            width: "100%",
            height: "100%",
            border: "none",
            backgroundColor: "white",
            paddingLeft: "10px",
          }}
        >
          <option key={"All_Refreshes"} value={"All_Refreshes"}>All Refreshes</option>
          {dates.map((item) => {
            return (
              <option key={item.raw} value={item.raw}>
                {item.pretty}
              </option>
            );
          })}
        </select>
      </GenericDataRow>
      </div>
      <div className='LTV summary'>
        <GenericDataChart
          dataReady={!!data}
          data={data}
          selectedGroupBy={selectedGroupBy}
          setSelectectGroupBy={setSelectectGroupBy}
          groupingsEndpoint={endpoints.LONG_TERM_VACANT_GROUPINGS}
          sizes={{chart: 600, chartContainer: 625, chartXAxisFontSize: 12, wrapperStyle: { paddingTop: '40px' }}}
          xAxisProps={{interval: 0}}
          customBarTick={
            _ => _.payload && _.payload.value !== null && typeof _.payload.value === 'number' ? 
              <Label {..._} /> 
            : 
              <CustomizedAxisTickMultiLine x={_.x} y={_.y} payload={_.payload}/>
          }
          customTableHeaders={<CustomTableHeaders/>}
        />
        <GenericProductFilterContainer clearFilters={() => setFilters({})}>
          <ProductDataFilter
            filterState={filters}
            applyFilter={applyFilter}
            dataFilterEndpoint={endpoints.LONG_TERM_VACANT_FILTERS}
          />
          <div className='grid grid-columns-2 grid-gap-5 mta'>
              { (hasPermission('LONG_TERM_VACANT', 'BULK-BUY')) ?
                  <button style={{  gridColumn: 'span 2' }} className="button bulk-buy-button compact smaller-text" onClick={() => setBulkModel('purchase')}>
                      Create Download File
                  </button>
              : '' }
              { (hasPermission('LONG_TERM_VACANT', 'ALLOCATE')) ?
                  <button style={{ gridColumn: !hasPermission('LONG_TERM_VACANT', 'UNALLOCATE') && 'span 2' }} 
                    className="button bulk-buy-button compact smaller-text" onClick={() => setBulkModel('allocate')}>
                      Allocate
                  </button>
              : '' }
              { (hasPermission('LONG_TERM_VACANT', 'UNALLOCATE')) ?
                  <button style={{ gridColumn: !hasPermission('LONG_TERM_VACANT', 'ALLOCATE') && 'span 2' }}
                   className="button bulk-buy-button compact smaller-text" onClick={() => setBulkModel('unallocate')}>
                      Unallocate
                  </button>
              : '' }
          </div>
        </GenericProductFilterContainer>
      </div>
    </GenericPageContainer>,
    <S3Modal
      closeModal={() => setS3Model(null)}
      modalMode={S3Model}
      showModal={!!S3Model}
      listingUrl={endpoints.LONG_TERM_VACANT_FILES}
      uploadUrl={endpoints.LONG_TERM_VACANT_FILES}
    />,
    <BulkBuy 
    SentenceFirst={({count}) => <>The selected data set includes {count} accounts to be downloaded. Press the 'Create File' button to create the download file and you will receive an email notification once this is available. Alternatively press the 'Cancel' button to go back to the summary screen.</>}
    SentenceSecond={() => <></>}
    purchaseLabel="Download Limit:"
    buyButtonText="Create File"
    completeOnFirstStep={true} 
    workflowUsersEndpoint={endpoints.LONG_TERM_VACANT_WF_USERS} 
    bulkEndpoint={endpoints.LONG_TERM_VACANT_SUMMARY_BULK} 
    modalIsOpen={bulkModel === 'purchase'} 
    closeModal={() => setBulkModel('')} 
    filters={filters} 
    date={date} />,
    <BulkAllocate 
    workflowUsersEndpoint={endpoints.LONG_TERM_VACANT_WF_USERS} 
    bulkEndpoint={endpoints.LONG_TERM_VACANT_SUMMARY_BULK} 
    unallocateEndpoint={endpoints.LONG_TERM_VACANT_UNALLOCATE_GET} 
    modalIsOpen={bulkModel === 'allocate' || bulkModel === "unallocate"} 
    mode={bulkModel === "unallocate" ? "unallocate" : null}
    closeModal={() => setBulkModel('')} 
    filters={filters} 
    date={date} />,
  ];
})

const CustomTableHeaders = () => {
  return (
    <>
      <th className="low">
          DQI
      </th>
      <th className="low">
          <i className="fal fa-star"></i>
          <i className="fal fa-star"></i>
          <i className="fal fa-star"></i>
          <i className="fal fa-star"></i>
          <i className="fal fa-star"></i>
      </th>
      <th className="low">
          <i className="fas fa-star"></i>
          <i className="fal fa-star"></i>
          <i className="fal fa-star"></i>
          <i className="fal fa-star"></i>
          <i className="fal fa-star"></i>
      </th>
      <th className="low">
          <i className="fas fa-star"></i>
          <i className="fas fa-star"></i>
          <i className="fal fa-star"></i>
          <i className="fal fa-star"></i>
          <i className="fal fa-star"></i>
      </th>
      <th className="low">
          <i className="fas fa-star"></i>
          <i className="fas fa-star"></i>
          <i className="fas fa-star"></i>
          <i className="fal fa-star"></i>
          <i className="fal fa-star"></i>
      </th>
      <th className="low">
          <i className="fas fa-star"></i>
          <i className="fas fa-star"></i>
          <i className="fas fa-star"></i>
          <i className="fas fa-star"></i>
          <i className="fal fa-star"></i>
      </th>
      <th className="low">
          <i className="fas fa-star"></i>
          <i className="fas fa-star"></i>
          <i className="fas fa-star"></i>
          <i className="fas fa-star"></i>
          <i className="fas fa-star"></i>
      </th>
      <th className='no-radius bold'>Total</th>
    </>
  )
}
