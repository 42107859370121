import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import BulkBuy from '../../../components/cot-alert-bulk-buy/bulkBuy';
import BulkAllocate from '../../../components/cot-alert-bulk-allocate/bulkAllocate';
import ProductDataFilter from '../../../components/product-data-filter/product-data-filter'
import S3Modal from '../../../components/s3Modal/S3Modal'
import GenericDataChart from '../../../GenericPageElements/GenericDataChart'
import GenericDataRow from '../../../GenericPageElements/GenericDataRow'
import GenericPageContainer from '../../../GenericPageElements/GenericPageContainer'
import GenericProductFilterContainer from '../../../GenericPageElements/GenericProductFilterContainer'
import endpoints from '../../../helpers/endpoints'
import hasPermission from '../../../helpers/permissions';
import request from '../../../helpers/request'
import session from '../../../stores/session'
import { Label } from '../OccupierCOTAlerts/occupierCOTAlerts'
import { CustomizedAxisTickMultiLine } from "../CompaniesHouseAlerts/CompaniesHouseAlerts";
import './OccupierValidation.scss';
import { UnallocateModal } from '../VacantCOTAlerts/vacantCOTAlerts';

export default function OccupierValidationSummary() {
  const [groupBy, setGroupBy] = useState('Type');
  const [data, setData] = useState(null);
  const [filters, setFilters] = useState({})
  const [date, setDate] = useState(null);
  const [dates, setDates] = useState([])
  const [bulkModel, setBulkModel] = useState('')
  const [S3Model, setS3Model] = useState(null);
  const [translations, setTranslations] = useState({})
  useEffect(() => {
    request(true).get('/translate?key=occupier_validation')
    .then(e => {
      setTranslations(e.data)
    })
    }, [])
  const applyFilter = (column, value) => {
    setFilters((current) => {
      return {
        ...current,
        [column]: value,
      };
    });
  };
  const getData = useCallback(() => {
    request(true).get(endpoints.OCCUPIER_VALIDATION_DATA, {
      params: {
        date: date,
        filter: filters,
        group: groupBy,
      }
    })
    .then((e) => {
      setData(e.data);
    });
  }, [groupBy, filters, date])

  useEffect(() => {
    getData()
  }, [groupBy, filters, date]);

  useEffect(() => {
    request(true)
      .get(endpoints.OCCUPIER_VALIDATION_DATES)
      .then((e) => {
        setDates(e.data.dates);
        if (e.data.dates.length > 0) setDate(e.data.dates[0].raw);
      });
  }, []);
  return [
    <GenericPageContainer
      title={'Occupier Validation'}
      titleIconClass='fak fa-id-business-icon fa-2x colour-primary'
      titleRight={<div>
        <Link className="navigate" to="/portal/occupier-validation/search">
            To Search
        </Link>
        { (hasPermission('OCCUPIER_VALIDATION', 'WORKFLOW')) ?
            <Link className="navigate" to="/portal/occupier-validation/workflow">
                To Workflow
            </Link>
        : null }
        { (hasPermission('OCCUPIER_VALIDATION', 'DASHBOARD')) ?
            <Link className="navigate" to="/portal/occupier-validation/dashboard">
              To Dashboard
            </Link>
        : null }
        {hasPermission('OCCUPIER_VALIDATION', 'DOWNLOAD') ? (
          <button className="navigate" onClick={() => setS3Model("download")}>
            Download Return File
          </button>
        ) : null}
        {hasPermission('OCCUPIER_VALIDATION', 'UPLOAD') ? (
          <button className="navigate" onClick={() => setS3Model("upload")}>
            Issue Input File
          </button>
        ) : null}
      </div>}
    >
    <div style={{maxWidth: '20vw', marginBottom: 15}}>
    <GenericDataRow title={translations['file-date']} contentColour={"white"}>
      <select
        onChange={(_) => setDate(_.target.value)}
        value={date}
        style={{
          width: "100%",
          height: "100%",
          border: "none",
          backgroundColor: "white",
          paddingLeft: "10px",
        }}
      >
        {dates.map((item) => {
          return (
            <option key={item.raw} value={item.raw}>
              {item.pretty}
            </option>
          );
        })}
      </select>
    </GenericDataRow>
    </div>
      <div class="occupier-validation">
      <GenericDataChart
          groupingsEndpoint={endpoints.OCCUPIER_VALIDATION_GROUPINGS}
          data={data}
          dataReady={!!data}
          selectedGroupBy={groupBy}
          setSelectectGroupBy={setGroupBy}
          overrideTableHeader="Occupier Banding"
          sizes={{chart: 600, chartContainer: 625, chartXAxisFontSize: 12, wrapperStyle: { paddingTop: '40px' }}}
          xAxisProps={{interval: 0}}
          customBarTick={CustomizedAxisTickMultiLine}
      ></GenericDataChart>
      <GenericProductFilterContainer clearFilters={() => setFilters({})}>
          <ProductDataFilter
            filterState={filters}
            applyFilter={applyFilter}
            dataFilterEndpoint={endpoints.OCCUPIER_VALIDATION_FILTERS}
          />
          <div className='grid grid-columns-2 grid-gap-5 mta'>
              { (hasPermission('OCCUPIER_VALIDATION', 'BULK-BUY')) ?
                  <button className="button bulk-buy-button compact smaller-text" onClick={() => setBulkModel('purchase')}>
                      Create Download File
                  </button>
              : '' }
              <div className='grid grid-gap-5'>
                { (hasPermission('OCCUPIER_VALIDATION', 'ALLOCATE')) ?
                    <button className="button bulk-buy-button compact smaller-text" onClick={() => setBulkModel('allocate')}>
                        Allocate Cases
                    </button>
                : '' }
                { (hasPermission('OCCUPIER_VALIDATION', 'UNALLOCATE')) ?
                    <button className="button bulk-buy-button compact smaller-text" onClick={() => setBulkModel('unallocate')}>
                        Unallocate Cases
                    </button>
                : '' }
              </div>
              { (hasPermission('OCCUPIER_VALIDATION', 'PRIORITY-ALLOCATE')) ?
                  <button className="button bulk-buy-button compact" onClick={() => setBulkModel('priority')}>
                      Priority Allocate
                  </button>
              : '' }
                  { (hasPermission('OCCUPIER_VALIDATION', 'PRIORITY-UNALLOCATE')) ?
                  <button className="button bulk-buy-button compact" onClick={() => setBulkModel('priority-unallocate')}>
                      Priority Unallocate
                  </button>
              : '' }
          </div>
      </GenericProductFilterContainer>
      </div>
    </GenericPageContainer>,
    <S3Modal
      closeModal={() => setS3Model(null)}
      modalMode={S3Model}
      showModal={!!S3Model}
      listingUrl={endpoints.OCCUPIER_VALIDATION_FILES}
      uploadUrl={endpoints.OCCUPIER_VALIDATION_FILES}
    />,
    <BulkBuy
    SentenceFirst={({count}) => <>The selected data set includes {count} accounts to be downloaded. Press the 'Create File' button to create the download file and you will receive an email notification once this is available. Alternatively press the 'Cancel' button to go back to the summary screen.</>}
    SentenceSecond={() => <></>}
    buyButtonText="Create File" completeOnFirstStep={true} workflowUsersEndpoint={endpoints.OCCUPIER_VALIDATION_WF_USERS} bulkEndpoint={endpoints.OCCUPIER_VALIDATION_BULK_BASE} modalIsOpen={bulkModel === 'purchase'} closeModal={() => setBulkModel('')} filters={filters} date={date} />,
    <BulkAllocate workflowUsersEndpoint={endpoints.OCCUPIER_VALIDATION_WF_USERS} bulkEndpoint={endpoints.OCCUPIER_VALIDATION_BULK_BASE} modalIsOpen={bulkModel === 'allocate'} closeModal={() => setBulkModel('')} filters={filters} date={date} />,
    <BulkAllocate workflowUsersEndpoint={endpoints.OCCUPIER_VALIDATION_WF_USERS} bulkEndpoint={endpoints.OCCUPIER_VALIDATION_BULK_BASE} urlParams={{priority: 'yes'}} modalIsOpen={bulkModel === 'priority'} closeModal={() => setBulkModel('')} filters={filters} date={date} />,
    <UnallocateModal
      modalIsOpen={bulkModel === 'unallocate'} 
      closeModal={() => setBulkModel('')}
      date={date}
      filters={filters}
      GET_ENDPOINT={endpoints.OCCUPIER_VALIDATION_UNALLOCATE_GET}
      POST_ENDPOINT={endpoints.OCCUPIER_VALIDATION_UNALLOCATE_POST}
    />,
    <UnallocateModal
        modalIsOpen={bulkModel === 'priority-unallocate'} 
        closeModal={() => setBulkModel('')}
        date={date}
        filters={filters}
        GET_ENDPOINT={endpoints.OCCUPIER_VALIDATION_UNALLOCATE_GET}
        POST_ENDPOINT={endpoints.OCCUPIER_VALIDATION_UNALLOCATE_POST}
        urlParams={{priority: 'yes'}}
    />
  ]
}
