import React, { useCallback, useEffect } from 'react'
import GenericPageContainer from '../../../GenericPageElements/GenericPageContainer'
// import { GenericTable } from './sharedComponents'
import ReactSelect from 'react-select'
import { DatePicker, DateTimePicker } from '@material-ui/pickers';
import request from '../../../helpers/request';
import hasPermission from '../../../helpers/permissions';
import endpoints from '../../../helpers/endpoints'
import { useState } from 'react';
import session from '../../../stores/session';
import { Link } from 'react-router-dom';
import { view } from '@risingstack/react-easy-state';
import ReactModal from 'react-modal';
import moment from 'moment'
import { VaCADashboardMonthAndYear, VaCADashboardMoveInsPerformed, VaCADashboardTeamPerformance, VaCADashboardTeamWorkflow, VaCADashboardTeamPerformanceDays, VaCADashboardMoveInsPerformedByOccupierConfidence, VaCADashboardRefreshCompletionPerformance } from '../VacantCOTAlerts/VacantCOTAlertsDashboard'

export default view(function LongTermVacantDashboard() {
  const [filter, setFilter] = useState({})
  return (
    <GenericPageContainer
    title={'Long Term Vacant - Dashboard'}
    titleIconClass={"fak fa-id-business-icon fa-2x colour-primary"}
    containerWidth={'90vw'}
    titleRight={<>
        <Link className="navigate" to="/portal/long-term-vacant/">
            To Overview
        </Link>
        <Link className="navigate" to="/portal/long-term-vacant/search">
            To Search
        </Link>
        { (hasPermission('VACANT_COT', 'WORKFLOW')) ?
            <Link className="navigate" to="/portal/long-term-vacant/workflow">
                To Workflow
            </Link>
        : null }
    </>}
    >

      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 20 }}>
        <div style={{gridColumnStart: 1, gridColumnEnd: 3}}>
          <VaCADashboardMonthAndYear prefix={'LONG_TERM_VACANT'} />
        </div>
        <VaCADashboardMoveInsPerformed prefix={'LONG_TERM_VACANT'} />
        <VaCADashboardTeamPerformance prefix={'LONG_TERM_VACANT'} />

        <VaCADashboardTeamWorkflow prefix={'LONG_TERM_VACANT'} filter={filter} setFilter={setFilter} />
        <VaCADashboardTeamPerformanceDays prefix={'LONG_TERM_VACANT'} filter={filter} />
        <VaCADashboardMoveInsPerformedByOccupierConfidence prefix={'LONG_TERM_VACANT'} filter={filter} />
        <VaCADashboardRefreshCompletionPerformance prefix={'LONG_TERM_VACANT'} filter={filter} />
      </div>

    </GenericPageContainer>
  )
})