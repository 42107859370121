import React, { Component } from 'react'
import Navigation from '../components/navigation/navigation'
import PortalCard from '../components/portal-card/portalCard'
import WelcomeText from '../components/welcome-text/welcomeText'
import Container from '../components/container/container'
import Footer from '../components/footer/footer'
import request from '../helpers/request'
import endpoints from '../helpers/endpoints'

export default class PortalPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            products: [],
            showNotes: false,
            loading: false,
            notes: null
        }
    }

    toggleNotes = () => {
        this.setState({showNotes: !this.state.showNotes});
        if (!this.state.notes) {
            request(true).get(endpoints.ADMIN_VERSION_HISTORY_CHANGES).then(r => {
                this.setState({notes: r.data})
                request(true).post(endpoints.ADMIN_VERSION_HISTORY_SEEN_CHANGES);
            })
        }
    }

    componentDidMount() {
        request(true).get(endpoints.PRODUCTS).then(r => {
            this.setState({
                products: r.data
            });
        }).catch(r => {
            console.error(r);
        });
    }

    render() {
        return (
            <div className="grid grid-gap-15">
                <Navigation />
                <div className='release-notes-side-bar' style={{visibility: this.state.showNotes ? 'visible' : 'hidden', pointerEvent: this.state.showNotes ? 'none' : 'unset'}}>
                    <div className='release-notes-container'>
                        <div>
                            <div style={{ cursor: this.state.showNotes ? 'pointer' : 'unset' }} onClick={() => this.setState({showNotes: false})}>
                                <i class="fa-regular fa-right-to-line" style={{ marginRight: '1em' }} />
                                Close
                            </div>
                            <h2 className='border'>Release Notes</h2>
                            { this.state.notes ?
                                this.state.notes.map(note => {
                                    console.log(note)
                                    return (
                                        <div className='border'>
                                            <h3>{note.Version_Number}</h3>
                                            <p style={{ wordWrap: 'break-word' }} dangerouslySetInnerHTML={{ __html: note.Release_Notes }}></p>
                                        </div>
                                    )
                                })
                            : <i className="fa fa-spinner fa-spin" /> }
                        </div>
                    </div>
                </div>
                <WelcomeText showNotes={this.toggleNotes} />
                <Container>
                    <div className="grid grid-columns-4 grid-portal grid-gap-30 margin-top-25">
                        { this.state.products.map(product => {
                            return (
                                <PortalCard floatHouse={product.enable_house_icon} key={product.id} title={product.name} icon={product.icon} description={product.description} link={product.frontend_path} available={product.available}/>
                            )
                        })}
                    </div>
                </Container>
                <Footer />
            </div>
        )
    }
}
