import React, { useEffect, useCallback } from 'react'
import { useState } from 'react'
import GenericPageContainer from '../../../GenericPageElements/GenericPageContainer'
import endpoints from '../../../helpers/endpoints';
import hasPermission from '../../../helpers/permissions';
import request from '../../../helpers/request';
import {
  CompanyInfo,
  GoogleEvidence,
  CompaniesHouseEvidence,
  YellEvidence,
  AddressDataEvidence,
  FSAEvidence,
  BTEvidence,
  TripAdvisorEvidence,
  DVSAEvidence,
  CQCEvidence, 
  JustEatEvidence, 
  One92Evidence, 
  FacebookEvidence,
  TitleOwnerRow
} from './shared'
import Select from 'react-select'
import GenericDataRow, { GenericDataRowTitle } from '../../../GenericPageElements/GenericDataRow';
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from 'react-router-dom';
import session from '../../../stores/session';
import PropertyMap from '../../../components/property-map/property-map'
import Twilio from '../../../components/twilio/twilio';

export default function IdenteqOccupierValidationSearch() {
  const [SPID, setSPID] = useState('')
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState('O')
  const [translations, setTranslations] = useState({})
  const [customerNumber, setCustomerNumber] = useState()
  const [twilioHeight, setTwilioHeight] = useState('500')
  useEffect(() => {
    const div = document.getElementById('ov-search');
    setTwilioHeight(div ? div.clientHeight : null);
    request(true).get('/translate?key=occupier_validation')
    .then(e => {
      setTranslations(e.data)
    })
    }, [])
  const getData = useCallback(() => {
    if (!SPID) return;
    setCustomerNumber(null);
    setLoading(true)
    setData([])
    request(true).get(endpoints.IDENTEQ_OCCUPIED_VALIDATION_LOOKUP, {
      params: {
        SPID
      }
    }).then(e => {
      setLoading(false)
      setData(e.data)
    }).catch(e => {
      setLoading(false)
      setData({})
      window.alert('Could not find SPID.')
    })
  }, [SPID])
  useEffect(() => {
    request(true).post(`${endpoints.IDENTEQ_OCCUPIER_VALIDATION_UPDATE_STATUS}`, {
      customer: data['SPID'],
      status: data['status']
    }).catch(e => {
      console.log(e);
    })
  }, [data.status])
  const purchase = () => {
    request(true).post(endpoints.IDENTEQ_OCCUPIER_VALIDATION_PURCHASE, {
      spid: SPID
    }).then(e => {
      setSPID('')
      getData()
      window.alert('Purchased data')
    }).catch(() => {
      console.log('Unable to purchase')
    })
  }
  const updateStatus = (event) => {
    setData({
      ...data,
      status: event
    })
  }
  const updateCustomerNumber = (number) => {
    setCustomerNumber(number)
  }
  const updateCustomer = (customer) => {
    setData(customer)
  }
  return (
    <GenericPageContainer containerWidth={'97.5vw'} title="Identeq Occupier Validation - Search"
    titleRight={<div>
        {
          loading ? <CircularProgress size={22} /> : null
        }
        <Link className="navigate" to="/portal/identeq-occupier-validation">
            To Overview
        </Link>
        { (hasPermission('IDENTEQ_OCCUPIER_VALIDATION', 'WORKFLOW')) ?
            <Link className="navigate" to="/portal/identeq-occupier-validation/workflow">
                To Workflow
            </Link>
        : null }
      </div>}>
      <div style={{display:'grid', gridTemplateColumns:'4fr 1fr', gridGap:'15px', gap:'15px'}}>
        <div id="ov-search">
          <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
            <div style={{display: 'grid', gridTemplateColumns: '3fr 1fr', gap: 10, alignItems: 'center'}}>
              <GenericDataRow title={translations['spid']} contentColour={'var(--white)'}>
                <input disabled={loading} style={{width: '100%', outline: 'none', border: 'none', backgroundColor: 'var(--white)'}} value={SPID} onChange={e => setSPID(e.target.value)} />
              </GenericDataRow>
              <button onClick={getData} className='navigate' style={{marginLeft: 0}}>Search</button>
            </div>
            <CompanyInfo translations={translations} data={data} mode={mode} setMode={setMode} updateStatus={updateStatus} right={<>
              <button onClick={purchase} className='navigate' style={{marginLeft: 0}}>Occupier Moved Out</button>
            </>} />
            <TitleOwnerRow translations={translations} data={data} mode={mode} setCustomerNumber={setCustomerNumber} />
            <div style={{display: 'grid', gridAutoColumns: '1fr', gridAutoFlow: 'column', gap: 10}}>
              <GoogleEvidence data={data} mode={mode} setCustomerNumber={setCustomerNumber} />
              <CompaniesHouseEvidence data={data} mode={mode} setCustomerNumber={setCustomerNumber} />
              <YellEvidence data={data} mode={mode} setCustomerNumber={setCustomerNumber} />
              <AddressDataEvidence data={data} mode={mode} setCustomerNumber={setCustomerNumber} />
            </div>
            <div style={{display: 'grid', gridAutoColumns: '1fr', gridAutoFlow: 'column', gap: 10}}>
              <FSAEvidence data={data} mode={mode} setCustomerNumber={setCustomerNumber} />
              <BTEvidence data={data} mode={mode} setCustomerNumber={setCustomerNumber} />
              <TripAdvisorEvidence data={data} mode={mode} setCustomerNumber={setCustomerNumber} />
              <DVSAEvidence data={data} mode={mode} setCustomerNumber={setCustomerNumber} />
            </div>
            <div style={{display: 'flex', flexDirection: 'row', gap: '.5em', height: '100%'}}>
              <CQCEvidence data={data} mode={mode} setCustomerNumber={setCustomerNumber} />
              <JustEatEvidence data={data} mode={mode} setCustomerNumber={setCustomerNumber} />
              <One92Evidence data={data} mode={mode} setCustomerNumber={setCustomerNumber} />
              <FacebookEvidence data={data} mode={mode} setCustomerNumber={setCustomerNumber} />
            </div>
          </div>

          <div className="secondary-grid" style={{gridTemplateColumns: '1fr 3fr', gridGap: '15px', gap: '15px', display: 'grid', minHeight: '300px'}}>
                <table className="table borders squish super-small-text extra-row-padding">
                  <thead>
                      <tr>
                          <th style={{textAlign: 'left'}}>
                              Days
                          </th>
                          <th>
                              Opening Times
                          </th>
                      </tr>
                  </thead>
                  <tbody>
                    {
                        ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map(day => {
                            return (
                                <tr>
                                    <td style={{textAlign: "left", width: "100px"}}>{ day }</td>
                                    <td style={{minWidth: "unset"}}>{ data?.[mode + "_Google_Opening_Hours"] ? JSON.parse(data?.[mode + "_Google_Opening_Hours"].replaceAll('\'', '"'))[day] : null }</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
              </table>
              <div style={{height: 'auto'}}>
                  <PropertyMap address={ data ? data.SPID_Address : null } />
              </div>
          </div>
        </div>
        <Twilio module="identeq_occupier_validation" accountNumber={data ? SPID : null} customer={data} updateCustomer={updateCustomer} customerNumber={customerNumber} updateCustomerNumber={updateCustomerNumber} height={twilioHeight} surveyEndpoint={endpoints.IDENTEQ_OCCUPIER_VALIDATION_SUBMIT_ANSWERS} />
      </div>
    </GenericPageContainer>
  )
}
