import React, { Component } from 'react'
import Navigation from '../../../components/navigation/navigation'
import { ReactComponent as HouseIcon } from '../../../assets/House Icon.svg'
import Container from '../../../components/container/container'
import Footer from '../../../components/footer/footer'
import request from '../../../helpers/request'
import hasPermission from '../../../helpers/permissions';
import endpoints from '../../../helpers/endpoints'
import { view } from '@risingstack/react-easy-state'
import './voidCOTAlerts.scss';

import { Link, withRouter } from 'react-router-dom'
import session from '../../../stores/session'
import HelpVideo from '../../../components/help-video/helpVideo'
import useWSProduct from '../../../helpers/useWSProduct'
import PropertyMap from '../../../components/property-map/property-map'
import { GenericTable } from '../VacantCOTAlerts/sharedComponents'
import PageTitle from '../../../components/page-header/pageTitle'

export default view(
  withRouter(
    class VoidCOTAlertsNFIData extends Component {
      constructor(props) {
        super(props)

        this.state = {
          accountNumber: '',
          customer: null,
          customers: null,
          showMap: true,
          purchasable: null,
          shouldRefreshMap: false,
          translations: null,
          workflow: [],
          workflowIndex: 0,
          workflowLength: 0,
        }
      }

      componentDidMount = () => {
        useWSProduct('void_cot_alerts');// eslint-disable-line
        request(true).get('/translate?key=void_cot_alerts').then(r => {
          this.setState({
            translations: r.data
          });
        }).catch(e => {
          console.error(e);
        })
      }

      accountNumberChange = (event) => {
        this.setState({
          accountNumber: event.target.value
        });
      }

      getData = () => {
        request(true).get(`${endpoints.VOID_COT_ALERTS_NFI_GET_CUSTOMER}?search=${this.state.accountNumber}`).then(r => {
            console.log(r.data.customer);
            if (!r.data.customer && !r.data[0]) window.alert('Account not found.');
            if (!Array.isArray(r.data)) r.data = [r.data];
            this.setState({
                workflowIndex: 0,
            }, () => {
                this.setState({
                    customer: r.data[0].customer,
                    customers: r.data,
                    workflowLength: r.data.length,
                    showMap: true,
                    purchasable: r.data[this.state.workflowIndex].customer && r.data[this.state.workflowIndex].customer.Date_Bought === null && r.data[this.state.workflowIndex].customer.Occupier_Score > 0,
                    shouldRefreshMap: true,
                }, () => {
                    this.setState({ shouldRefreshMap: false });
                });
            })
        }).catch(e => {
            //console.log(e);
        });
    }

      purchaseCustomer = () => {
        request(true).post(`${endpoints.VOID_COT_ALERTS_BUY_CUSTOMER}`, {
          "customer": this.state.customer.Account_Number,
          "file_date": this.state.customer.File_Date,
          "SPID_Address": this.state.customer.SPID_Address,
          "AddressBase_Address": this.state.customer.AddressBase_Address,
          "mode": "NFI"
        }).then(r => {
          this.setState({
            customer: r.data.customer,
            showMap: true,
            purchasable: r.data.customer.Date_Bought === null
          });
        }).catch(e => {
          console.log(e);
        })
      }

      searchKeyDown = (e) => {
        if (e.which === 13) {
          this.getData();
        }
      }

      componentWillUnmount() {
        useWSProduct('void_cot_alerts', 'unlisten');// eslint-disable-line
      }

      workflowNext = () => {
        if (this.state.workflowIndex + 1 > this.state.workflowLength) return
        this.setState({
            workflowIndex: this.state.workflowIndex + 1,
        }, () => {
            this.setState({
                customer: this.state.customers[this.state.workflowIndex].customer,
                showMap: true,
                purchasable: this.state.customers[this.state.workflowIndex].customer && this.state.customers[this.state.workflowIndex].customer.Date_Bought === null && this.state.customers[this.state.workflowIndex].customer.Occupier_Score > 0,
                shouldRefreshMap: true,
            }, () => {
                this.setState({ shouldRefreshMap: false });
            });
        });
    }

    workflowPrev = () => {
        if (this.state.workflowIndex > this.state.workflowLength) return
        this.setState({
            workflowIndex: this.state.workflowIndex - 1,
        }, () => {
            this.setState({
                customer: this.state.customers[this.state.workflowIndex].customer,
                showMap: true,
                purchasable: this.state.customers[this.state.workflowIndex].customer && this.state.customers[this.state.workflowIndex].customer.Date_Bought === null && this.state.customers[this.state.workflowIndex].customer.Occupier_Score > 0,
                shouldRefreshMap: true,
            }, () => {
                this.setState({ shouldRefreshMap: false });
            });
        });
    }

      footprintTableLeft = [
        [ 'Council Tax', 'NFI_Council_Tax' ],
        [ 'Housing Benefit Claimants', 'NFI_Housing_Benefit_Claimants' ],
        [ 'Electoral Register', 'NFI_Electoral_Register' ],
        [ 'Housing Rents', 'NFI_Housing_Rents' ],
        [ 'State Benefits', 'NFI_State_Benefits' ],
        [ 'Residents Parking Permit', 'NFI_Residents_Parking_Permit' ],
        [ 'Student Loans', 'NFI_Student_Loans' ],
        [ 'Blue Badges', 'NFI_Blue_Badges' ],
      ]

      footprintTableRight = [
        [ 'Deferred Pensions', 'NFI_Deferred_Pensions' ],
        [ 'Lone Pensioner Allowance', 'NFI_Lone_Pensioner_Allowance' ],
        [ 'Occupational Pensions', 'NFI_Occupational_Pensions' ],
        [ 'Pensions Gratuities', 'NFI_Pensions_Gratuities' ],
        [ 'Concessionary Travel Pass', 'NFI_Concessionary_Travel_Pass' ],
        [ 'Other', 'NFI_Other' ],
        [ 'Earliest Date Linked to Property', 'NFI_Earliest_date_linked_to_property' ],
        [ 'Occupier Score', 'NFI_Occupier_Score' ],
      ]

      footprintTableOptions = {
        biggerSecondRow: false,
        title: 'Occupier Footprint'
      }

      render() {
        return (
          <div className="grid grid-gap-15" id="void-cot-alerts-page">
            <Navigation />
            <Container>
              <PageTitle title={ this.props.history.location.pathname.indexOf('void') !== -1 ? 'Void COT Alerts - NFI Data' : 'Occupier COT Alerts - NFI Data' } titleRight={
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Link className="navigate" to={this.props.history.location.pathname.indexOf('void') !== -1 ? '/portal/void-cot-alerts/' : '/portal/occupier-cot-alerts/'}>
                    To Overview
                  </Link>
                  {(hasPermission('VOID_COT', 'WORKFLOW')) ?
                    <Link className="navigate" to={this.props.history.location.pathname.indexOf('void') !== -1 ? '/portal/void-cot-alerts/workflow' : '/portal/occupier-cot-alerts/workflow'}>
                      To Workflow
                    </Link>
                    : null}
                  { (hasPermission('VOID_COT', 'WORKFLOW-COMBINED')) ?
                    <Link className="navigate" to={ this.props.history.location.pathname.indexOf('void') !== -1 ? '/portal/void-cot-alerts/workflow-combined' : '/portal/occupier-cot-alerts/workflow-combined' }>
                      Workflow Combined
                    </Link>
                  : null }
                  <Link className="navigate" to="/portal/void-cot-alerts/search">
                    To Search
                  </Link>
                  <Link className="navigate" to="/portal/void-cot-alerts/report">
                    To Report
                  </Link>
                </div>
            } />
            </Container>
            <Container>
              <div className="vcv">
                <div className="primary-grid">
                  <div className="left-grid">
                    <div className="data-row with-buttons">
                      <div className="data-row-title">
                        {this.state.translations ? this.state.translations['AccountNumber'] : ''}
                      </div>
                      <div style={{ position: 'relative'}}>
                        <input type="text" className="data-row-field" name="account-number" value={this.state.accountNumber} style={{ width: 'calc(100% - 15px)', height: '100%' }} onChange={this.accountNumberChange} onKeyPress={this.searchKeyDown} />
                        <span className="message">{ this.state.workflowIndex + 1 }/{ this.state.workflowLength }</span>
                      </div>
                      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '15px', marginLeft: '15px' }}>
                        <button className="button background-primary colour-white" onClick={ this.workflowPrev } disabled={this.state.workflowIndex === 0}>
                            <i className="fas fa-angle-left"></i>
                        </button>
                        <button className="button background-primary colour-white" onClick={ this.workflowNext } disabled={this.state.workflowIndex === this.state.workflowLength - 1}>
                            <i className="fas fa-angle-right"></i>
                        </button>
                    </div>
                    </div>
                    {this.state.customer && this.state.customer.Surname !== false ?
                      <div className="name-grid">
                        <div className="data-row">
                          <div className="data-row-title">
                            Title
                          </div>
                          <input type="text" className="data-row-field" disabled={true} value={this.state.customer ? `${this.state.customer.NFI_Title}` : ''} />
                        </div>
                        <div className="data-row">
                          <div className="data-row-title">
                            Forename
                          </div>
                          <input type="text" className="data-row-field" disabled={true} value={this.state.customer ? `${this.state.customer.NFI_Forename}` : ''} />
                        </div>
                        <div className="data-row">
                          <div className="data-row-title">
                            Surname
                          </div>
                          <input type="text" className="data-row-field" disabled={true} value={this.state.customer ? `${this.state.customer.NFI_Surname}` : ''} />
                        </div>
                      </div>
                      :
                      <div className="data-row">
                        <div className="data-row-title">
                          {this.state.translations ? this.state.translations['Name'] : ''}
                        </div>
                        <input type="text" className="data-row-field" disabled={true} value={this.state.customer ? `${this.state.customer.NFI_Title}` : ''} />
                      </div>
                    }
                    <div className="left-grid-bottom">
                      <div className="data-row">
                        <div className="data-row-title">
                          {this.state.translations ? this.state.translations['DateOfBirth'] : ''}
                        </div>
                        <input type="text" className="data-row-field" disabled={true} value={this.state.customer ? this.state.customer.Date_of_Birth : ''} />
                      </div>
                      <div className="data-row">
                        <div className="data-row-title">
                          {this.state.translations ? this.state.translations['Landline'] : ''}
                        </div>
                        <input type="text" className="data-row-field" disabled={true} value={this.state.customer ? this.state.customer.Landline : ''} />
                      </div>
                      <div className="data-row">
                        <div className="data-row-title">
                          {this.state.translations ? this.state.translations['Mobile'] : ''}
                        </div>
                        <input type="text" className="data-row-field" disabled={true} value={this.state.customer ? this.state.customer.Mobile : ''} />
                      </div>
                    </div>
                    <div className="data-row">
                      <div className="data-row-title">
                        {this.state.translations ? this.state.translations['ClientAddress'] : ''}
                      </div>
                      <input type="text" className="data-row-field" disabled={true} value={this.state.customer ? this.state.customer.SPID_Address : ''} />
                    </div>
                    <div className="data-row">
                      <div className="data-row-title">
                        {this.state.translations ? this.state.translations['MatchedAddress'] : ''}
                      </div>
                      <input type="text" className="data-row-field" disabled={true} value={this.state.customer ? this.state.customer.AddressBase_Address : ''} />
                    </div>
                    <div className="left-grid-bottom">
                      <div className="data-row">
                        <div className="data-row-title">
                          {this.state.translations ? this.state.translations['AddressScore'] : ''}
                        </div>
                        <input type="text" className="data-row-field" disabled={true} value={this.state.customer ? this.state.customer.Address_Quality : ''} />
                      </div>
                      <div className="data-row">
                        <div className="data-row-title">
                          {this.state.translations ? this.state.translations['UPRN'] : ''}
                        </div>
                        <input type="text" className="data-row-field" disabled={true} value={this.state.customer ? this.state.customer.UPRN : ''} />
                      </div>
                      <div className="data-row">
                        <div className="data-row-title">
                          {this.state.translations ? this.state.translations['occupier_band'] : ''}
                        </div>
                        <input type="text" className="data-row-field" disabled={true} value={this.state.customer ? this.state.customer.NFI_Occupier_Band : ''} />
                      </div>
                    </div>
                  </div>
                  <div className="right-grid">
                    <button className="search-button" onClick={this.getData}>
                      Search
                    </button>
                    {(!hasPermission('VOID_COT', 'PURCHASE') && this.state.purchasable) ?
                      <button className="buy-button disabled" disabled={true}>
                        Purchase Customer
                      </button>
                      :
                      <button className="buy-button" disabled={!this.state.purchasable} onClick={this.purchaseCustomer}>
                        {this.state.purchasable === true || this.state.purchasable === null ? "Purchase Customer" :
                          this.state.customer.Bought_By ?
                            `Purchased on ${this.state.customer.Date_Bought} by ${this.state.customer.Bought_By}`
                            :
                            this.state.customer.Date_Bought ?
                              `Purchased on ${this.state.customer.Date_Bought}`
                              :
                              'Unavailable for Purchase'
                        }
                      </button>
                    }
                    <div className="data-row">
                      <div className="data-row-title">
                        {this.state.translations ? this.state.translations['Email'] : ''}
                      </div>
                      <input type="text" className="data-row-field" disabled={true} value={this.state.customer ? this.state.customer.Email : ''} />
                    </div>
                    <div className="data-row">
                      <div className="data-row-title">
                        {this.state.translations ? this.state.translations['VoidAgeBand'] : ''}
                      </div>
                      <input type="text" className="data-row-field" disabled={true} value={this.state.customer ? this.state.customer.Voids_Age_Band : ''} />
                    </div>
                    <div className="data-row">
                      <div className="data-row-title">
                        {this.state.translations ? this.state.translations['AccountType'] : ''}
                      </div>
                      <input type="text" className="data-row-field" disabled={true} value={this.state.customer ? this.state.customer.Type : ''} />
                    </div>
                    <div className="data-row">
                      <div className="data-row-title">
                        {this.state.translations ? this.state.translations['Consumption'] : ''}
                      </div>
                      <input type="text" className="data-row-field" disabled={true} value={this.state.customer ? this.state.customer.Consumption : ''} />
                    </div>
                  </div>
                </div>
                <div className="tertiary-grid">
                  <div className="data-row">
                      <div className="data-row-title">
                          { this.state.translations ? this.state.translations['Bucket'] : '' }
                      </div>
                      <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? this.state.customer.Bucket : ''}/>
                  </div>
                  <div className="data-row">
                      <div className="data-row-title">
                          { this.state.translations ? this.state.translations['Freehold_Comp_Name'] : '' }
                      </div>
                      <input type="text" className="data-row-field" disabled={true} value={ this.state.customer ? this.state.customer.Freehold_Comp_Name  : '' }/>
                  </div>
                  <div className="data-row">
                      <div className="data-row-title">
                          { this.state.translations ? this.state.translations['Proprietor_Comp_Name'] : '' }
                      </div>
                      <input type="text" className="data-row-field" disabled={true} value={this.state.customer? this.state.customer.Proprietor_Comp_Name : '' }/>
                  </div>
                </div>
                <div className="secondary-grid">
                  <PropertyMap address={this.state.customer ? this.state.customer.SPID_Address : null} />
                  <GenericTable data={this.state.customer} rows={this.footprintTableLeft} {...this.footprintTableOptions} />
                  <GenericTable data={this.state.customer} rows={this.footprintTableRight} {...this.footprintTableOptions} />
                </div>
              </div>
            </Container>
            <HelpVideo video={session.company.vca_video_url} />
            <Footer />
          </div>
        )
      }
    }
  )
)

