import React, {useEffect, useState} from 'react'
import Navigation from '../../components/navigation/navigation'
import Footer from '../../components/footer/footer'
import Container from '../../components/container/container'
import request from '../../helpers/request';
import { matchPath, withRouter } from 'react-router-dom';
import ProductSelect from '../../components/product-select/productSelect'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Pagination from '../../components/pagination/pagination';

export default withRouter(({ ...props }) => {
  const match = matchPath(props.history.location.pathname, {
    path: '/admin/portal/companies/:company/translate',
  })
  const {params} = match;
  const [companyId] = useState(params.company);
  const [translations, setTranslations] = useState([]);
  const [page, setPage] = useState({
    page: 1,
    last_page: null
  });
  const [selectedProduct, setSelectedProduct] = useState()
  const [products, setProducts] = useState([])
  const [search, setSearch] = useState()
  const [suggestions, setSuggestions] = useState([])
  const [loadingSuggestions, setLoadingSuggestions] = useState(false)

  const loadProducts = React.useCallback(() => {
    request(true).get("/real-products", {
      params: {
        withTranslations: true
      }
    }).then(r => {
      setProducts(r.data);
    }).catch(e => {
        console.error(e);
    });
  })

  useEffect(() => {
    console.log(selectedProduct)
  }, [selectedProduct])

  function debounce(func, delay) {
    let debounceTimer;
    return function(...args) {
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func(...args), delay);
    };
}

  const loadTranslations = debounce((page = 1) => {
    if (selectedProduct === undefined) {
      setTranslations([]);
      setPage({
        page: 1,
        last_page: null
      });
      return;
    }
    const product = products.find(product => product.id === selectedProduct);
    if (!product && selectedProduct !== '') {
      setTranslations([]);
      return;
    }
    request(true).get(`/translate/${companyId}?key=${product ? product.product_key : ''}&search=${search ?? ''}&page=${page}`).then(r => {
      setTranslations(r.data.data);
      setPage({
        page: r.data.current_page,
        last_page: r.data.last_page
    });
      setLoadingSuggestions(false)
    }).catch(e => {
        setTranslations([]);
        setPage({
          page: 1,
          last_page: null
        });
        setLoadingSuggestions(false)
        console.error(e);
    });
  });

  useEffect(() => {
    loadTranslations()
  }, [companyId, selectedProduct, search])

  const onInputChange = e => {
    const trans = [].concat(translations);
    trans[e.target.name].override = e.target.value;
    setTranslations(trans);
  }

  const submit = () => {
    const data = {};

    for (const index in translations) {
      if (translations[index].override.trim() !== '') {
        data[translations[index].id] = translations[index].override.trim();
      }
    }

    const product = products.find(product => product.id === selectedProduct);

    request(true).post(`/translate/${companyId}?key=${product.product_key}`, { data }).then(r => {
      console.log(r);
    }).catch(e => {
        console.error(e);
      });
  }

  React.useEffect(() => {
    loadProducts()
    loadTranslations();
  }, []);

  return (
    <div className="grid grid-gap-20">
      <Navigation/>
      <Container>
        <div className="grid" style={{ gridGap: '15px', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr' }}>
          <ProductSelect product={selectedProduct} onChange={(e) => setSelectedProduct(e ? e.value : undefined)} />
          <Autocomplete
              value={search}
              onChange={(event, newValue) => {
                  setSearch(newValue ?? '')
              }}
              onInputChange={(event, newInputValue, reason) => {
                if (reason === "clear") {
                  setSearch('')
                }
              }}
              filterOptions={() => suggestions}
              handleHomeEndKeys
              id="Address-Search"
              options={suggestions}
              loading={loadingSuggestions}
              disabled={!selectedProduct && selectedProduct !== ''}
              getOptionLabel={(option) => {
                  return search
              }}

              renderOption={(props, option) => {
                  const { key, ...optionProps } = props;
                  return (
                      <li key={key} {...optionProps}>
                      </li>
                  );
              }}
              sx={{ width: 305, 
                  "& .MuiOutlinedInput-root": {
                      padding: 0, paddingRight: 8
                  }
              }}
              freeSolo
              renderInput={(params) => (
                  <TextField {...params} placeholder="Search translations" 
                    value={search ?? ''}
                      onChange={(event) => {
                        setSearch(event.target.value || '')
                        if (event.target.value !== '') {
                          setLoadingSuggestions(true)
                        }
                      }}
                      onKeyDown={(event) => {
                          if (event.key === 'Enter' && !document.querySelector('.MuiAutocomplete-popper li')) {
                              event.preventDefault();
                          }
                      }}
                  />
              )}
          />
        </div>
        <table className="table" style={{ marginTop: '15px' }}>
          <thead>
            <tr>
              <th>Key</th>
              <th>Default</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            { translations.map((item, index) => (
              <tr key={`translation-${index}`}>
                <td>
                  { item.key }
                </td>
                <td>
                  { item.default }
                </td>
                <td>
                  <input type="text" onChange={ onInputChange } name={index} value={item.override}/>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button style={{ width: '15%', marginTop: '1em' }} className="button background-primary colour-white" onClick={ submit }>
          Update
        </button>
        <Pagination page={ page.page } pages={ page.last_page } pageChange={ loadTranslations } style={{margin: 'auto'}}/>
      </Container>
      <Footer/>
    </div>
  )
})
