import React, {useState, useEffect} from 'react'
import endpoints from '../../../helpers/endpoints';
import request from '../../../helpers/request';
import hasPermission from '../../../helpers/permissions';

export function GenericTable({ 
    data, rows, title, 
    extraClass, style={}, evidence = true, 
    subtitle = 'Evidence', align, 
    firstColWidth, biggerSecondRow = true,
    leftOfTitleContent, openTwilioModal = null,
    loading
 }) {
  return (
    <table className={`table borders ${extraClass}`} style={{ height: '100%', ...style }}>
      <thead>
        <tr>
            {
                leftOfTitleContent ? <>
                    <th>
                        {leftOfTitleContent}
                    </th>
                </> : null
            }
          <th style={{textAlign: leftOfTitleContent ? 'center' : 'start' }} colSpan={evidence ? 1 : 2}>
            {title}
          </th>
          {
            evidence ? <>
              <th>
                {subtitle}
              </th>
            </> : null
          }
        </tr>
      </thead>
      <tbody>
        {
          rows.map((row, i) => {
            const Value = row[1]
            const LeftButtonValue = row[0]

            if (row[0] === "Phone") {
                return (
                <tr key={`gtable_${Math.random()}_${i}`}>
                    <td style={{ height: biggerSecondRow ? i === 1 ? 30 : undefined : undefined, textAlign: align, width: i === 0 ? firstColWidth || undefined : undefined }}>{row[0]}</td>
                    <td style={{ minWidth: evidence ? 'unset' : 60 }}>{data ? (typeof Value === 'string') ?  data[Value] : <Value data={data}></Value> : null}</td>
                </tr>
                )
            } else {
                return (
                  <tr key={`gtable_${Math.random()}_${i}`}>
                    <td style={{ height: biggerSecondRow ? i === 1 ? 30 : undefined : undefined, textAlign: align, width: i === 0 ? firstColWidth || undefined : undefined }}>{typeof row[0] === "function" ? <LeftButtonValue data={data}></LeftButtonValue> : row[0]}</td>
                    <td style={{ minWidth: evidence ? 'unset' : 60, textAlign: loading ? 'center' : 'unset' }}>
                        {
                            loading && i === 0 ?
                                <i className="fa fa-spinner fa-spin"></i>
                            :
                              data ? (typeof Value === 'string') ?  data[Value] ? data[Value] : '' : <Value data={data}></Value> : null
                        }
                    </td>
                  </tr>
                )
            }
          })
        }
      </tbody>
    </table>
  )
}

export function DVSAEvidence({data, openTwilioModal}) {
    const rows = [['Company', 'DVSA_Name'],['Address', 'DVSA_Address'],['Phone', 'DVSA_Phone']];
    return (
        <GenericTable data={data} rows={rows} title="DVSA" openTwilioModal={openTwilioModal} />
    )
}

export function CharityCommisionEvidence({data, openTwilioModal}) {
    const rows = [['Name', 'CC_Name'],['Address', 'CC_Address'],['Phone', 'CC_Phone']];
    return (
        <GenericTable data={data} rows={rows} title="Trip Advisor" openTwilioModal={openTwilioModal} />
    )
}

export function BTDirectoryEvidence({data, openTwilioModal}) {
    const rows = [['Company', 'BT_Name'],['Address', 'BT_Address'],['Phone', 'BT_Phone']];
    return (
        <GenericTable data={data} rows={rows} title="BT Directory" openTwilioModal={openTwilioModal} />
    )
}

export function FoodStandardsEvidence({data, openTwilioModal}) {
    const rows = [['Company', 'FSA_Name'],['Address', 'FSA_Address'],['Rating Date', 'FSA_Rating_Date']];
    return (
        <GenericTable data={data} rows={rows} title="Food Standards Agency" openTwilioModal={openTwilioModal} />
    )
}

export function GoogleEvidence({data, openTwilioModal}) {
    const rows = [['Company','Google_Company'],['Address','Google_Address'],['Business Status', 'Google_Status'],['Phone','Google_Phone'],['First Review Date','Google_First_Date'],['Last Review Date','Google_Date']];
    return (
        <GenericTable data={data} rows={rows} title="Google" openTwilioModal={openTwilioModal} />
    )
}

export function CompaniesHouseEvidence({data, openTwilioModal}) {
    const rows = [['Company','Comp_Hse_Company'],['Address','Comp_Hse_Address'],['Business Status','Comp_Hse_Status'],['SIC','Comp_Hse_SIC'],['Incorporated Date','Comp_Hse_Inc_Date'],['Last Statement Date','Comp_Hse_LS_Date']];
    return (
        <GenericTable data={data} rows={rows} title="Companies House" openTwilioModal={openTwilioModal} />
    )
}

export function YellComEvidence({data, openTwilioModal}) {
    const rows = [['Company','Yell_Company'],['Address','Yell_Address'],['Business Status','Yell_Status'],['Phone','Yell_Phone'],['Website','Yell_Website'],['Email','Yell_Email']];
    return (
        <GenericTable data={data} rows={rows} title="Yell.Com" openTwilioModal={openTwilioModal} />
    )
}

export function AddressEvidence({data, openTwilioModal}) {
    const rows = [['Company','AD_Name'],['UPRN Address','AddressBase_Address'],['UPRN','UPRN'],['VOA Number','AD_VOA_No'],['VOA End Date','AD_VOA_End'],['CT Start Date','AD_CT_Start']];
    return (
        <GenericTable data={data} rows={rows} title="Address Data" openTwilioModal={openTwilioModal} />
    )
}

export function CQCEvidence({data, openTwilioModal}) {
    const rows = [['Company', 'CQC_Name'],['Address', 'CQC_Address'],['Phone', 'CQC_Phone']];
    return (
        <GenericTable data={data} rows={rows} title="Quality Care Comm" openTwilioModal={openTwilioModal} />
    )
}

export function JustEatEvidence({data, openTwilioModal}) {
    const rows = [['Company', 'JE_Company'],['Address', 'JE_Address'],['Phone', 'JE_Phone']];
    return (
        <GenericTable data={data} rows={rows} title="Just Eat" openTwilioModal={openTwilioModal} />
    )
}

export function One92Evidence({data, openTwilioModal}) {
    const rows = [['Company', 'One92_Company'],['Address', 'One92_Address'],['Phone', 'One92_Phone']];
    return (
        <GenericTable data={data} rows={rows} title="192.com" openTwilioModal={openTwilioModal} />
    )
}

export function FacebookEvidence({data, openTwilioModal}) {
    const rows = [['Company', 'FB_Company'],['Address', 'FB_Address'],['Phone', 'FB_Phone']];
    return (
        <GenericTable data={data} rows={rows} title="Facebook" openTwilioModal={openTwilioModal} />
    )
}

export function TitleOwnerRow({ state }) {
    return (
        <div style={{gridColumnStart: 1, gridColumnEnd: 3}}>
            <div className="left-grid-bottom" style={{display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 15}}>
                <div className="data-row">
                    <div className="data-row-title">
                    { state.translations ? state.translations['title-number-owner'] : '' }
                    </div>
                    <input type="text" className="data-row-field" disabled={true} value={ state.customer ? state.customer.LR_Name_No ? `${state.customer.LR_Name_No}` : '' : '' }/>
                </div>
                <div className="data-row">
                    <div className="data-row-title">
                    { state.translations ? state.translations['title-owner-address'] : '' }
                    </div>
                    <input type="text" className="data-row-field" disabled={true} value={ state.customer ? state.customer.LR_Address ? `${state.customer.LR_Address}` : '' : '' }/>
                </div>
            </div>
        </div>
    )
}

export function LeftColumn({ state, children }) {
    return (
        <>
            {
                children
            }
            <div className="data-row">
                <div className="data-row-title">
                    { state.translations ? state.translations['client_address'] : '' }
                </div>
                <input type="text" className="data-row-field" disabled={true} value={ state.customer ? state.customer.SPID_Address : '' }/>
            </div>
            <div className="data-row">
                <div className="data-row-title">
                    { state.translations ? state.translations['matched_address'] : '' }
                </div>
                <div style={{display: 'grid', cursor: 'pointer'}} onClick={(e) => {window.blur(); if (state.customer?.AddressBase_Address) window.open(`https://www.google.com/maps/search/?api=1&query=${state.customer ? state.customer.AddressBase_Address : ''}`)}} >
                    <input style={{cursor: 'pointer'}} disabled={true} type="text" className="data-row-field colour-blue" value={ state.customer ? state.customer.AddressBase_Address : '' }/>
                </div>
            </div>
            <div className="left-grid-bottom">
            <div className="data-row" style={{gridColumnStart: 1, gridColumnEnd: 3}}>
                    <div className="data-row-title">
                    { state.translations ? state.translations['company_name'] : '' }
                    </div>
                    <input type="text" className="data-row-field" disabled={true} value={ state.customer ? state.customer.Company_Name ? `${state.customer.Company_Name}` : '' : '' }/>
                </div>
                <div className="data-row">
                    <div className="data-row-title">
                    { state.translations ? state.translations['company_number'] : '' }
                    </div>
                    <input type="text" className="data-row-field" disabled={true} value={ state.customer ? state.customer.Company_Number ? `${state.customer.Company_Number}` : '' : '' }/>
                </div>
            </div>
            <div className="left-grid-bottom"> 
                <div className="data-row">
                    <div className="data-row-title">
                    { state.translations ? state.translations['website'] : '' }
                    </div>
                    <input type="text" className="data-row-field" disabled={true} value={ state.customer ? state.customer.Website ? `${state.customer.Website}` : '' : '' }/>
                </div>
                <div className="data-row">
                    <div className="data-row-title">
                    { state.translations ? state.translations['landline'] : '' }
                    </div>
                    <input type="text" className="data-row-field" disabled={true} value={ state.customer ? state.customer.Landline ? `${state.customer.Landline}` : '' : '' }/>
                </div>
                <div className="data-row">
                    <div className="data-row-title">
                    { state.translations ? state.translations['mobile'] : '' }
                    </div>
                    <input type="text" className="data-row-field" disabled={true} value={ state.customer ? state.customer.Mobile ? `${state.customer.Mobile}` : '' : '' }/>
                </div>
            </div>
            <div className="data-row">
                <div className="data-row-title">
                    { state.translations ? state.translations['registered_address'] : '' }
                </div>
                <input type="text" className="data-row-field" disabled={true} value={ state.customer ? state.customer.Registered_Address : '' }/>
            </div>
            <div className="left-grid-bottom">
                <div className="data-row">
                    <div className="data-row-title">
                        { state.translations ? state.translations['address_score'] : '' }
                    </div>
                    <input type="text" className="data-row-field" disabled={true} value={ state.customer ? state.customer.Address_Score : ''}/>
                </div>
                <div className="data-row">
                    <div className="data-row-title">
                        { state.translations ? state.translations['uprn'] : '' }
                    </div>
                    <input type="text" className="data-row-field" disabled={true} value={ state.customer ? state.customer.UPRN : '' }/>
                </div>
                <div className="data-row">
                    <div className="data-row-title">
                        { state.translations ? state.translations['sic_code'] : '' }
                    </div>
                    <input type="text" className="data-row-field" disabled={true} value={state.customer? state.customer.SIC_Code : '' }/>
                </div>
            </div>
        </>
    )
}

export function RightColumn({ state, children, setWholesaler }) {
    return (
        <div className="right-grid">
            {
                children
            }
            {/* <div className="data-row">
                <div className="data-row-title">
                    { state.translations ? state.translations['type'] : '' }
                </div>
                <input type="text" className="data-row-field" disabled={true} value={state.customer ? state.customer.Type : ''}/>
            </div> */}
            <div className="data-row">
                <div className="data-row-title">
                    { state.translations ? state.translations['void_age_band'] : '' }
                </div>
                <input type="text" className="data-row-field" disabled={true} value={ state.customer ? state.customer.Void_Age_Band : '' }/>
            </div>
            <div className="data-row">
                <div className="data-row-title">
                    Assigned to
                </div>
                <input type="text" className="data-row-field" disabled={true} value={ state.customer ? state.customer.assigned_to : '' }/>
            </div>
            {
                state.workflow ? 
                    <div className="data-row">
                        <div className="data-row-title">
                            { state.translations ? state.translations['Wholesaler'] : '' }
                        </div>
                        <select style={{height: 28, border: '1px solid var(--primary)', borderRadius: 5}} onChange={setWholesaler} value={state.selectedWholesaler ? state.selectedWholesaler : state.customer?.Wholesaler ?? "New"}>
                            <option value=''>All Wholesalers</option>
                            {
                                state.wholesalers.map(wholesaler => {
                                    return (
                                        <option value={wholesaler.value}>{wholesaler.label}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                :
                    <div className="data-row">
                        <div className="data-row-title">
                            { state.translations ? state.translations['Wholesaler'] : '' }
                        </div>
                        <input type="text" className="data-row-field" disabled={true} value={ state.customer ? state.customer.Wholesaler : '' }/>
                    </div>
            }
            <div className="data-row">
                <div className="data-row-title">
                    { state.translations ? state.translations['occupier_score'] : '' }
                </div>
                <input type="text" className="data-row-field" disabled={true} value={ state.customer ? state.customer.Occupier_Score : '' }/>
            </div>
        </div>
    )
}

export function NotesRow({ state, notesEndpoint, outcomeEndpoint, issueToEndpoint, setNotes, showNotes=true, extraWorkflowStatuses }) {
    const [retailerNotes, setRetailerNotes] = useState(state.customer?.retailerNotes ?? '')
    const [wholesalerNotes, setWholesalerNotes] = useState(state.customer?.wholesalerNotes ?? '')
    const [retOutcome, setRetOutcome] = useState(state.customer?.Ret_Outcome)
    const [wsOutcome, setWsOutcome] = useState(state.customer?.WS_Outcome)
    const [wsNoteIndex, setWsNoteIndex] =  useState(state.notes?.filter(note => note.Note_Type === "Wholesaler").length)
    const [retNoteIndex, setRetNoteIndex] =  useState(state.notes?.filter(note => note.Note_Type === "Retailer").length)
    const [prevWsNotes, setPrevWsNotes] =  useState(state.notes?.filter(note => note.Note_Type === "Wholesaler"))
    const [prevRetNotes, setPrevRetNotes] =  useState(state.notes?.filter(note => note.Note_Type === "Retailer"))

    const updateOutcome = () => {
        request(true).post(outcomeEndpoint, {
            SPID: state.customer?.SPID ?? state.customer?.Core_SPID,
            WS_Outcome: wsOutcome,
            Ret_Outcome: retOutcome
        }).then(e => {
            // window.alert(e.data);
        }).catch(e => {
            console.log(e)
            window.alert("Update failed.")
        })
    }

    useEffect(() => {
        if ((retOutcome && retOutcome !== state.customer?.Ret_Outcome) || (wsOutcome && wsOutcome !== state.customer?.WS_Outcome)) {
          updateOutcome()
        }
      }, [retOutcome, wsOutcome])

    useEffect(() => {
        setRetailerNotes(state.customer?.retailerNotes ?? '')
        setWholesalerNotes(state.customer?.wholesalerNotes ?? '')
        setRetOutcome(state.customer?.Ret_Outcome)
        setWsOutcome(state.customer?.WS_Outcome)
        setWsNoteIndex(state.notes?.filter(note => note.Note_Type === "Wholesaler").length ?? 0)
        setRetNoteIndex(state.notes?.filter(note => note.Note_Type === "Retailer").length ?? 0)
        setPrevWsNotes(state.notes?.filter(note => note.Note_Type === "Wholesaler") ?? '')
        setPrevRetNotes(state.notes?.filter(note => note.Note_Type === "Retailer") ?? '')
    }, [state.customer?.SPID, state.notes])

    
    const submitNotes = (noteType) => {
        request(true).post(notesEndpoint, {
            SPID: state.customer?.SPID,
            notes: noteType === "Wholesaler" ? wholesalerNotes : retailerNotes,
            noteType: noteType
          }).then(e => {
            // window.alert(e.data);
            setNotes(e.data)
            window.alert("Note submitted")
          }).catch(e => {
            console.log(e)
            window.alert("Update failed.")
          })
    }

    const issueToUser = (userType) => {
        request(true).post(issueToEndpoint, {
            SPID: state.customer?.SPID ?? state.customer?.Core_SPID,
            userType: userType,
        }).then(e => {
            // window.alert(e.data);
            window.alert("Submitted to " + userType)
        }).catch(e => {
            console.log(e)
            window.alert("Update failed.")
        })
    }

    return (
        <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumn: '1/3', gridGap: '15px'}}>
            {/* <div style={{display: 'grid', gridGap: '15px'}}>
                <div className="data-row">
                    <div className="data-row-title">
                        { state.translations ? state.translations['Retailer'] : '' }
                    </div>
                    <input type="text" className="data-row-field" disabled={true} value={ state.customer ? state.customer.Retailer_ID : '' }/>
                </div>
                <div className="data-row">
                    <div className="data-row-title">
                        { state.translations ? state.translations['Retailer_Assessment'] : '' }
                    </div>
                    <select disabled={!hasPermission('VACANT_COT', 'RETAILER')} value={retOutcome ? retOutcome : state?.customer?.Ret_Outcome ?? 'New'} onChange={(event) => setRetOutcome(event.target.value)}>
                        <option value="New">New</option>
                        <option value="In Progress">In Progress</option>
                        <option value="Site Visit">Site Visit</option>
                        <option value="Issued to Retailer">Issued to Retailer</option>
                    </select>
                </div>
            </div>
            <div style={{display: 'grid', gridGap: '15px'}}>
                <div className="data-row">
                    <div className="data-row-title">
                        { state.translations ? state.translations['Wholesaler'] : '' }
                    </div>
                    <input type="text" className="data-row-field" disabled={true} value={ state.customer ? state.customer.Wholesaler : '' }/>
                </div>
                <div className="data-row">
                    <div className="data-row-title">
                        { state.translations ? state.translations['Wholesaler_Assessment'] : '' }
                    </div>
                    <select disabled={!hasPermission('VACANT_COT', 'WHOLESALER')} value={wsOutcome ? wsOutcome : state?.customer?.wsOutcome ?? 'New'} onChange={(event) => setWsOutcome(event.target.value)}>
                        <option value="New">New</option>
                        <option value="In Progress">In Progress</option>
                        <option value="Previous Occupier">Previous Occupier</option>
                        <option value="Unable to Validate Occupier">Unable to Validate Occupier</option>
                        <option value="Occupier Moved In">Occupier Moved In</option>
                        { extraWorkflowStatuses && extraWorkflowStatuses.map(status => {
                            return (
                                <option value={status.value}>{status.label}</option>
                            )
                        }) }
                    </select>
                </div>
            </div> */}
            { showNotes && <>
                <div style={{height: '100%', width: '100%'}}>
                    <div style={{position: 'relative'}}>
                        <textarea disabled={!hasPermission('VACANT_COT', 'RETAILER')} onChange={(event) => setRetailerNotes(event.target.value.slice(0, 500))} value={retailerNotes ?? ''} rows={7} style={{height: '100%', width: '100%', resize: 'none'}}/>
                        <div style={{ position: 'relative', bottom: '3px', float: 'right', color: 'gray', fontSize: 12 }}>
                            {retailerNotes.length}/500
                        </div>
                        <textarea disabled={true} value={prevRetNotes[retNoteIndex - 1]?.Note ?? ''} rows={7} style={{height: '100%', width: '100%', resize: 'none'}}/>
                        <div style={{ position: 'relative', bottom: '3px', float: 'right', color: 'gray', fontSize: 12, display: 'flex', gap: 10, alignItems: 'center' }}>
                            {retNoteIndex}/{prevRetNotes.length} <i class="fa-solid fa-chevron-left" style={{ cursor: retNoteIndex !== 1 && retNoteIndex !== 0  ? 'pointer' : 'unset' }}  onClick={() => setRetNoteIndex(retNoteIndex !== 1 ? retNoteIndex - 1 : retNoteIndex)} /> <i class="fa-solid fa-chevron-right" style={{ cursor: retNoteIndex !== prevRetNotes.length ? 'pointer' : 'unset' }} onClick={() => setRetNoteIndex(retNoteIndex !== prevRetNotes.length ? retNoteIndex + 1 : retNoteIndex)} />
                        </div>
                        <div style={{ position: 'relative', bottom: 5, left: 5, color: 'gray', fontSize: 12, display: 'flex', gap: 10, alignItems: 'center', minHeight: '3vh' }}>
                            { prevRetNotes.length > 0 ?
                                    prevRetNotes[retNoteIndex - 1]?.User_Name + " - " + 
                                    new Date(prevRetNotes[retNoteIndex - 1]?.Date).toLocaleString('en-GB', { timeZone: 'Europe/London', hour12: false }).replace(',', '').replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$2-$1')
                            : ''}
                        </div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'flex-end', gap: '10px'}}>
                        <button 
                                disabled={!hasPermission('VACANT_COT', 'RETAILER')} 
                                className={`button compact smaller-text ${hasPermission('VACANT_COT', 'RETAILER') ? 'background-primary colour-white' : ''}`}
                                onClick={() => issueToUser('Wholesaler')}
                            >Issue to Wholesaler</button>
                        <button 
                            disabled={!hasPermission('VACANT_COT', 'RETAILER')  || retailerNotes.length === 0} 
                            className={`button compact smaller-text ${hasPermission('VACANT_COT', 'RETAILER') && retailerNotes.length > 0 ? 'background-primary colour-white' : ''}`}
                            onClick={() => submitNotes('Retailer')}
                        >Submit Notes</button>
                    </div>
                </div>
                <div style={{height: '100%', width: '100%'}}>
                    <div style={{position: 'relative'}}>
                        <textarea disabled={!hasPermission('VACANT_COT', 'WHOLESALER')} onChange={(event) => setWholesalerNotes(event.target.value.slice(0, 500))} value={wholesalerNotes ?? ''} rows={7} style={{height: '100%', width: '100%', resize: 'none'}}/>
                        <div style={{ position: 'relative', bottom: '3px', float: 'right', color: 'gray', fontSize: 12 }}>
                            {wholesalerNotes.length}/500
                        </div>
                        <textarea disabled={true} value={prevWsNotes[wsNoteIndex - 1]?.Note  ?? ''} rows={7} style={{height: '100%', width: '100%', resize: 'none'}}/>
                        <div style={{ position: 'relative', bottom: '3px', float: 'right', color: 'gray', fontSize: 12, display: 'flex', gap: 10, alignItems: 'center' }}>
                            {wsNoteIndex}/{prevWsNotes.length} <i class="fa-solid fa-chevron-left" style={{ cursor: wsNoteIndex !== 1 && wsNoteIndex !== 0 ? 'pointer' : 'unset' }} onClick={() => setWsNoteIndex(wsNoteIndex !== 1 && wsNoteIndex !== 0 ? wsNoteIndex - 1 : wsNoteIndex)} /> <i class="fa-solid fa-chevron-right" style={{ cursor: wsNoteIndex !== prevWsNotes.length ? 'pointer' : 'unset' }} onClick={() => setWsNoteIndex(wsNoteIndex !== prevWsNotes.length ? wsNoteIndex + 1 : wsNoteIndex)} />
                        </div>
                        <div style={{ position: 'relative', bottom: 5, left: 5, color: 'gray', fontSize: 12, display: 'flex', gap: 10, alignItems: 'center', minHeight: '3vh' }}>
                            { prevWsNotes.length > 0 ?
                                    prevWsNotes[wsNoteIndex - 1]?.User_Name + " - " + 
                                    new Date(prevWsNotes[wsNoteIndex - 1]?.Date).toLocaleString('en-GB', { timeZone: 'Europe/London', hour12: false }).replace(',', '').replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$2-$1')
                            : ''}
                        </div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'flex-end', gap: '10px'}}>
                        <button 
                            disabled={!hasPermission('VACANT_COT', 'WHOLESALER')} 
                            className={`button compact smaller-text ${hasPermission('VACANT_COT', 'WHOLESALER') ? 'background-primary colour-white' : ''}`}
                            onClick={() => issueToUser('Retailer')}
                        >Issue to Retailer</button>
                        <button 
                            disabled={!hasPermission('VACANT_COT', 'WHOLESALER') || wholesalerNotes.length === 0} 
                            className={`button compact smaller-text ${hasPermission('VACANT_COT', 'WHOLESALER') && wholesalerNotes.length > 0 ? 'background-primary colour-white' : ''}`}
                            onClick={() => submitNotes('Wholesaler')}
                        >Submit Notes</button>
                    </div>
                </div>
            </>}
        </div>
    )
}

// const MapsLink = ({ children, address, className }) => {
//     const [center, setCenter] = React.useState({});
//     const [reload, setReload] = React.useState(false)
//     const [current, setCurrent] = React.useState('')

//     const newChild = useMemo(() => {
//         const child = React.cloneElement(children, {
//             key: new Date().getTime(),
//             style: {
//                 cursor: 'pointer'
//             },
//             onClick: () => alert('open')
//         });
//         // child.style.cursor = 'pointer';
//         return child
//     }, [children])

//     const {isLoaded, loadError} = useLoadScript({
//         googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY
//     })

//     React.useEffect(() => {
//         if (isLoaded && (address !== current)) {
//             updateMap()
//             setCurrent(address)
//         }
//     }, [address, isLoaded])

//     const updateMap = () => {
//         setReload(true)
//         const geocoder = new window.google.maps.Geocoder();

//         geocoder.geocode({address: address}, (res, status) => {
//             if (status === "OK") {
//                 setCenter(res[0].geometry.location);
//                 setReload(false)
//             }
//         });
//     }

//     return (
//         !reload ? isLoaded ? newChild : children : children
//     )
// }
