import React, { useEffect, useCallback } from 'react'
import { useState } from 'react'
import GenericPageContainer from '../../../GenericPageElements/GenericPageContainer'
import endpoints from '../../../helpers/endpoints';
import hasPermission from '../../../helpers/permissions';
import request from '../../../helpers/request';
import {
  CompanyInfo,
  GoogleEvidence,
  CompaniesHouseEvidence,
  YellEvidence,
  AddressDataEvidence,
  FSAEvidence,
  BTEvidence,
  TripAdvisorEvidence,
  DVSAEvidence,
  CQCEvidence, 
  JustEatEvidence, 
  One92Evidence, 
  FacebookEvidence,
  TitleOwnerRow
} from './shared'
import Select from 'react-select'
import GenericDataRow, { GenericDataRowTitle } from '../../../GenericPageElements/GenericDataRow';
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from 'react-router-dom';
import session from '../../../stores/session';
import PropertyMap from '../../../components/property-map/property-map'
import ReactModal from 'react-modal'

export default function OccupierValidationSearch() {
  const [SPID, setSPID] = useState('')
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState('O')
  const [translations, setTranslations] = useState({})
  const [exclusionReason, setExlusionReason] = useState('')
  useEffect(() => {
    request(true).get('/translate?key=occupier_validation')
    .then(e => {
      setTranslations(e.data)
    })
    }, [])
  const getData = useCallback(() => {
    if (!SPID) return;
    setLoading(true)
    setData([])
    setExlusionReason('')
    request(true).get(endpoints.OCCUPIED_VALIDATION_LOOKUP, {
      params: {
        SPID
      }
    }).then(e => {
      setLoading(false)
      setData(e.data)
    }).catch(e => {
      setLoading(false)
      setData({})
      window.alert('Could not find SPID.')
    })
  }, [SPID]) 
  const purchase = () => {
    request(true).post(endpoints.OCCUPIER_VALIDATION_PURCHASE, {
      spid: SPID
    }).then(e => {
      setSPID('')
      setData({})
      setExlusionReason('')
    }).catch(() => {
      console.log('Unable to purchase')
    })
  }
  const exclude = () => {
    request(true).post(endpoints.OCCUPIER_VALIDATION_EXCLUDE, {
      spid: SPID,
      Occupier_Exclusion_Reason: exclusionReason
    }).then(e => {
      setSPID('')
      setData({})
      setExlusionReason('')
    }).catch(() => {
      console.log('Unable to exclude')
    })
  }
  const updateStatus = (event) => {
    if (!SPID) return 0
    request(true).post(`${endpoints.OCCUPIER_VALIDATION_UPDATE_STATUS}`, {
      customer: data['SPID'],
      status: event.target.value
    }).then(r => {
      setData(r.data)
    }).catch(e => {
      console.log(e);
    })
  }
  return (
    <GenericPageContainer title="Occupier Validation - Search"
    titleRight={<div>
        {
          loading ? <CircularProgress size={22} /> : null
        }
        <Link className="navigate" to="/portal/occupier-validation">
            To Overview
        </Link>
        { (hasPermission('OCCUPIER_VALIDATION', 'WORKFLOW')) ?
            <Link className="navigate" to="/portal/occupier-validation/workflow">
                To Workflow
            </Link>
        : null }
        { (hasPermission('OCCUPIER_VALIDATION', 'DASHBOARD')) ?
            <Link className="navigate" to="/portal/occupier-validation/dashboard">
              To Dashboard
            </Link>
        : null }
      </div>}>
      <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
        <div style={{display: 'grid', gridTemplateColumns: '2fr 1fr 1fr', gap: 10, alignItems: 'center'}}>
          <GenericDataRow title={translations['spid']} contentColour={'var(--white)'}>
            <input disabled={loading} style={{width: '100%', outline: 'none', border: 'none', backgroundColor: 'var(--white)'}} value={SPID} onChange={e => setSPID(e.target.value)} />
          </GenericDataRow>
          <button onClick={getData} className='navigate' style={{marginLeft: 0}}>Search</button>
          <button onClick={purchase} className='navigate' style={{marginLeft: 0}}>Occupier Moved Out</button>
        </div>
        <CompanyInfo translations={translations} data={data} mode={mode} setMode={setMode} updateStatus={updateStatus} right={<>
          {/* <button onClick={purchase} className='navigate' style={{marginLeft: 0}}>Occupier Moved Out</button> */}
          <select style={{height: 28, border: '1px solid var(--primary)', borderRadius: 5}} onChange={_ => setExlusionReason(_.target.value)} value={exclusionReason}>
            <option value={''}>Select an exclusion reason</option>
            <option>No COT: Current Occupier</option>
            <option>No COT: Name Correction</option>
            <option>No COT: Now Vacant</option>
            <option>Multiple Occupier/LL Query</option>
            <option>Address/Data Quality Issue</option>
            <option>Previous Occupier Provided</option>
            <option>Adjacent Occupier Provided</option>
            <option>De-registration</option>
            <option>Other</option>
          </select>
        </>} />
        <TitleOwnerRow translations={translations} data={data} mode={mode} />
        <div style={{display: 'grid', gridAutoColumns: '1fr', gridAutoFlow: 'column', gap: 10}}>
          <GoogleEvidence data={data} mode={mode} />
          <CompaniesHouseEvidence data={data} mode={mode} />
          <YellEvidence data={data} mode={mode} />
          <AddressDataEvidence data={data} mode={mode} />
        </div>
        <div style={{display: 'grid', gridAutoColumns: '1fr', gridAutoFlow: 'column', gap: 10}}>
          <FSAEvidence data={data} mode={mode} />
          <BTEvidence data={data} mode={mode} />
          <TripAdvisorEvidence data={data} mode={mode} />
          <DVSAEvidence data={data} mode={mode} />
        </div>
        <div style={{display: 'flex', flexDirection: 'row', gap: '.5em', height: '100%'}}>
          <CQCEvidence data={data} mode={mode} />
          <JustEatEvidence data={data} mode={mode} />
          <One92Evidence data={data} mode={mode} />
          <FacebookEvidence data={data} mode={mode} />
        </div>
      </div>

      <div className="secondary-grid" style={{gridTemplateColumns: '1fr 3fr', gridGap: '15px', gap: '15px', display: 'grid', minHeight: '300px'}}>
            <table className="table borders squish super-small-text extra-row-padding">
              <thead>
                  <tr>
                      <th style={{textAlign: 'left'}}>
                          Days
                      </th>
                      <th>
                          Opening Times
                      </th>
                  </tr>
              </thead>
              <tbody>
                {
                    ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map(day => {
                        return (
                            <tr>
                                <td style={{textAlign: "left", width: "100px"}}>{ day }</td>
                                <td style={{minWidth: "unset"}}>{ data?.[mode + "_Google_Opening_Hours"] ? JSON.parse(data?.[mode + "_Google_Opening_Hours"].replaceAll('\'', '"'))[day] : null }</td>
                            </tr>
                        )
                    })
                }
            </tbody>
          </table>
          <div style={{height: 'auto'}}>
              <PropertyMap address={ data ? data.SPID_Address : null } />
          </div>
      </div>
      <ReactModal 
      className="card bulk-buy"
      isOpen={exclusionReason !== ''}>
          <p>You have selected the following exclusion reason</p>
          <p>{exclusionReason}</p>
          <div className='grid grid-columns-2 grid-gap-5'>
              <button className='button compact background-red colour-white' onClick={() => setExlusionReason('')}>Cancel</button>
              <button className='button compact background-primary colour-white' onClick={() => exclude()}>Confirm</button>
          </div>
      </ReactModal>
    </GenericPageContainer>
  )
}