import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ProductDataFilter from "../../../components/product-data-filter/product-data-filter";
import GenericDataChart from "../../../GenericPageElements/GenericDataChart";
import GenericPageContainer from "../../../GenericPageElements/GenericPageContainer";
import GenericProductFilterContainer from "../../../GenericPageElements/GenericProductFilterContainer";
import endpoints from "../../../helpers/endpoints";
import hasPermission from '../../../helpers/permissions';
import request from "../../../helpers/request";
import S3Modal from "../../../components/s3Modal/S3Modal";
import session from "../../../stores/session";
import GenericDataRow from "../../../GenericPageElements/GenericDataRow";
import { view } from "@risingstack/react-easy-state";
import { Link } from "react-router-dom";
import BulkBuy from '../../../components/cot-alert-bulk-buy/bulkBuy';
import BulkAllocate from '../../../components/cot-alert-bulk-allocate/bulkAllocate';
import { CustomizedAxisTickMultiLine } from "../CompaniesHouseAlerts/CompaniesHouseAlerts";
import Dropzone from '../../../components/dropzone/dropzone'
import ReactModal from 'react-modal'
import Axios from "axios";
import './LongUnreadMeters.scss';

export default view(function LongUnreadMetersSummary() {
  const [filters, setFilters] = useState({});
  const [selectedGroupBy, setSelectectGroupBy] = useState("Connection_Type");
  const [data, setData] = useState(null);
  const [dates, setDates] = useState([]);
  const [date, setDate] = useState(null);
  const [S3Model, setS3Model] = useState(null);
  const [bulkModel, setBulkModel] = useState('')
  
  const applyFilter = (column, value) => {
    setFilters((current) => {
      return {
        ...current,
        [column]: value,
      };
    });
  };

  useEffect(() => {
    setDate("All_Refreshes");
  }, []);

  useEffect(() => {
    if (!date) return;
    request(true)
      .get(endpoints.LONG_UNREAD_METERS_DATA, {
        doesCancel: true,
        params: {
          date: date,
          filter: filters,
          group: selectedGroupBy,
        },
      })
      .then((e) => {
        setData(e.data);
      });
  }, [filters, selectedGroupBy, date]);

  return [
    <GenericPageContainer
      title="Long Unread Meters - Summary"
      titleIconClass={"fak fa-id-business-icon fa-2x colour-primary"}
      containerWidth={"92vw"}
      titleRight={
        <div>
          {hasPermission('LONG_UNREAD_METERS', 'DOWNLOAD') && (session.wholesaler ? session.wholesaler === "SEVERN-W"  : true)  ? (
            <button className="navigate" onClick={() => setBulkModel("cds-upload")}>
              CDS File Upload
            </button>
          ) : null}
          <Link className="navigate" to="/portal/long-unread-meters/search">
              To Search
          </Link>
          { (hasPermission('LONG_UNREAD_METERS', 'WORKFLOW')) ?
            <Link className="navigate" to="/portal/long-unread-meters/workflow">
                To Workflow
            </Link>
          : null }
          {hasPermission('LONG_UNREAD_METERS', 'DOWNLOAD') ? (
            <button className="navigate" onClick={() => setS3Model("download")}>
              Download Return File
            </button>
          ) : null}
        </div>
      }
    >
      <div style={{maxWidth: '20vw', marginBottom: 15}}>
      <GenericDataRow title={"File Date"} contentColour={"white"}>
        <select
          onChange={(_) => setDate(_.target.value)}
          value={date}
          style={{
            width: "100%",
            height: "100%",
            border: "none",
            backgroundColor: "white",
            paddingLeft: "10px",
          }}
        >
          <option key={"All_Refreshes"} value={"All_Refreshes"}>All Refreshes</option>
          {dates.map((item) => {
            return (
              <option key={item.raw} value={item.raw}>
                {item.pretty}
              </option>
            );
          })}
        </select>
      </GenericDataRow>
      </div>
      <div className='AAC' style={{ gridTemplateColumns: '5fr 1fr' }}>
        <GenericDataChart
          dataReady={!!data}
          data={data}
          selectedGroupBy={selectedGroupBy}
          setSelectectGroupBy={setSelectectGroupBy}
          groupingsEndpoint={endpoints.LONG_UNREAD_METERS_GROUPINGS}
          sizes={{chart: 600, chartContainer: 625, chartXAxisFontSize: 12, wrapperStyle: { paddingTop: '40px' }}}
          xAxisProps={{interval: 0}}
          customBarTick={CustomizedAxisTickMultiLine}
          overrideTableHeader={"Status"}
        />
        <GenericProductFilterContainer clearFilters={() => setFilters({})}>
          <ProductDataFilter
            filterState={filters}
            applyFilter={applyFilter}
            dataFilterEndpoint={endpoints.LONG_UNREAD_METERS_FILTERS}
          />
          <div className='grid grid-columns-2 grid-gap-5 mta'>
              { (hasPermission('LONG_UNREAD_METERS', 'BULK-BUY')) ?
                  <button style={{  gridColumn: 'span 2' }} className="button bulk-buy-button compact smaller-text" onClick={() => setBulkModel('purchase')}>
                      Create Download File
                  </button>
              : '' }
              { (hasPermission('LONG_UNREAD_METERS', 'ALLOCATE')) ?
                  <button style={{ gridColumn: !hasPermission('LONG_UNREAD_METERS', 'UNALLOCATE') && 'span 2' }} 
                    className="button bulk-buy-button compact smaller-text" onClick={() => setBulkModel('allocate')}>
                      Allocate
                  </button>
              : '' }
              { (hasPermission('LONG_UNREAD_METERS', 'UNALLOCATE')) ?
                  <button style={{ gridColumn: !hasPermission('LONG_UNREAD_METERS', 'ALLOCATE') && 'span 2' }}
                   className="button bulk-buy-button compact smaller-text" onClick={() => setBulkModel('unallocate')}>
                      Unallocate
                  </button>
              : '' }
          </div>
        </GenericProductFilterContainer>
      </div>
    </GenericPageContainer>,
    <S3Modal
      closeModal={() => setS3Model(null)}
      modalMode={S3Model}
      showModal={!!S3Model}
      listingUrl={endpoints.LONG_UNREAD_METERS_FILES}
      uploadUrl={endpoints.LONG_UNREAD_METERS_FILES}
    />,
    <BulkBuy 
    SentenceFirst={({count}) => <>The selected data set includes {count} accounts to be downloaded. Press the 'Create File' button to create the download file and you will receive an email notification once this is available. Alternatively press the 'Cancel' button to go back to the summary screen.</>}
    SentenceSecond={() => <></>}
    purchaseLabel="Download Limit:"
    buyButtonText="Create File"
    completeOnFirstStep={true} 
    workflowUsersEndpoint={endpoints.LONG_UNREAD_METERS_WF_USERS} 
    bulkEndpoint={endpoints.LONG_UNREAD_METERS_BULK_BASE} 
    modalIsOpen={bulkModel === 'purchase'} 
    closeModal={() => setBulkModel('')} 
    filters={filters} 
    date={date} />,
    <BulkAllocate 
    workflowUsersEndpoint={endpoints.LONG_UNREAD_METERS_WF_USERS} 
    bulkEndpoint={endpoints.LONG_UNREAD_METERS_BULK_BASE} 
    unallocateEndpoint={endpoints.LONG_UNREAD_METERS_UNALLOCATE} 
    modalIsOpen={bulkModel === 'allocate' || bulkModel === "unallocate"} 
    mode={bulkModel === "unallocate" ? "unallocate" : null}
    closeModal={() => setBulkModel('')} 
    filters={filters} 
    date={date} />,
    <ReactModal
    isOpen={bulkModel === "cds-upload"}
    onRequestClose={() => setBulkModel('')}
    className="card bulk-allocate"
    contentLabel="cds Upload"
    style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}
    >
      <h2>Issue File</h2>
      <Dropzone onDrop={(acceptedFiles) => {
        request(true).post(endpoints.LONG_UNREAD_METERS_CDS_UPLOAD, {
          fileName: acceptedFiles[0].name
        }).then(r => {
          Axios.put(r.data, acceptedFiles[0], {
            headers: {
                'Content-Type': acceptedFiles[0].type
            }
          })
        }).then(() => {
          window.alert('Files successfully uploaded')
          setBulkModel('')
        }).catch(e => {
          console.log(e)
          window.alert('Error uploading files');
        })
      }} />
    </ReactModal>
  ];
})
