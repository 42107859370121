import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import ReactDOMServer from 'react-dom/server'
import { GoogleMap, Marker, useLoadScript, TileLayer  } from '@react-google-maps/api';
import { view } from "@risingstack/react-easy-state";
import GenericPageContainer from "../../../GenericPageElements/GenericPageContainer";
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Select from 'react-select'
import request from "../../../helpers/request";
import endpoints from "../../../helpers/endpoints";
import "./UPRNSearch.scss";
import classCodes from '../../../assets/classification-codes.json'
import OsGridRef, { LatLon } from 'geodesy/osgridref'

export default view(function UPRNSearch() {
    const [search, setSearch] = useState("");
    const [selectedSuggestion, setSelectedSuggestion] = useState(null); 
    const [searchType, setSearchType] = useState({label: 'UPRN', value: 'uprn'});
    const [errors, setErrors] = useState([])
    const [notes, setNotes] = useState([])
    const [results, setResults] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [infoWindow, setInfoWindow] = useState(null);
    const [popupIndex, setPopupIndex] = useState(0);
    const [markers, setMarkers] = useState([])

    const iconURL = "https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon.png"
    const selectedIconURL = "https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png"
    
    const mapRef = useRef(null);
    const customTileLayerRef = useRef(null);

    const {isLoaded, loadError} = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY
    })

    const handleSearch = () => {
        setErrors([]);
        setNotes([]);
        setLoading(true)
        if (infoWindow) {
            infoWindow.close()
        }
        if (markers.length > 0) {
            for (var marker of markers) {
                marker.setMap(null)
                marker = null
            }
            setMarkers([])
        }

        request(true).get(endpoints[`UPRN_SEARCH_${searchType.value.toUpperCase()}`], {
            doesCancel: true,
            params: {
                [searchType.value]: search,
                ...(selectedSuggestion && {suggestion: selectedSuggestion})
            }
        }).then(async response => {
            setLoading(false)
            if (!response.data.results || response.data.results.length === 0) {
                setErrors(["No results for that search"])
                return
            }

            var results = response.data.results

            results = await Promise.all(results.map(async result => {
                var { LAT, LNG, ADDRESS } = result.DPA;
                if (!LAT || !LNG) {
                    const center = await geoCodeAddress(ADDRESS);
                    LAT = center.lat()
                    LNG = center.lng()
                    const wgs84 = new LatLon(LAT, LNG);
                    const gridref = wgs84.toOsGrid();
                    const grid = OsGridRef.parse(gridref)

                    return {...result, DPA: {...result.DPA, LAT: LAT, LNG: LNG, X_COORDINATE: grid.easting, Y_COORDINATE: grid.northing}}
                } else {
                    return result
                }
            }))

            const { LAT, LNG } = results[0].DPA;
            if (results.length > 1 && searchType.value === "voa") {
                setNotes(["Multiple results found for this reference."])
            }
            setResults(results)

            if (mapRef.current) {
                mapRef.current.panTo({ lat: LAT + 0.0005, lng: LNG });
                mapRef.current.setZoom(16);
            }

        }).catch(error => {
            console.log(error)
            setErrors(["Error retrieving data"])
            setLoading(false)
        })
    }

    const geoCodeAddress = (address) => {
        return new Promise((resolve, reject) => {
            const geocoder = new window.google.maps.Geocoder();
            geocoder.geocode({address: address}, (res, status) => {
                if (status === "OK") {
                    resolve(res[0].geometry.location)
                } else {
                    reject()
                }
            })
        })
    }

    const addCustomTileLayer = (map) => {
        const tileLayer = new window.google.maps.ImageMapType({
            getTileUrl: (coord, zoom) =>
                `https://${["a", "b", "c"][coord.x % 3]}.tile.openstreetmap.org/${zoom}/${coord.x}/${coord.y}.png`,
            tileSize: new window.google.maps.Size(256, 256),
            maxZoom: 19,
            name: "OSM Tiles",
        });
        customTileLayerRef.current = tileLayer;
        map.overlayMapTypes.insertAt(0, tileLayer);

        map.panTo({ lat: 54.5, lng: -2.5 });
        map.setZoom(6);

        map.addListener("maptypeid_changed", () => {
            const mapTypeId = map.getMapTypeId(); 
            if (mapTypeId === "satellite" || mapTypeId === "hybrid") {
                map.overlayMapTypes.clear();
            } else if (!map.overlayMapTypes.getArray().includes(tileLayer)) {
                map.overlayMapTypes.insertAt(0, tileLayer);
            }
        });
    };

    const onLoad = useCallback((loadedMap) => {
        if (!mapRef.current) {
            mapRef.current = loadedMap;
            addCustomTileLayer(loadedMap);
            const InfoWindow = new window.google.maps.InfoWindow() 
            setInfoWindow(InfoWindow)

            loadedMap.addListener('click', () => {
                if (InfoWindow) {
                    InfoWindow.close();
                    setSelectedMarker((prevSelectedMarker) => {
                        if (prevSelectedMarker) {
                            prevSelectedMarker.setIcon(iconURL);
                        }
                        return null;
                    });
                }
            });
        }
    }, []);

    const createPopup = (result, index) => {
        if (!result) return
        const stackedMarkers = results.filter(item => item.DPA.X_COORDINATE === result.DPA.X_COORDINATE && item.DPA.Y_COORDINATE === result.DPA.Y_COORDINATE);

        const currentResult = stackedMarkers[index ?? popupIndex]
        const { X_COORDINATE, Y_COORDINATE, ADDRESS, UPRN, CLASSIFICATION_CODE, VOA_REFERENCE } = currentResult.DPA;
        const addressParts = ADDRESS.split(',');

        const cycleIndex = (change = 0) => {
            setPopupIndex((prevIndex) => {
                const newIndex = prevIndex + change;
                if (newIndex < 0 || newIndex >= stackedMarkers.length) return prevIndex;
                createPopup(stackedMarkers[newIndex], newIndex)
                return newIndex;
            });
        }

        const contentDiv = document.createElement('div');
        contentDiv.className = 'pop-up-content'
        contentDiv.innerHTML = ReactDOMServer.renderToString(
            <>
                <ul>
                    <li>
                        <p>
                            <strong>UPRN:</strong> {UPRN}
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>VOA Reference:</strong> {VOA_REFERENCE}
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Address:</strong>
                            <br />
                            {addressParts.map((part, index) => (
                                <span key={index}>
                                    {part}
                                    {index < addressParts.length - 1 && <br />}
                                </span>
                            ))}
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Easting (X):</strong> {X_COORDINATE}
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Northing (Y):</strong> {Y_COORDINATE}
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Classification Code (Y):</strong> {classCodes.find(classCode => classCode.Concatenated === CLASSIFICATION_CODE)?.Class_Desc}
                        </p>
                    </li>
                </ul>
                {stackedMarkers.length > 1 && (
                    <div className="stacked-markers">
                        <span class="prev-button fas fa-angle-left" />
                        <span>{(index ?? popupIndex) + 1}/{stackedMarkers.length}</span>
                        <span class="next-button fas fa-angle-right" />
                    </div>
                )}
            </>
        );
    
        infoWindow.setContent(contentDiv);
    
        const prevButton = contentDiv.querySelector('.prev-button');
        const nextButton = contentDiv.querySelector('.next-button');
    
        if (prevButton) prevButton.addEventListener('click', (event) => {
            event.preventDefault();
            cycleIndex(-1)
        });
        if (nextButton) nextButton.addEventListener('click', (event) => {
            event.preventDefault();
            cycleIndex(1)
        });
    }

    useEffect(() => {
        const markerArray = [];
        results.map((result, index) => {
            const { LAT, LNG, UPRN } = result.DPA;
            const marker = new window.google.maps.Marker({
                position: { lat: LAT, lng: LNG },
                map: mapRef.current,
                title: UPRN,
                icon: iconURL
              });
              if (['address', 'uprn', 'coordinates', 'voa'].includes(searchType.value) && index === 0) {
                createPopup(result)
                marker.setIcon(selectedIconURL)
                marker.setZIndex(1000)
                infoWindow.open(mapRef.current, marker);
                setSelectedMarker(marker)
              }
              marker.addListener('click', () => {
                setSelectedMarker((prevMarker) => {
                    if (prevMarker && prevMarker !== marker) {
                        prevMarker.setIcon(iconURL);
                    }
            
                    if (prevMarker === marker) {
                        if (infoWindow.isOpen) {
                            infoWindow.close(mapRef.current, marker);
                            return marker
                        } else {
                            infoWindow.open(mapRef.current, marker)
                            return marker
                        }
                    } else {
                        marker.setIcon(selectedIconURL);
                        createPopup(result);
                        infoWindow.open(mapRef.current, marker);
                        return marker;
                    }
                });
            });
            markerArray.push(marker)
        })
        setMarkers(markerArray)
    }, [results])

    useEffect(() => {
        setSearch("")
        setSelectedSuggestion(null)
    }, [searchType])

    useEffect(() => {
        setPopupIndex(0);
    }, [infoWindow, selectedMarker])

    return (
        <GenericPageContainer
        title="UPRN Search"
        titleIconClass={"fak fa-id-business-icon fa-2x colour-primary"}
        >
            <div className="grid grid-gap-15 uprn-search">
                <div className="grid grid-columns-2 grid-gap-15" style={{ gridTemplateColumns: 'min-content min-content auto' }}>
                    <MapInput searchType={searchType} search={search} setSearch={setSearch} handleSearch={handleSearch} setSelectedSuggestion={setSelectedSuggestion} />
                    <Select 
                        styles={{
                            control: (baseStyles) => ({
                            ...baseStyles,
                            minWidth: '15em',
                            }),
                            container: (baseStyles) => ({
                                ...baseStyles,
                                zIndex: '999'
                            })
                        }}
                        placeholder="Search by..." 
                        onChange={(option) => setSearchType(option)} 
                        defaultValue={{label: 'UPRN', value: 'uprn'}}
                        options={[
                            {label: 'UPRN', value: 'uprn'},
                            {label: 'Address', value: 'address'},
                            {label: 'Postcode', value: 'postcode'},
                            {label: 'Coordinates', value: 'coordinates'},
                            {label: 'VOA Reference', value: 'voa'},
                        ]}
                    />
                    <button disabled={loading} style={{ width: '10em' }} className="button compact smaller-text background-primary colour-white" onClick={handleSearch}>
                        {
                            loading ? 
                                <i className="fa fa-spinner fa-spin"></i>
                            :
                                "Search"
                        }
                    </button>
                </div>
                {notes.map(note => {return <p style={{ color: "var(--yellow)" }}>{note}</p>})}
                {errors.map(error => {return <p style={{ color: "var(--red)" }}>{error}</p>})}
                {isLoaded ?
                    <MemoizedGoogleMap onLoad={onLoad} results={results} />
                :
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <i className="fa fa-spinner fa-spin"></i>
                    </div>

                }
            </div>
        </GenericPageContainer>
    );
})

const MemoizedGoogleMap = React.memo(({ onLoad, results }) => {
    const mapContainerStyle = useMemo(() => ({ width: '100%', height: '80vh' }), []);

    return (
        <GoogleMap
            mapContainerClassName="map"
            mapContainerStyle={mapContainerStyle}
            maxZoom={20}
            onLoad={onLoad}
            options={{
                gestureHandling: 'greedy',
                mapTypeControlOptions: {
                    mapTypeIds: ['roadmap', 'hybrid', 'satellite']
                }
            }}
        />
    )
})

const MapInput = ({ searchType, search, setSearch, handleSearch, setSelectedSuggestion }) => {
    const [searchSuggestions, setSearchSuggestions] = useState([])
    const [loadingSuggestions, setLoadingSuggestions] = useState(false)

    useEffect(() => {
        if (searchType.value !== "address") return;
        if (search) {
            getSuggestions()
        } else {
            setLoadingSuggestions(false)
            setSearchSuggestions([])
        }
    }, [search])

    function debounce(func, delay) {
        let debounceTimer;
        return function(...args) {
            clearTimeout(debounceTimer);
            debounceTimer = setTimeout(() => func(...args), delay);
        };
    }

    const getSuggestions = debounce(() => {
        setLoadingSuggestions(true)
        request(true).get(endpoints.UPRN_SEARCH_SUGGESTIONS, {
            doesCancel: true,
            params: {
                search: search
            }
        }).then(r => {
            setSearchSuggestions(r.data);
            setLoadingSuggestions(false)
        }).catch(error => {
            console.log(error)
            setSearchSuggestions([])
            setLoadingSuggestions(false)
        })
    }, 300)

    if (searchType.value === "coordinates") {
        return (
            <div className="grid grid-columns-2 grid-gap-15">
                {['X', 'Y'].map(axis => {
                    return (
                        <input type="text"
                        placeholder={axis}
                        className="data-row-field"
                        value={search?.[axis]}
                        onChange={(e) => setSearch({...search, [axis]: e.target.value ?? ''})}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter' ) {
                                handleSearch();
                            }
                        }}
                        disabled={!searchType}
                        />    
                    )
                })}
            </div>
        )
    } else if (searchType.value === "address") {
        return (
            <Autocomplete
                value={search}
                onChange={(event, newValue) => {
                    setSearch(newValue?.SINGLE_LINE_ADDRESS || '');
                    setSelectedSuggestion(newValue?._id || null)
                }}
                filterOptions={() => searchSuggestions}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="Address-Search"
                options={searchSuggestions}
                loading={loadingSuggestions}
                loadingText="Loading..."
                getOptionLabel={(option) => {
                    if (option.SINGLE_LINE_ADDRESS) {
                        return option.SINGLE_LINE_ADDRESS
                    } else {
                        return search
                    }
                }}
                renderOption={(props, option) => {
                    const { key, ...optionProps } = props;
                    return (
                        <li key={key} {...optionProps}>
                            {option.SINGLE_LINE_ADDRESS ?? search}
                        </li>
                    );
                }}
                sx={{ width: 305, 
                    "& .MuiOutlinedInput-root": {
                        padding: 0, paddingRight: 8
                    }
                }}
                freeSolo
                renderInput={(params) => (
                    <TextField {...params} placeholder="Enter Address" onChange={(event) => setSearch(event.target.value || '')} 
                        onKeyDown={(event) => {
                            if (event.key === 'Enter' && !document.querySelector('.MuiAutocomplete-popper li')) {
                                event.preventDefault();
                                handleSearch();
                            }
                        }}
                    />
                )}
            />
        )
    } else {
        return (
            <input
                style={{ width: searchType.value === "address" ? '30em' : 'unset' }}
                type="text"
                value={search}
                onChange={(e) => setSearch(searchType?.value === "postcode" ? e.target.value.toUpperCase() : e.target.value)}
                placeholder={`Enter ${searchType.label}`}
                className="data-row-field"
                onKeyDown={(event) => {
                    if (event.key === 'Enter' ) {
                        handleSearch();
                    }
                }}
                disabled={!searchType}
            />
        )
    }
}
