import React, { Component, useEffect, useMemo, useState } from 'react'
import Navigation from '../../components/navigation/navigation'
import Footer from '../../components/footer/footer'
import Container from '../../components/container/container'
import Modal from 'react-modal';

import request from '../../helpers/request';
import endpoints from '../../helpers/endpoints';
import { Link } from 'react-router-dom';

import {
    ColumnDef,
    Row,
    flexRender,
    getCoreRowModel,
    useReactTable,
} from '@tanstack/react-table';
  
import {
DndContext,
KeyboardSensor,
MouseSensor,
TouchSensor,
closestCenter,
useSensor,
useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
arrayMove,
SortableContext,
verticalListSortingStrategy,
} from '@dnd-kit/sortable';
  
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

export default function ProductsPage() {
    const [products, setProducts] = useState([]);
    const [realProducts, setRealProducts] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState();
    const [product, setProduct] = useState({
                name: '',
                description: '',
                icon: '',
                available: false,
    });
    const [errors, setErrors] = useState({})

    const dataIds = useMemo(() => products.map(({ id }) => id), [products]);

    const OrderCell = ({ row }) => {
        const [input, setInput] = useState(row.original.order);
        return (
          <input
          size={3}
            onChange={(event) => {
              if (!event.target.value.match(/^[0-9]*$/)) return
              setInput(Number(event.target.value))
            }}
            onBlur={() => {
              const index = products.findIndex(item => item.id === row.original.id)
              const newProducts = [...products];
              newProducts[index].order = input;
              newProducts.sort((a, b) => a.order - b.order);
              setProducts(newProducts);
              submitNewOrder([products[index]]);
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                const index = products.findIndex(item => item.id === row.original.id)
                const newProducts = [...products];
                newProducts[index].order = input;
                newProducts.sort((a, b) => a.order - b.order);
                setProducts(newProducts);
                submitNewOrder([products[index]]);
              }
            }}
            value={input} 
            className="button bulk-buy-button compact" 
            style={{ margin: '0px', paddingLeft: '0px', paddingRight: '0px', textAlign: 'center', width: '3em' }}
          />
        )
    }

    const columns = useMemo(
        () => [
        {
            accessorKey: 'name',
            header: () => 'Name',
        },
        {
            cell: ({ row }) =>
                <Link to={ "/admin/portal/products/" + row.id }><i className="fa fa-search" aria-hidden="true"></i></Link>,
            header: 'Actions',
        },
        {
            cell: ({ row }) => <OrderCell row={row} />,
            header: 'Order',
        },
        {
            id: 'drag-handle',
            header: '',
            cell: ({ row }) => <RowDragHandleCell rowId={row.id} />,
        },
    ], [products])

    const loadPage = (page = 1) => {
        request(true).get(endpoints.ADMIN_PRODUCTS_INDEX).then(e => {
            setProducts(e.data)
        }).catch(e => {
            console.log(e);
        })
    }

    const updatePage = (page) => {
        loadPage(page);
    }

    useEffect(() => {
        loadPage();
        loadRealProducts();
    }, [])

    const openModal = () => {
        setModalIsOpen(true)
    }

    const closeModal = () => {
        setModalIsOpen(false)
    }

    const updateFormValue = (e) => {
        let value = e.target.value ? e.target.value : undefined

        if (e.target.value === "true") {
            value = true;
        } else if (e.target.value === "false") {
            value = false;
        }

        setProduct({
            ...product,
            [e.target.name]: value
        })
    }

    const formSubmit = () => {
        request(true).post(endpoints.ADMIN_PRODUCTS_INDEX, product).then(r => {
            loadPage(1);
            setProduct({
                name: '',
                icon: '',
                description: '',
                available: false
            })
        }).catch(r => {
            setErrors(r.response.data.errors ?? {})
        })
    }

    const loadRealProducts = () => {
        request(true).get(endpoints.ADMIN_REAL_PRODUCTS_INDEX).then(r => {
            setRealProducts(r.data)
        });
    }

    const updateProductState = (index, event) => {
        let temp = [].concat(realProducts);
        temp[index].product_offering_id = event.target.value;

        setRealProducts(temp)
    }

    const submitRealProducts = (event) => {
        event.preventDefault();

        let postData = {
            realProducts: realProducts.map(item => {
                return {
                    id: item.id,
                    product_offering_id: item.product_offering_id
                }
            })
        }

        request(true).post(endpoints.ADMIN_REAL_PRODUCTS_INDEX, postData).then(r => {
            //console.log(r);
        }).catch(e => {
            //console.log(e);
        })
    }

    const submitNewOrder = (rows) => {
        const newProducts = [...products];
        rows.forEach(row => {
            newProducts[products.findIndex(product => product.id === row.id)] = row
        })
        setProducts(newProducts.sort((a, b) => a.order - b.order))

        rows.forEach(row => {
            request(true).post(`${endpoints.ADMIN_PRODUCTS_INDEX}/${row.id}`, {
                ...row
            })
        })
    }

    const table = useReactTable({
        data: products,
        columns: columns,
        getCoreRowModel: getCoreRowModel(),
        getRowId: row => row.id,
        autoResetAll: false
    });

    const handleDragEnd = (event) => {
        const { active, over } = event;
        if (active && over && active.id !== over.id) {
            const activeIndex = products.findIndex(item => item.id === active.id);
            const overIndex = products.findIndex(item => item.id === over.id);

            const activeItem = products[activeIndex];
            const overItem = products[overIndex];

            if (activeItem.order <= overItem.order) {
            activeItem.order = overItem.order + 1;
            } else if (activeItem.order > overItem.order) {
            activeItem.order = overItem.order - 1;
            if (activeItem.order === -1) {
                activeItem.order = 0
                overItem.order = 1
            }
            }

            submitNewOrder([activeItem, overItem])
        }
    }
    
    const sensors = useSensors(
        useSensor(MouseSensor),
        useSensor(TouchSensor),
        useSensor(KeyboardSensor)
    );

    const RowDragHandleCell = React.memo(({ rowId }) => {
        const { attributes, listeners } = useSortable({ id: rowId });
        return (
            <i {...attributes} {...listeners} class="fa-solid fa-bars fa-lg"></i>
        );
    });
    
    const DraggableRow = ({ row }) => {
        const { transform, transition, setNodeRef, isDragging } = useSortable({
            id: row.original.id,
        });

        const style = {
            transform: CSS.Transform.toString(transform),
            transition: transition,
            opacity: isDragging ? 0.8 : 1,
            zIndex: isDragging ? 1 : 0,
            position: 'relative',
        };

        return (
            <tr
            className={product ? 'tr-drag-bars' : ''}
            ref={setNodeRef}
            style={style}
            >
            {row.getVisibleCells().map(cell => (
                <td
                key={cell.id}
                className={cell.column.id === 'drag-handle' ? 'drag-bars' : ''}
                >
                {cell.column.id === 'drag-handle' ? (
                    <RowDragHandleCell rowId={row.original.id} />
                    )
                : (
                    flexRender(cell.column.columnDef.cell, cell.getContext())
                )}
                </td>
            ))}
            </tr>
        );
    }
    return (
        <div className="grid grid-gap-20">
            <Navigation/>
            <Container>
                <div className="flex middle">
                    <p className="colour-secondary font-weight-600" style={{fontSize: 20}}>Products</p>
                    <button onClick={openModal} style={{ marginLeft: 'auto' }} className="button background-primary colour-white">Add A Product</button>
                </div>
            </Container>
            <Container>
                <DndContext
                    collisionDetection={closestCenter}
                    modifiers={[restrictToVerticalAxis]}
                    onDragEnd={handleDragEnd}
                    sensors={sensors}
                >
                    <table className="table">
                        <thead>
                            {table.getHeaderGroups().map(headerGroup => (
                                <tr key={headerGroup.id}>
                                    {headerGroup.headers.map(header => (
                                    header.id !== "drag-handle" &&
                                    <th key={header.id}>
                                        {header.isPlaceholder
                                        ? null
                                        : flexRender(header.column.columnDef.header, header.getContext())}
                                    </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            <SortableContext items={dataIds} strategy={verticalListSortingStrategy}>
                                {table.getRowModel().rows.map(row => (
                                    <DraggableRow key={row.id} row={row} />
                                ))}
                            </SortableContext>
                        </tbody>
                    </table>
                </DndContext>
            </Container>
            <Container>
                <form onSubmit={ submitRealProducts }>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Key</th>
                                <th>URL</th>
                                <th>Product Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            { realProducts.map((product, index) => {
                                return (
                                    <tr key={`real-product-${product.id}`}>
                                        <td>
                                            { product.product_key }
                                        </td>
                                        <td>
                                            { product.product_url}
                                        </td>
                                        <td>
                                            <select name={product.id} value={ product.product_offering_id } onChange={ (event) => { updateProductState(index, event) }}>
                                                <option value={''}>
                                                    Select a Product
                                                </option>
                                                { products.map(item => {
                                                    return (
                                                        <option key={`${product.id}-${item.id}`} value={item.id}>
                                                            { item.name }
                                                        </option>
                                                    )
                                                })}
                                            </select>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    <button style={{ marginTop: '20px', float: 'right' }} className="button background-primary colour-white">Update</button>
                </form>
            </Container>
            <Modal
                style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}
                className="card"
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Add A Company"
                appElement={document.getElementById('admin-page')}
            >
                <div className="grid grid-gap-15">
                    <div className="form-row">
                        <label htmlFor="name">Name:</label>
                        <input onChange={updateFormValue} name="name" placeholder="Name" className="input" type="text" value={product.name} required/>
                        { errors.name ? errors.name.map((value, index) => {
                            return (
                                <p key={index}>
                                    { value }
                                </p>
                            )
                        }) : <></>}
                    </div>
                    <div className="form-row">
                        <label htmlFor="icon">Icon:</label>
                        <input onChange={updateFormValue} name="icon" placeholder="Icon" className="input" type="text" value={product.icon} required/>
                        { errors.name ? errors.name.map((value, index) => {
                            return (
                                <p key={index}>
                                    { value }
                                </p>
                            )
                        }) : <></>}
                    </div>
                    <div className="form-row">
                        <label htmlFor="description">Description:</label>
                        <input onChange={updateFormValue} name="description" placeholder="Description" className="input" type="text" value={product.description} required/>
                        { errors.description ? errors.description.map((value, index) => {
                            return (
                                <p className="error" key={index}>
                                    { value }
                                </p>
                            )
                        }) : <></>}
                    </div>
                    <div className="form-row">
                        <label htmlFor="available">Available:</label>
                        <div className="item">
                            True: <input onChange={updateFormValue} name="available" className="input" type="radio" value={true} checked={product.available}/>
                        </div>
                        <div className="item">
                            False: <input onChange={updateFormValue} name="available" className="input" type="radio" value={false} checked={!product.available}/>
                        </div>
                        { errors.available ? errors.available.map((value, index) => {
                            return (
                                <p className="error" key={index}>
                                    { value }
                                </p>
                            )
                        }) : <></>}
                    </div>
                    <button onClick={formSubmit} className="button background-primary colour-white">Create</button>
                </div>
            </Modal>
            <Footer />
        </div>
    )
}
